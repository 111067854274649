<template lang="pug">
  .forecast-container
    .forecast-container-header
      span {{ $t('Dashboard.weather_ForecastSales') }}
      .forecast-container-header-right
        .switch
          span.m-right-10 {{ $t('Dictionary.Quantity') }}/{{ $t('Dictionary.Amount') }}
          SwitchButton( 
            :active="showCurrency"
            :class="showCurrency ? 'active' : 'passive'"
            id="btn-dashboard-switch-status"
            @change="changeCurrencyStatus(row)"
          )
    table.m-top-10
      thead
        tr
          th
            .forecast-header-left
              .dot-forecast 
              .forecast-header-left-info {{ $t('Dashboard.forecastedSale') }}
            .forecast-header-left
              .dot-real 
              .forecast-header-left-info {{ $t('Dashboard.actualSale') }}
            .forecast-header-left
              .dot-percent 
              .forecast-header-left-info {{ $t('Dashboard.percentDeviation') }}

          th(v-for="(day, index) in WeatherForecastData" :key="index") 
            span.weather-date {{ getFilteredDate(day.date) }}
            img.weather-icon(:src="`http://openweathermap.org/img/wn/${day.icon}@2x.png`" :alt="day.info")
            .weather
              span.weather-max {{ getFormattedTemperature(day.temperatureMax) }}°
              span.weather-min {{ getFormattedTemperature(day.temperatureMin) }}°
      tbody
              
        tr(v-for="stock in SalesForecastData")
          td
            span.product {{stock.menuItemName}}
          td(v-for="forecastData in stock.resultList")
            .quantities(v-if="!showCurrency")
              span {{ forecastData.forecastedQuantity || '-' }}
              span {{ forecastData.actualQuantity || '-' }}
              span {{ forecastData.percentDeviationQuantity || '%' }}
            .amounts(v-else)
              span {{ forecastData.forecastedAmount !== null ?  currencySymbol + forecastData.forecastedAmount : '-' }}
              span {{ forecastData.actualAmount !== null ? currencySymbol + forecastData.actualAmount : '-' }} 
              span {{ forecastData.percentDeviationAmount || '%' }}

</template>

<script>
import {mapGetters} from 'vuex'
import SwitchButton from '@/view/global/switch-button'


export default {
  name: 'ForecastTable',
  components: {
    SwitchButton
  },
  data () {
    return {
      showCurrency: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', [
      'baseCurrencyId',
      'SalesForecastData',
      'WeatherForecastData'
    ]),
    ...mapGetters('Global', [
      'CurrencyList'
    ]),
    selectedData () {
      this.selectData = this.Supplier
      return this.selectData.slice(this.fromPage, this.toPage)
    },
    currencySymbol () {
      return this.CurrencyList.find(c => c.id === this.baseCurrencyId).symbol
    }
  },
  methods: {
    changeCurrencyStatus () {
      this.showCurrency = !this.showCurrency
    },
    getFormattedTemperature (temperature) {
      return Math.floor(temperature)
    },
    changeData (fromPage, toPage) {
      this.fromPage = fromPage
      this.toPage = toPage
    },
    getFilteredDate (date) {
      return moment(date).format('ddd, MMM D')
    }
  }
}
</script>

<style scoped lang="scss">

.forecast-container {
  background: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  background: #FFFFFF;
  border: 1px #EDEFF1;
}
th {
  border: 1px solid #EDEFF1;
  padding: 8px;
  text-align: center;
  &:nth-child(5) {
    background: #FBFBFB;
    border-top: 4px solid #505050;
  }
}
td {
  border: 1px solid #EDEFF1;
  padding: 8px;
  text-align: center;
  &:nth-child(5) {
    background: #FBFBFB;
  }
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  &-forecast {
    @extend .dot;
    background: #505050;
  }
  &-real {
    @extend .dot;
    background: #19BB7A;
  }
  &-percent {
    @extend .dot;
    background: #8F9BA9;
  }
}
.forecast-header-left {
  display: flex;
  width: max-content;
  align-items: center;
  margin-top: 10px;
  &-info {
    color: #8F9BA9;
    font-weight: 420;
    font-size: 12px;
    margin-left: 10px;
  }
}
.product {
  color: #505050;
  font-size: 14px;
  font-weight: 600;
}
.weather {
  display: flex;
  justify-content: center;
  &-icon {
    width: 60px;
    height: 60px;
  }
  &-max {
    color: #505050;
    font-size: 14px;
  }
  &-min {
    color: #8F9BA9;
    font-size: 14px;
    margin-left: 5px;
  }
  &-date {
    color: #505050;
    font-size: 14px;
    font-weight: 600;
  }
}
.quantities, .amounts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    :nth-child(1) {
      color: #505050;
    }
    :nth-child(2) {
      color: #19BB7A;     
    }
    :nth-child(3) {
      color: #8F9BA9;
    }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  padding: 6px 8px;
  font-weight: 400;
  color: #86939E;
  font-size: 12px;
  border: 1px solid #E1E9EE;
}
</style>
