var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "onhand",
              suggestionValue: "name",
              showSuggestionType: true,
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.suggestions,
              customSuggestionPlaceHolder: _vm.$t("OnHand.search_Placeholder"),
              customSuggestionTitle: _vm.$t("OnHand.search_SuggestionTitle"),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.onChangeQueryItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("applyFilter", {
            attrs: {
              filterCheckList: _vm.filterCheckList,
              filterToggleList: _vm.filterToggleList,
            },
            on: {
              filterSubmit: _vm.getOnHandWithFilters,
              onChangeAnyList: _vm.onChangeOverGroupList,
            },
          }),
          _c("div", { staticClass: "show-more m-left-15" }, [
            _c("div", { staticClass: "Form-item dropdown-handle" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeOtherOptions,
                      expression: "closeOtherOptions",
                    },
                  ],
                  staticClass: "select dropdown-handle-button",
                  attrs: {
                    "aria-expanded": _vm.showOtherOptions,
                    tabindex: "0",
                  },
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.toggleOtherOptions.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.toggleOtherOptions.apply(null, arguments)
                      },
                    ],
                    click: _vm.toggleOtherOptions,
                  },
                },
                [
                  _c("div", { staticClass: "dropdown-handle-button-values" }, [
                    _vm._v(_vm._s(_vm.$t("Global.moreOptions"))),
                  ]),
                  _c("Icon", {
                    staticClass:
                      "dropdown-handle-button-icon icon icon-down-arrow",
                    class: { open: _vm.showOtherOptions },
                    attrs: { name: "icon-down-arrow" },
                  }),
                ],
                1
              ),
            ]),
            _vm.showOtherOptions
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "shortkey",
                        rawName: "v-shortkey",
                        value: ["esc"],
                        expression: "['esc']",
                      },
                    ],
                    staticClass: "MiniPopup left",
                  },
                  [
                    _c("CustomCheckbox", {
                      staticClass: "checkbox-show-exp-date",
                      attrs: {
                        id: "checkbox-show-exp-date",
                        label: _vm.$t("OnHand.ShowExpDateControlOnly"),
                        checked: _vm.showWithExpDate,
                      },
                      on: { change: _vm.changeExpDateStatusForData },
                    }),
                    _c("CustomCheckbox", {
                      staticClass: "show-passive-recipes",
                      attrs: {
                        id: "checkbox-on-hand-show-with-base-currency",
                        checked: _vm.showWithBaseCurrency,
                        label: _vm.$t("OnHand.showWithBaseCurrency"),
                      },
                      on: { change: _vm.showBaseCurrency },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      !_vm.isEmpty
        ? _c(
            "template",
            { slot: "toolbar-right" },
            [
              _vm.isSingleStock
                ? _c("div", { staticClass: "Result-top-header-right__item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("OnHand.result_Header_Quantity"))),
                    ]),
                    _c("span", { staticClass: "value ff-mono" }, [
                      _vm._v(
                        _vm._s(_vm.totalQuantity) +
                          " " +
                          _vm._s(_vm.singleStockItemBaseUnitCode)
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.isSingleStock
                ? _c("div", {
                    staticClass: "Result-top-header-right__separator",
                  })
                : _vm._e(),
              _c("div", { staticClass: "Result-top-header-right__item" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("OnHand.result_Header_total"))),
                ]),
                _c("span", { staticClass: "value ff-mono" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatCurrency3Digits")(_vm.totalAmount))
                  ),
                ]),
              ]),
              _c("ImportExport", {
                ref: "wrapper",
                attrs: {
                  componentName: "onhand",
                  isDisabled: _vm.isPageLoading,
                  activeImportExports: ["export"],
                  downloadParams: _vm.downloadParams(),
                  downloadUrl: "StockOnHand/stockonhand/export",
                  downloadFileName: "stockonhand-report",
                  downloadFileType: "xlsx",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading
            ? _c("TableLoading")
            : _c("TableView", {
                attrs: {
                  uniqueIdentifier: "id",
                  componentName: "OnHandTable",
                  fields: _vm.fields,
                  data: _vm.customStocks,
                  readOnly: true,
                  page: _vm.Page,
                  "empty-state-title": _vm.$t("OnHand.emptyPage_Title"),
                  "empty-state-desc": _vm.$t("OnHand.emptyPage_Description"),
                },
                on: {
                  sort: _vm.sort,
                  submitFilter: _vm.submitFilter,
                  clearFilter: _vm.clearFilter,
                  changePopupFilterSearch: _vm.changePopupFilterSearch,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              }),
          _c("portal", {
            attrs: { to: "stockItemName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {
                      staticClass: "stockItemName",
                      on: {
                        click: function ($event) {
                          return _vm.openModal(row)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "tooltip-relative show-MiniPopup" },
                        [
                          _vm._v(_vm._s(_vm.getName(row.stockItemName))),
                          row.stockItemName.length > 30
                            ? _c("Tooltip", {
                                attrs: {
                                  white: "",
                                  right: "",
                                  text: row.stockItemName,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      row.expirationDateControlIsEnabled
                        ? _c("Icon", {
                            staticClass: "showExp-btn",
                            attrs: { name: "icon-login-eye" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "stockItemMinimumQuantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              min_value: 0,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        attrs: {
                          name: `stockItemMinimumQuantity-${row.rowIndex}-${row?.id}`,
                          id: "input-batch-quantity",
                          error: _vm.veeErrors.has(
                            `quantity-${row.rowIndex}-${row?.id}`
                          ),
                          extraDataEmit: row,
                        },
                        on: { blur: _vm.onChangeInputMinimumQuantity },
                        model: {
                          value: row.stockItemMinimumQuantity,
                          callback: function ($$v) {
                            _vm.$set(row, "stockItemMinimumQuantity", $$v)
                          },
                          expression: "row.stockItemMinimumQuantity",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "stockItemOptimumQuantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              min_value: 0,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        attrs: {
                          name: `stockItemOptimumQuantity-${row.rowIndex}-${row?.id}`,
                          id: "input-batch-quantity",
                          error: _vm.veeErrors.has(
                            `quantity-${row.rowIndex}-${row?.id}`
                          ),
                          extraDataEmit: row,
                        },
                        on: { blur: _vm.onChangeInputOptimumQuantity },
                        model: {
                          value: row.stockItemOptimumQuantity,
                          callback: function ($$v) {
                            _vm.$set(row, "stockItemOptimumQuantity", $$v)
                          },
                          expression: "row.stockItemOptimumQuantity",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "orderByMinimumStock" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.stockItemMinimumQuantity > 0 &&
                            row.stockItemMinimumQuantity -
                              row.stockItemUnitQuantity >
                              -1
                            ? Number(
                                Math.abs(
                                  row.stockItemMinimumQuantity -
                                    row.stockItemUnitQuantity
                                ).toFixed(3)
                              )
                            : "-"
                        )
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "orderByOptimumStock" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.stockItemOptimumQuantity > 0 &&
                            row.stockItemOptimumQuantity -
                              row.stockItemUnitQuantity >
                              -1
                            ? Number(
                                Math.abs(
                                  row.stockItemOptimumQuantity -
                                    row.stockItemUnitQuantity
                                ).toFixed(3)
                              )
                            : "-"
                        )
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "requestType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    { staticClass: "requestType" },
                    [
                      _c("customSelectInput", {
                        staticClass: "custom-select",
                        attrs: {
                          name: "requestType",
                          optionData: _vm.requestTypes,
                          optionKey: "requestType",
                          optionIdKey: "requestType",
                          selectedDataRequired: true,
                          isValueNumber: true,
                          extraDataEmit: row,
                          hideDefault: false,
                          optionIdName: "stockOnHand-request-type",
                        },
                        on: { change: _vm.onInputSelected },
                        model: {
                          value: row.requestType,
                          callback: function ($$v) {
                            _vm.$set(row, "requestType", $$v)
                          },
                          expression: "row.requestType",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "fromStoreId" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("CustomSelect", {
                        staticClass: "custom-select",
                        attrs: {
                          name: "selectStore",
                          isDisabled: _vm.calculateStats(row),
                          optionData: _vm.allStoreList,
                          optionTitle: "name",
                          selectedEmitValue: "id",
                          isSearchActive: true,
                          extraDataEmit: row,
                          defaultTitle: _vm.$t("OnHand.fromStore_selection"),
                          selectedDataRequired: true,
                          hideDefault: true,
                        },
                        on: {
                          inputSelected: function ($event) {
                            return _vm.onInputSelectedStore($event, row)
                          },
                        },
                        model: {
                          value: row.fromStoreId,
                          callback: function ($$v) {
                            _vm.$set(row, "fromStoreId", $$v)
                          },
                          expression: "row.fromStoreId",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "isUsed" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    { staticClass: "tooltip-relative" },
                    [
                      _c("CustomMultiselect", {
                        staticClass: "m-left-5",
                        attrs: {
                          id: "onHand-add-shelf",
                          items: _vm.ShelfStorageList,
                          selected: _vm.selectedShelfList,
                          useCustomEmptyState: true,
                          customEmptyState: row.isUsed
                            ? _vm.$t("OnHand.shelfList_selected")
                            : _vm.$t("OnHand.shelfList_unselected"),
                          popupHeader: _vm.$t(
                            "OnHand.shelfStorageList_selection"
                          ),
                          unselectedText: _vm.$t(
                            "OnHand.shelfStorageList_selection"
                          ),
                          buttonText: _vm.$t("Global.apply"),
                          buttonIconLeft: "btn-success",
                          showAll: false,
                          isDisabled: !_vm.checkPermission(
                            "ShelfStorageLocationEdit"
                          ),
                          useCustomLocation:
                            _vm.stocks.length > 5 &&
                            _vm.stocks.length < row.rowIndex + 5
                              ? true
                              : false,
                          isLoadingData: _vm.fetchingShelfListData,
                          dontDisableButton: _vm.ShelfStorageList.length
                            ? true
                            : false,
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.getShelfData(row)
                          },
                          delegateCheckItem: _vm.onChangeSelectShelfList,
                          delegateSubmitItems: function ($event) {
                            return _vm.submitShelfList(row)
                          },
                        },
                      }),
                      !_vm.checkPermission("ShelfStorageLocationEdit")
                        ? _c("Tooltip", {
                            staticClass: "small",
                            attrs: {
                              white: "",
                              right: "",
                              text: _vm.$t(
                                "OnHand.noPermissionForShelfListEdit"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "stockItemSafeStockQuantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              min_value: 0,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        attrs: {
                          name: `stockItemSafeStockQuantity-${row.rowIndex}-${row?.id}`,
                          id: "input-batch-quantity",
                          error: _vm.veeErrors.has(
                            `quantity-${row.rowIndex}-${row?.id}`
                          ),
                          extraDataEmit: row,
                        },
                        on: { blur: _vm.onChangeSafeQuantity },
                        model: {
                          value: row.stockItemSafeStockQuantity,
                          callback: function ($$v) {
                            _vm.$set(row, "stockItemSafeStockQuantity", $$v)
                          },
                          expression: "row.stockItemSafeStockQuantity",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c(
            "Modal",
            {
              attrs: { isOpen: _vm.showExpDateDataModal },
              on: { closeModal: _vm.closeModal },
            },
            [
              _c("template", { slot: "title" }, [
                _c("span", [_vm._v(_vm._s(_vm.stockItemName))]),
              ]),
              _c(
                "template",
                { slot: "content" },
                [
                  !_vm.stockItemsWithExpDate.length
                    ? _c("EmptyState", {
                        staticClass: "batch-empty-state",
                        attrs: {
                          title: _vm.$t("OnHand.emptyPage_BatchTitle"),
                          description: _vm.$t(
                            "OnHand.emptyPage_BatchDescription"
                          ),
                        },
                      })
                    : _c("TableView", {
                        attrs: {
                          data: _vm.stockItemsWithExpDate,
                          fields: _vm.batchListFields,
                          readOnly: true,
                        },
                      }),
                  _c("portal", {
                    attrs: { to: "daysLeft" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass: "days",
                                class: _vm.getClassForDaysLeft(row.daysLeft),
                              },
                              [_vm._v(_vm._s(row.daysLeft))]
                            ),
                          ])
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }