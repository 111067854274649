<template lang="pug">
Page.Page-margin
  template(slot="header-left-search")
    Search.search(
      componentName="Authorization",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch"
    )

  template(slot="header-left-dropdown")
    .PageUserGroups.border-none
      .PageUserGroups-lists(v-if="userGroupList.length > 3")
        .left
          customSelect(
            v-model="selectedGroup"
            selectedEmitValue="id"
            :optionData="userGroupList"
            optionTitle="name"
            componentName="authorization-select"
            :actions="['edit', 'remove']"
            @inputSelected="onInputSelected"
            @onItemActionEdit="openEditUserGroupPopup"
            @onItemActionRemove="openRemoveUserGroupPopup"
          )
      .PageUserGroups-lists(v-else).m-top-10
        TitleBox.item-node(
          :title="this.$t('Global.Select_Default_Text')"
          componentName="authorization-title-box-all"
          @delegateOnItemView="onInputSelected"
          :isArrowIconVisible="false"
          :actions="['view']"
        )
        TitleBox.item-node(
          v-for="(userGroup, index) in userGroupList"
          :key="index"
          :data="userGroup"
          :title="userGroup.name"
          :boxIndex="index"
          componentName="authorization-title-box"
          :actions="['edit', 'remove', 'view']"
          :isArrowIconVisible="false"
          @delegateOnItemEdit="openEditUserGroupPopup"
          @delegateOnItemRemove="openRemoveUserGroupPopup"
          @delegateOnItemView="onInputSelected"
        )
  template(slot="header-right-content")
    Button(
      size="medium"
      primary
      id="btn-user-groups-add"
      type="button"
      v-show="!isUserGroupsEmpty && !isLoading"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="openNewUserGroupPopup")
      span {{ $t('Authorization.addUserGroupButton') }}

  template(slot="toolbar-right")
    Button(
      id="btn-user-add"
      primary
      type="button"
      size="small"
      v-show="!isLoading"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="openNewUserPopup")
        span {{ $t('Authorization.addUsersButton') }}

  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .PageTable(v-else)
      EmptyState(
        v-if="filteredUserList.length === 0",
        :title="$t('Global.emptyState_Title', { field: $t('Authorization.Users_not_found') })",
        :description="$t('Global.emptyState_Description', { field: $t('Authorization.Users_not_found') })",
        :buttonText="$t('Authorization.popupUserForm_Title_Create')",
        @emptyAddButton="openNewUserPopup")
      TableView(
        v-else
        :componentName="'authorization'"
        :fields="fields"
        :data="filteredUserList"
        :readOnly="true"
        :usePortalAction="true"
      )
      portal(to="actionPortal")
        div.popup-container.flexbox-custom(slot-scope="row")
          ActionButton(
            id="request-order-list-action-button"
            :data="['edit', 'remove']"
            v-if="!row.isDeleted"
            :item="row"
            @onItemDelete="openRemoveUserModal(row)"
            @onItemEdit="openEditUsersPopup(row)"
          )
      portal(to="status")
        .switch-button(slot-scope="row")
          SwitchButton(
            v-if="row.type !== 3 && !row.isDeleted"
            :active="row.isActive"
            :id="`btn-authorization-switch-status`"
            @change="changeActivationStatus(row)")
      
      portal(to="emailAddress")
        .email-confirmation(slot-scope="row")
          Icon(v-if="row.emailConfirmed" name="icon-success")
          Icon.tooltip-relative(v-else, name="icon-pending")
          span.m-left-10 {{row.emailAddress}} 

      portal(to="role")
        span(slot-scope="row") {{ row.roleList[0].name }}

      router-view(@getUserList="getLists")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import getFields from './fields/fields'
import SwitchButton from '@/view/global/switch-button'
import TitleBox from '@/view/global/title-box'
import customSelect from '@/view/global/custom-select'
import matchingSearch from '@/utils/matchingSearch'

export default {
  name: 'Authorization',

  data () {
    return {
      fields: getFields(this.$t),
      search: '',
      selectedGroup: null
    }
  },

  async created () {
    this.getLists()
  },

  components: {
    SwitchButton,
    TitleBox,
    customSelect
  },

  computed: {
    ...mapGetters('Settings', [
      'userGroupList',
      'isUserGroupsEmpty',
      'UserList',
      'pageHeaderLinks'
    ]),

    ...mapGetters('Auth', ['User']),


    filteredUserList () {
      if (this.selectedGroup) {
         if (this.search.length >= 2) {
          return this.UserList.filter(item => {
            return item.roleList[0].roleId === this.selectedGroup && matchingSearch(item.emailAddress, this.search) || (item.firstname ? matchingSearch(item.firstname, this.search) : '') || (item.surname ? matchingSearch(item.surname, this.search) : '')
          })
        } else {
            return this.UserList.filter(item => item.roleList[0].roleId === this.selectedGroup)
          }
      }
      else {
         if (this.search.length >= 2) {
          return this.UserList.filter(item => {
            return matchingSearch(item.emailAddress, this.search) || (item.firstname ? matchingSearch(item.firstname, this.search) : '') || (item.surname ? matchingSearch(item.surname, this.search) : '')
          })
        } else {
          return this.UserList
        }
      }
    },

    isLoading () {
      return this.$wait.is(['fetchUserGroupList']) || this.$wait.is(['fetchUserList'])
    }
  },
  methods: {
    ...mapActions('Settings', [
      'fetchUserGroupList',
      'fetchUserList',
      'updateUserStatus',
      'restoreRemovedUser'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    onChangeSearch (search) {
      this.search = search
    },

    async getLists () {
      await this.fetchUserGroupList()
      await this.fetchUserList()
      this.selectedGroup = null
    },

    onInputSelected (item) {
      this.search = ''
      this.selectedGroup = item.id
    },

    changeActivationStatus (item) {
      this.updateUserStatus(item)
    },

    openNewUserGroupPopup () {
      this.$router.push({
        name: 'NewUserGroups'
      })
    },

    openNewUserPopup () {
      this.$router.push({
        name: 'NewUser'
      })
    },

    openEditUserGroupPopup (item) {
      this.$router.push({
        name: 'EditUserGroups',
        params: {
          id: item.id,
          item: JSON.parse(JSON.stringify(item)),
          status: 'edit',
          userGroupName: item.name
        }
      })
    },

    openEditUsersPopup (item) {
      this.$router.push({
        name: 'EditUser',
        params: {
          id: item.id,
          item
        }
      })
    },

    restoreUser (item) {
      this.restoreRemovedUser({id: item.id}).then(res => {
        if (res) {
          const message = this.$t('Authorization.notification_UserRestored')
          this.notifyShow({ message })
          this.getLists()
        }
      })
    },

     openRemoveUserModal (item) {
      this.$router.push({
        name: 'RemoveUser',
        params: {
          id: item.id,
          item
        }
      })
    },

    openRemoveUserGroupPopup (item) {
      this.$router.push({
        name: 'ShowUserGroupRemove',
        params: {
          id: item.id,
          item
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding: 0;
  }
  .PageUserGroups {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $color-gray;
    .item-node {
      width: fit-content;
      height: 50px;
      flex-wrap: nowrap;
      &:not(:first-child) {
        margin-left: 10px;
      }
      :deep() .title {
        font-size: $font-size-small;
        color: $color-dark;
        font-weight: $font-weight-normal;
      }
      :deep() .actions {
        margin-left: 15px;
      }
    }
    &-lists {
      display: flex;
      flex-wrap: unset;
      align-items: center;
    }
  }
  .PageUserHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  :deep() .Tableee-col {
    text-align: center;
    z-index: 100 !important;
    .flexbox {
      justify-content: center;
    }
  }
  .custom-green {
    font-size: $font-size-normal;
    color: $color-success;
  }
  :deep() .row-TableViewPassiveItems {
    border: none !important;
    td:last-child {
      border-right: none !important;
    }
    td:first-child {
      border-left: none !important;
    }
  }
</style>
