<template lang="pug">
  div.register-page
    div(v-if="showCheckMail").check-email
      h1.success-message {{ $t('Register.confirmationLink') }}
      p.m-bottom-20 {{ $t('Register.checkEmail') }}
    form.Auth-form.Register-form.Form(
    v-else
    @submit.stop.prevent="submit")
      .Form-item-group.Form-item-header
        h1.Auth-form-title {{ $t('Register.formHeaderTitle') }}
        .log-out
          span {{ $t('Register.logOutDesc') }}
          button(type="button" @click="logOut") {{ $t('Register.logOut') }}

      .Form-item-group
        .Form-item.required
          label.Form-item-label {{ $t('Register.formFieldFirstName') }}
          .control
            input.txt(
            id="input-auth-register-firstname"
            name="firstname",
            autocomplete="off",
            :placeholder="$t('Register.formFieldFirstNamePlaceholder')",
            v-model="form.firstname",
            :class="{ 'is-danger': veeErrors.has('firstname') }",
            type="text",
            disabled)

        .Form-item.required.m-left-15
          label.Form-item-label {{ $t('Register.formFieldLastName') }}
          .control
            input.txt(
            id="input-auth-register-surname"
            name="surname",
            autocomplete="off",
            :placeholder="$t('Register.formFieldLastNamePlaceholder')",
            v-model="form.surname",
            :class="{ 'is-danger': veeErrors.has('surname') }",
            type="text",
            disabled)

      .Form-item-group
        .Form-item.email.required
          label.Form-item-label {{ $t('Register.formFieldEmail') }}
          .control
            input.txt.txt-right-icon(
            id="input-auth-register-email"
            name="email",
            autocomplete="new-email",
            :placeholder="$t('Register.formFieldEmailPlaceholder')",
            v-model="form.email",
            :class="{ 'is-danger': veeErrors.has('email') }",
            type="email",
            disabled)
      
        .Form-item.m-left-15
          label.Form-item-label {{ $t('Register.phoneNumber') }}
          .control
            PhoneNumber(
              :isDisabled="isPhoneNumberDisabled"
              :existingPhoneNumber="form.phoneNumber"
              :selectedCountry="country"
              @getCountryCode="getCountryCode"
              @getPhoneNumber="getPhoneNumber"
            )
      
      .Form-item-group
        .Form-item.name.required
          label.Form-item-label
            | {{ $t('Register.formFieldTenantName') }}
          .control
            input.txt.txt-right-icon(
            id="input-auth-tenant-name"
            name="companyName",
            autocomplete="off",
            :placeholder="$t('Register.formFieldCompanyNamePlaceholder')",
            :data-vv-as="$t('Register.formFieldTenantName')",
            v-model="form.companyName",
            v-validate="'required|min:2|max:128|very_singularity:Tenant/check/name,Name'",
            :class="{ 'is-danger': veeErrors.has('companyName') }",
            type="text")
            //
            Button(
            id="btn-auth-tenant-loading"
            type="button",
            variant="icon loading"
            tabindex="-1",
            v-show="isPendingCompanyName")
              Loading(theme="disable")
            Button(
            id="btn-auth-tenant-check"
            type="button",
            variant="icon check"
            tabindex="-1",
            v-show="isValidateCompanyName")
              svg.icon.icon-check
                use(xlink:href="#icon-check")
            //
            p.Form-item-help.is-danger(
            v-show="veeErrors.has('companyName')")
              | {{ veeErrors.first('companyName') }}

        .Form-item.required.m-left-15
          label.Form-item-label
            | {{ $t('Register.formFieldCurrency') }}
          customSelect.custom-select(
            name="currency",
            v-model="form.currency"
            :optionData="CurrencyList"
            optionTitle="isoCode"
            selectedEmitValue="id"
            componentName="currency-select"
            :isDefaultTextActive="false"
            :defaultTitle="$t('Register.formFieldCurrencySelect')"
            :data-vv-as="$t('Register.formFieldCurrency')"
            :isSearchActive="true"
            v-validate="'required'"
            :error="veeErrors.has('currency')")

          showValidateError(
            v-show="veeErrors.has('currency')"
            :errorName="veeErrors.first('currency')")

      .Form-item-group
        .Form-item.name.required
          label.Form-item-label {{ $t('Global.timeZone') }}
          customSelect.custom-select(
            name="timeZone"
            v-model="form.timeZone"
            :optionData="TimeZoneList"
            optionTitle="value"
            selectedEmitValue="id"
            componentName="time-zone-select"
            :isDefaultTextActive="false"
            :defaultTitle="$t('Register.formFieldTimeZoneSelect')"
            :data-vv-as="$t('Global.timeZone')"
            :isSearchActive="true"
            :searchIn="true"
            v-validate="'required'"
            :error="veeErrors.has('timeZone')")

          showValidateError(
            v-show="veeErrors.has('timeZone')"
            :errorName="veeErrors.first('timeZone')")

        .Form-item.required.m-left-15
          label.Form-item-label
            | {{ $t('Settings.User_FormField_language') }}
          customSelect.custom-select(
            name="languageId"
            v-model="form.languageId"
            :optionData="clearLanguages"
            optionTitle="name"
            selectedEmitValue="id"
            componentName="language-select"
            :isDefaultTextActive="false"
            :defaultTitle="$t('Global.select_language')"
            :data-vv-as="$t('Settings.User_FormField_language')"
            :isSearchActive="true"
            v-validate="'required'"
            :error="veeErrors.has('languageId')")
          
          showValidateError(
            v-show="veeErrors.has('languageId')"
            :errorName="veeErrors.first('languageId')")

      .Form-item-group
        .Form-item
          label.Form-item-label {{ $t('Register.formFieldTcknVkn') }}
          .control
            input.txt(
              id="input-auth-register-tax-number"
              name="taxNumber",
              type="number",
              autocomplete="off",
              :placeholder="$t('Register.formFieldTcknVknPlaceholder')",
              v-model="form.taxNumber",
              @keydown="preventNotNumberValues")

        .Form-item.m-left-15
          label.Form-item-label {{ $t('Register.formFieldTaxOffice') }}
          .control
            input.txt(
              id="input-auth-register-tax-office"
              name="taxOffice",
              type="text"
              autocomplete="off",
              :placeholder="$t('Register.formFieldTaxOfficePlaceholder')",
              v-model="form.taxOfficeName")

      .Form-item.x2.m-top-40.half
        .userAgreement
          CustomCheckbox(
          id="checkbox-register-open-user-agreement"
          label=""
          v-model="userAgreement")
          a.link(
          @click="openAgreement")
            |  {{ $t('Register.formFieldUserAgreement') }}
          span.text {{ $t('Register.formFieldUserAgreementText') }}
        Button(
        type="submit",
        primary
        size="medium"
        id="signup-button"
        variant="full"
        :disabled="!isFormReady || isLoading"
        :class="isFormReady && !isLoading ? 'btn-success' : 'btn-gray'")
          span(v-show="!isLoading") {{ $t('Register.formFieldSubmit') }}
          Loading(theme="disable", v-show="isLoading")
      

      Modal(:isOpen="isOpenAgreementModal",
      @closeModal="closeAgreementModal")
        template(slot="title")
          | {{ $t('UserAgreement.title') }}
        template(slot="content")
          div.Register-agreementText(v-html="agreement.agreementText")
        template(slot="footer")
          Button(
            size="xsmall",
            primary,
            @click="confirmAgreement") {{ $t('UserAgreement.confirmationButton') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vee-validate'
import PhoneNumber from '@/view/global/phone-number'
import customSelect from '@/view/global/custom-select'
import { isProd, isStaging } from '@/utils/helper'
import AuthorizationContext from '@/sso/keycloak-instance.js'
import CallingCodes from '@/constant/calling-codes.js'


export default {
  name: 'Register',
  components: {
    PhoneNumber,
    customSelect
  },

  data () {
    return {
      userAgreement: false,
      isOpenAgreementModal: false,
      form: {
        firstname: '',
        surname: '',
        email: '',
        companyName: '',
        currency: null,
        phoneNumber: null,
        timeZone: null,
        languageId: null,
        taxNumber: null,
        taxOfficeName: ''
      },
      country: {long: 'Turkey', code: '+90', short: 'TR'},
      showCheckMail: false,
      isPhoneNumberDisabled: false
    }
  },

  beforeMount () {
    this.setDefaults()
  },

  async mounted () {
    await this.getCurrencyList(this.language.id)
    await this.getUTCList()
    this.form.timeZone = 'Europe/Istanbul'
    await this.getAgreement(this.language.id)
    this.setLanguageDropdownVisibility(true)
  },

  beforeRouteLeave (to, from, next) {
    this.setLanguageDropdownVisibility(false)
    next()
  },

  computed: {
    ...mapGetters('i18n', [
      'langDefault'
    ]),

    ...mapGetters('Auth', [
      'agreement'
    ]),

    ...mapGetters('Global', [
      'findLanguage',
      'CurrencyList',
      'Languages',
      'TimeZoneList'
    ]),


    ...mapFields({
      flagFirstname: 'firstname',
      flagSurname: 'surname',
      flagEmail: 'email',
      flagCompanyName: 'companyName',
      flagCurrency: 'currency',
      flagTimeZone: 'timeZone',
      flagLanguageId: 'languageId'
    }),

    clearLanguages () {
      return this.Languages.map(lang => ({
        ...lang,
        isoCode: lang.isoCode.split('-')[0]
      }))
    },

    newUser () {
      return {
        firstname: this.form.firstname,
        surname: this.form.surname,
        emailAddress: this.form.email,
        languageId: this.form.languageId,
        companyName: this.form.companyName,
        baseCurrencyId: this.form.currency,
        agreementId: this.agreement.id,
        timeZoneKey: this.form.timeZone,
        phoneNumber: this.form.phoneNumber ? this.country.code + '-' + this.form.phoneNumber : null,
        taxNumber: this.form.taxNumber,
        taxOfficeName: this.form.taxOfficeName
      }
    },
    language () {
      return this.findLanguage(this.langDefault)
    },

    isPendingEmail () {
      return this.flagEmail.pending
    },

    isValidateEmail () {
      return this.flagEmail.valid && !this.isPendingEmail
    },

    isFormReady () {
      return  this.userAgreement &&
              this.flagCompanyName.valid && 
              this.flagCurrency.valid &&
              this.flagLanguageId.valid
    },

    isLoading () {
      return this.$wait.is(['isCreatingUser'])
    },

    isPendingCompanyName () {
      return this.flagCompanyName.pending
    },

    isValidateCompanyName () {
      return this.flagCompanyName.valid && !this.isPendingCompanyName
    },

    isProd () {
      return isProd()
    },

    isStaging () {
      return isStaging()
    }
  },

  methods: {
    ...mapActions('Auth', [
      'createUserWithTenant',
      'getAgreement',
      'Login',
      'Logout'
    ]),

    ...mapActions('Global', [
      'setLanguageDropdownVisibility'
    ]),

    ...mapActions('Global', [
      'getCurrencyList',
      'getUTCList'
    ]),

    preventNotNumberValues(e) {
      if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
        e.preventDefault();
      }
    },

    async logOut () {
      window.history.replaceState({}, '', '/')
      await AuthorizationContext.logout()
      this.Logout()
    },

    setDefaults () {
      const user = AuthorizationContext.user.profile
      const attrs = user.attributes

      this.form.firstname = user.firstName
      this.form.surname = user.lastName
      this.form.email = user.email

      if (attrs?.phoneNumber?.length > 0) {
        this.country = CallingCodes.find(c => c.code === '+' + user.attributes.phoneCode[0])
        this.getPhoneNumber(user.attributes.phoneNumber[0])
        this.isPhoneNumberDisabled = true
      }
    },
  
    getPhoneNumber(number) {
      this.form.phoneNumber = number
    },
    getCountryCode (item) {
      if (item) this.country = item
      else {
        this.country = {
          short: 'TR',
          long: 'Turkey',
          code: '+90'
        }
      }
    },

    openAgreement () {
      this.isOpenAgreementModal = true
    },
    closeAgreementModal () {
      this.isOpenAgreementModal = false
    },
    confirmAgreement () {
      this.userAgreement = true
      this.closeAgreementModal()
    },
    submit () {
      this.$wait.start('isCreatingUser')
      this.$validator.validateAll().then(async result => {
        if (!result) return
        const user = AuthorizationContext.user
        const phoneNumberUpdateRequired = (
          (!user.profile.attributes?.phoneNumber ||
          user.profile.attributes?.phoneNumber?.length === 0) &&
          this.form.phoneNumber
        )
        if (phoneNumberUpdateRequired) {
          await user.updateProfile({
            ...user.profile,
            attributes: {
              ...user.profile.attributes,
              phoneCode: this.country.code.replace('+', ''),
              phoneNumber: this.form.phoneNumber
            }
          })
        }
        this.createUserWithTenant(this.newUser).then(res => {
          if (res) {
            this.showCheckMail = true
          } else {
            this.$store.commit('Notify/SET_HIDE_CALLBACK', async () => {
              await this.logOut()
            })
          }
          this.$wait.end('isCreatingUser')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .register-page {
    overflow: hidden;
  }
  .userAgreement {
    display: flex;
    align-items: flex-start;
    margin-bottom: 17px;
    .link {
      margin-left: 10px;
      margin-right: 5px;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
      color: $color-dark;
    }
    .text {
      font-weight: 500;
      line-height: 19px;
    }
  }
  .Register {
    &-agreementText {
      p {
        color: $color-light;
        line-height: 1.5;
      }
    }
  }
  .check-email {
    margin-left: 10%;
    width: 80%;
  }

  .Form-item-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Auth-form {
    width: 80% !important;
    margin-bottom: 10px;
  }

  .Form-item {
    width: 400px !important;
    margin-top: 20px;

    &.half {
      width: 305px !important;
    }
  }
  .Form-item + .Form-item{
    margin-top: 20px !important;
  }
  .Auth-form-title {
    padding-bottom: unset;
    border-bottom: unset;
    margin-bottom: unset;
    width: 190px !important;
  }
  .Auth-form-link {
    margin-top: 0 !important;
    width: 190px !important;
  }
  
  .Form-item-header {
    padding: 30px 0px;
    border-bottom: 1px solid #bfbcbc;
    margin-bottom: 20px;

    .log-out {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 3px;

      button {
        text-decoration: underline;
      }
    }
  }
  .submit-button {
    width: 340px !important;
  }
  .Form-item-help {
    position: absolute;
    margin-top: 0;
    font-size: 13px;
  }
  .m-top-40 {
    margin-top: 40px;
  }

  :deep() .select-dropdown-custom.custom-select {
    .select-dropdown-custom-options {
      &.upside {
        width: 100% !important;
      }
      &.normal {
        width: 100% !important;
      }
    }
    .select-dropdown-custom-selected {
      width: 100% !important;
      height: 54px !important;
    }
    .select-dropdown-custom-name {
      font-size: $font-size-bigger !important;
    }
    .selected-name {
      font-size: $font-size-normal !important;
    }
  }
  
</style>
