var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasTabPermission
    ? _c("NoPermission")
    : _c("div", [
        _c(
          "div",
          { staticClass: "SettingsContainer m-bottom-15" },
          [
            _vm.UserInfo.type === 3
              ? _c("div", { staticClass: "UserAgreement" }, [
                  _c(
                    "a",
                    { staticClass: "link", on: { click: _vm.openAgreement } },
                    [_vm._v(" " + _vm._s(_vm.$t("Settings.showUserAgreement")))]
                  ),
                ])
              : _vm._e(),
            _c(
              "Modal",
              {
                attrs: { isOpen: _vm.isOpenAgreementModal },
                on: { closeModal: _vm.closeAgreementModal },
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(_vm._s(_vm.$t("UserAgreement.title"))),
                ]),
                _c("template", { slot: "content" }, [
                  _c("div", {
                    staticClass: "UserAgreement-agreementText",
                    domProps: {
                      innerHTML: _vm._s(_vm.agreement.agreementText),
                    },
                  }),
                ]),
              ],
              2
            ),
            _c(
              "form",
              {
                staticClass: "Form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "Form-row" }, [
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item firstname required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Settings.User_FormField_firstname"))
                        ),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.firstname,
                              expression: "form.firstname",
                            },
                          ],
                          staticClass: "txt",
                          attrs: {
                            disabled: "",
                            name: "firstname",
                            autocomplete: "off",
                            type: "text",
                            id: "input-settings-form-field-firstname",
                          },
                          domProps: { value: _vm.form.firstname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "firstname",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item surname required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Settings.User_FormField_surname"))
                        ),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.surname,
                              expression: "form.surname",
                            },
                          ],
                          staticClass: "txt",
                          attrs: {
                            disabled: "",
                            name: "surname",
                            autocomplete: "off",
                            type: "text",
                            id: "input-settings-form-field-surname",
                          },
                          domProps: { value: _vm.form.surname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "surname", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "Form-row" }, [
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item email" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Settings.User_FormField_email"))),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.emailAddress,
                              expression: "form.emailAddress",
                            },
                          ],
                          staticClass: "txt",
                          attrs: {
                            name: "emailAddress",
                            disabled: "",
                            type: "email",
                          },
                          domProps: { value: _vm.form.emailAddress },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "emailAddress",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Settings.User_FormField_language"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.languageId,
                                  expression: "form.languageId",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "select",
                              class: {
                                "is-danger": _vm.veeErrors.has("languageId"),
                              },
                              attrs: {
                                id: "select-settings-user-language",
                                name: "languageId",
                                "data-vv-as": _vm.$t(
                                  "Settings.User_FormField_language"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "languageId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.clearLanguages, function (language) {
                              return _c(
                                "option",
                                {
                                  key: language.id,
                                  attrs: {
                                    id: `option-settings-user-language${language.id}`,
                                  },
                                  domProps: { value: language.id },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("Languages." + language.isoCode)
                                    )
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "Form-row" }, [
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Register.phoneNumber"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("PhoneNumber", {
                            attrs: {
                              phoneNumber: _vm.newPhoneNumber,
                              selectedCountry: _vm.country,
                              isDisabled: true,
                              existingPhoneNumber: _vm.newPhoneNumber,
                            },
                            on: {
                              getCountryCode: _vm.getCountryCode,
                              getPhoneNumber: _vm.getPhoneNumber,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-col" }, [
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Global.timeZone"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("CustomSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: "timeZone",
                              optionData: _vm.TimeZoneList,
                              optionTitle: "value",
                              selectedEmitValue: "id",
                              componentName: "time-zone-select",
                              useCustomSearchKey: true,
                              isDefaultTextActive: false,
                              defaultTitle: _vm.$t(
                                "Register.formFieldTimeZoneSelect"
                              ),
                              "data-vv-as": _vm.$t("Global.timeZone"),
                              isSearchActive: true,
                              searchIn: true,
                              error: _vm.veeErrors.has("timeZone"),
                            },
                            model: {
                              value: _vm.form.timeZoneKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "timeZoneKey", $$v)
                              },
                              expression: "form.timeZoneKey",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("timeZone"),
                                expression: "veeErrors.has('timeZone')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("timeZone"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "Form-row m-top-20" }, [
                  _c(
                    "div",
                    { staticClass: "Form-col align-self-flex-end" },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "width-max-content m-bottom-10",
                        attrs: {
                          id: "custom-checkbox-out-of-office",
                          checked: _vm.form.isOutOfOffice,
                          label: _vm.$t("Settings.Tenant_outOfOffice"),
                        },
                        on: { change: _vm.checkOutOfOffice },
                      }),
                      _c("CustomCheckbox", {
                        staticClass: "width-max-content m-bottom-10",
                        attrs: {
                          id: "custom-checkbox-showCurrencySymbol",
                          checked: _vm.form.showCurrencySymbol,
                          label: _vm.$t("Settings.Tenant_showCurrencySymbol"),
                        },
                        on: { change: _vm.showCurrencySymbol },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-row submit" }, [
                  _c(
                    "div",
                    { staticClass: "Form-item submit" },
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            primary: "",
                            variant: "full",
                            size: "medium",
                            id: "btn-settings-submit-user",
                            type: "submit",
                            disabled: _vm.shouldDisableSubmit,
                          },
                        },
                        [
                          _c("Loading", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLoading,
                                expression: "isLoading",
                              },
                            ],
                            attrs: { theme: "disable" },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isLoading,
                                  expression: "!isLoading",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Settings.User_FormField_submit"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }