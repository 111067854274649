<template lang="pug">
  div

    EmptyState.emptyState(
      v-if="!refrigeratorExtension.isActive"
      iconName="empty-state-alarm-big"
      :title="$t('Dashboard.refrigerator_isNotActive')"
      :description="$t('Dashboard.refrigerator_ActivationInfo')"
    )
    div(v-else)
      TableLoading(v-if="isWaitingRefrigeratorList || !selectedDevice")
      .refrigerator(v-else)
        .refrigerator-left
          .refrigerator-left-top
            .col-left
              .full-width
                .card-box
                  .card-box-top
                    .card-box-top-left
                      p {{ $t('Dashboard.refrigerator_roomTemperature') }}
                    .card-box-top-right
                      CustomSelect(
                        :value="selectedDevice?.shelfStorageLocationListHeaderId"
                        selectedEmitValue="shelfStorageLocationListHeaderId"
                        optionTitle="shelfStorageLocationListHeaderName"
                        :optionData="refrigeratorList"
                        :isDefaultTextActive="false"
                        :isSearchActive="true"
                        @inputSelected="getRefrigeratorInfo"
                      )
                  
                  .card-box-center
                    .card-box-center-left
                      p.current-degree {{refrigeratorDataList[0]?.value}}
                    .card-box-center-right
                  
                  .card-box-bottom
                    .card-box-bottom-left
                      .celcius °C
                    .card-box-bottom-right
                      .label {{ $t('Dashboard.refrigerator_online') }}
      
            .col-right
              .full-width
                .card-box
                  .card-box-center
                    .card-box-center-left
                      Icon(
                        name="thermometer-hot"
                        class="degree-info"
                      )
                    
                    .card-box-center-right.m-left-10
                      p.card-box-center-right-text {{ $t('Dashboard.refrigerator_cabinetTemperature') }}
                      p.card-box-center-right-text-secondary {{ refrigeratorDataList[0]?.value }}

              .full-width
                .card-box
                  .card-box-center
                    .card-box-center-left
                      Icon(
                        name="thermometer-hot"
                        class="degree-info"
                      )
                    
                    .card-box-center-right.m-left-10
                      p.card-box-center-right-text {{ $t('Dashboard.refrigerator_evaporatorTemperature') }}
                      p.card-box-center-right-text-secondary {{ refrigeratorDataList[1]?.value }}

          .refrigerator-left-bottom
            .refrigerator-left-bottom-title 
              .device-data {{ $t('Dashboard.refrigerator_deviceData') }}
              .device-name {{selectedDevice?.shelfStorageLocationListHeaderName}}
            .refrigerator-left-bottom-content
              EmptyState.emptyState-device-data(
                v-if="!refrigeratorDataList?.length"
                iconName="empty-state-alarm-big"
                :title="$t('Dashboard.refrigerator_emptyState_DeviceData')"
                :description="$t('Dashboard.refrigerator_emptyState_DeviceData_EmptyDescription')"
              )
              RefrigeratorTable(
                v-else
                :currentData="refrigeratorDataList"
                :historyData="refrigeratorHistoryDataList"
              )
            
            
        .refrigerator-right
          .alarm-title 
            .alarm-title-text {{ $t('Dashboard.refrigerator_alarmStatus') }}
            Icon(name="alarm-bell" class="alarm-bell").alarm-icon

          .alarm-status-current.m-top-15
            span.alarm-status-current-text {{ $t('Dashboard.refrigerator_current') }}

            .table-responsive
              table.table
                thead
                  tr
                    th {{ $t('Dashboard.refrigerator_dateAndTime') }}
                    th {{ $t('Dashboard.refrigerator_status') }}
                tbody
                  EmptyState.emptyState-alarm(
                    v-if="!refrigeratorAlarmDataList?.length"
                    iconName="empty-state-alarm"
                    :description="$t('Dashboard.refrigerator_Alarm_EmptyDescription')"
                  )
                  tr(v-else v-for="log in refrigeratorAlarmDataList")
                    td.date-time 
                      span.date {{ formatDate(log.time) }}
                      span.time {{ formatTime(log.time) }}

                    td.status {{ log.alarm }}

          .alarm-status-history.m-top-15
            span.alarm-status-history-text {{ $t('Dashboard.refrigerator_history') }}
            .table-responsive
              table.table
                thead
                  tr
                    th {{ $t('Dashboard.refrigerator_dateAndTime') }}
                    th {{ $t('Dashboard.refrigerator_status') }}
                tbody
                  EmptyState.emptyState-alarm(
                    v-if="!refrigeratorHistoryAlarmDataList?.length"
                    iconName="empty-state-alarm"
                    :description="$t('Dashboard.refrigerator_Alarm_History_EmptyDescription')"
                  )
                  tr(v-else v-for="log in refrigeratorHistoryAlarmDataList")
                    td.date-time 
                      span.date {{ formatDate(log.time) }}
                      span.time {{ formatTime(log.time) }}
                    
                    td.status {{ log.alarm }}

              
          
          
</template>


<script>
import ReportBox from './components/report-box'
import RefrigeratorTable from './components/refrigerator-table'

import CustomSelect from '@/view/global/custom-select'
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'Devices',
  components: {
    ReportBox,
    CustomSelect,
    RefrigeratorTable
  },

  data () {
    return {
      selectedDevice: null,
      refrigeratorList: [],
      refrigeratorAlarmDataList: [],
      refrigeratorHistoryAlarmDataList: [],
      refrigeratorDataList: [],
      refrigeratorHistoryDataList: [],
      refrigeratorExtension: false
    }
  },

  props: {
 
  },

  async mounted () {
    this.refrigeratorExtension = this.IntegrationProductList.find(i => i.name === 'EMKO')
    if (this.refrigeratorExtension.isActive) await this.getRefrigerators()
  },

  computed: {
    ...mapGetters('Integration', ['IntegrationProductList']),

    isWaitingRefrigeratorList () {
      return this.$wait.is(['getRefrigeratorData'])
    }
  },

  methods: {
    ...mapActions('Dashboard', ['getRefrigeratorList', 'getRefrigeratorData']),

    getRefrigerators () {
      const params = {
        integrationProductId: this.refrigeratorExtension.id
      }
      this.getRefrigeratorList({ params }).then(res => {
        this.refrigeratorList = res.data.intRefrigeratorList
        this.selectedDevice = this.refrigeratorList[0]
        this.getRefrigeratorInfo()
      })
    },

    async getRefrigeratorInfo (item) {
      if (item) this.selectedDevice = item
      const payload = {
        shelfStorageLocationListHeaderId: this.selectedDevice?.shelfStorageLocationListHeaderId
      }
      await this.getRefrigeratorData(payload).then(res => {
        this.refrigeratorAlarmDataList = res.data.refrigeratorAlarmDataList
        this.refrigeratorHistoryAlarmDataList = res.data.refrigeratorHistoryAlarmDataList
        this.refrigeratorDataList = res.data.refrigeratorDataList
        this.refrigeratorHistoryDataList = res.data.refrigeratorHistoryDataList
      })
    },
    formatDate (dateTime) {
      return moment(dateTime).locale('tr').format('DD MMMM YYYY');
    },
    formatTime(dateTime) {
      return moment(dateTime).locale('tr').format('HH:mm:ss');
    }
  }

}
</script>


<style lang="scss" scoped>
  .refrigerator {
    width: 100%;
    display: flex;
    &-left {
      width: 80%;
      display: flex;
      border-radius: 5px;
      flex-direction: column;
      justify-content: flex-start;
      &-top {
        width: 98%;
        display: flex;
        padding: 20px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
        .col {
          display: flex;
          width: 100%;
          &-left {
            width: 50%;

          }
          &-right {
            width: 50%;
            display: flex;
            flex-direction: column;
          }
        }

      }
      &-bottom {
        width: 98%;
        margin-top: 20px;
        padding: 20px;
        border-radius: 5px;
        background: $color-white;
        &-title {
          display: flex;
          justify-content: space-between;
          .device {
            &-data {
              font-size: $font-size-big;
              color: rgba(80, 80, 80, 1);
              font-weight: 420;
            }
            &-name {
              background: rgba(213, 228, 239, 1);
              color: rgba(16, 62, 139, 1);
              padding: 5px;
              min-width: 70px;
              width: fit-content;
              text-align: center;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
    &-right {
      padding: 20px;
      border-radius: 5px;
      background: white;
    }
  }
  .full-width {
    width: 99%;
    padding: 5px;
  }
  .half-width {
    width: 50%;
    height: 78px;
    padding: 5px;
  }
  .alarm-status {
    width: 376px; 
    height: 70vh;
    background: mediumaquamarine;
  }
  .card-box {
  background: rgba(243, 245, 247, 1);
  border-radius: $border-radius;
  padding: 20px;
  @media (max-width: 1280px) {
    margin-top: 20px;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      color: #505050;
      font-weight: 500;
    }
    &-text {
      display: flex;
      &-info {
        font-size: $font-size-small;
        color: $color-dark;
      }
    }
  }
  &-center {
    display: flex;
    align-items: center;
    &-left {
      display: flex;
      align-items: baseline;
    }
    &-right {
      font-size: $font-size-x-micro;
      font-weight: $font-weight-normal;
      color: #505050;
      &-secondary {
        font-size: $font-size-bigger;
        color: #505050;
      }
      &-text {
        font-size: $font-size-x-micro;
        font-weight: 420;
        &-secondary {
        font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.degree-info {
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
}
.current-degree {
  font-weight: $font-weight-bold;
  font-size: 44px;
  line-height: 61.16px;
  color: #505050;
}
.optimal-degree {
  color: rgba(143, 155, 169, 1);
  font-weight: $font-weight-bold;
  font-size: 20px;
}
:deep() .select-dropdown-custom {
  &-selected {
    width: fit-content !important;
  }
  &-name {
    color: #393939;
  }
}

.celcius {
  border-radius: 34px;
  background: white;
  width: 32px;
  padding: 6px 2px 1px 6px;
  height: 32px;
  font-weight: $font-weight-bold;
  color: rgba(80, 80, 80, 1);
}
.label {
  padding: 4px 10px;
  background: $color-success;
  width: fit-content;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.emptyState {
  &-alarm {
    height: unset !important;
    min-height: unset !important;
  }
  &-device-data {
    height: unset !important;
  }

}
.table-responsive {
  display: block;
  width: 336px;
  height: 207px;
  overflow-y: hidden; 

  border: 1px solid rgb(218, 219, 220);
  margin-top: 20px;
  background: #fff;

  .table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      display: table;
      width: 100%;
      table-layout: fixed;
      color: #505050;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #eaeaeb;
      tr {
        display: flex;
        justify-content: space-between;
        padding: 9px 12px;
        th {
          font-weight: 600;
          position: sticky;
          top: 0;
          background: inherit;
          z-index: 10;
        }
      }
    }

    tbody {
      display: block;
      width: 100%;
      height: 360px;
      overflow-y: auto;
      box-sizing: border-box;
      tr {
        display: table;
        width: 100%;
        box-sizing: border-box;
        height: 44px;
        padding: 9px;
        td {
          border-top: 1px solid #eaeaea;
          box-sizing: border-box; 
          padding: 9px 12px;
          &:last-child {
            text-align: right;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.date-time {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .date {
    color: rgba(143, 155, 169, 1);
    font-weight: 500;
  }
  .time {
    color: #505050;
    font-weight: bold;
  }
}
.empty-state-alarm {
  min-height: unset !important;
}
.alarm-title {
  display: flex;
  justify-content: space-between;
  &-text {
    font-size: $font-size-big;
    font-weight: $font-weight-normal;
  }
}
.alarm-status {
  font-size: $font-size-big;
  font-weight: 450;
  &-current, &-history{
    &-text {
      font-size: 14px;
      font-weight: 420px;
    }
  }
}
.alarm-icon {
  color: rgba(143, 155, 169, 1);
  width: 20px;
  height: 24px;
}
.optimal-value {
  color: rgba(143, 155, 169, 1);
}

:deep() .select-dropdown-custom .select-dropdown-custom-name[data-v-61c41260]:first-child {
  margin-right: 5px !important;
}

.emptyState-alarm {
  :deep() .empty-state-alarm {
    margin-top: 19px;
    margin-bottom: 15px;
    width: 75px !important;
    height: 59px !important;
  } 
  :deep() .EmptyState-inner{
    margin-top: 10px !important;
  }
  :deep() .title {
    margin: 0 !important;
  }
}
</style>