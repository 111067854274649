var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isTraniningRecipe
        ? _c("TrainingRecipeConfirmation", {
            staticClass: "training-recipe-container",
            attrs: { isLoading: _vm.isOverwritingRecipe },
            on: {
              clickCancel: _vm.cancelTrainingRecipe,
              clickOverwrite: _vm.overwriteTrainingRecipe,
              clickAsNew: _vm.asNewTrainingRecipe,
            },
          })
        : _vm._e(),
      _c(
        "Page",
        [
          _c(
            "template",
            { slot: "header-breadcrumb-left" },
            [
              _vm.$wait.is("pageLoading")
                ? _c("Loading")
                : _c(
                    "div",
                    { staticClass: "Breadcrumb m-top-5" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            id: "link-recipe-breadcrumb",
                            to: { name: "Recipes" },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Recipe.breadcrumb_Recipes")))]
                      ),
                      _c("span", [_vm._v(_vm._s(">"))]),
                      _c(
                        "div",
                        { staticClass: "relative-pairs-tooltip" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "isSelected",
                              attrs: {
                                href: "",
                                id: "link-recipe-breadcrumb-active",
                              },
                            },
                            [_vm._v(_vm._s(_vm.recipeBreadcrumb))]
                          ),
                          _c("PairsTooltip", {
                            attrs: {
                              list: _vm.breadCrumbHoverInfo,
                              itemKey: "key",
                              itemValue: "value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "header-left-dropdown" },
            [
              _c("PageTabButtons", {
                staticClass: "page-tabs m-left-10",
                attrs: {
                  selectedList: _vm.pageHeaderLinks,
                  translationGroup: "TabItems",
                  matchedRoute: "Recipes",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEmpty,
                      expression: "!isEmpty",
                    },
                  ],
                  attrs: { size: "medium", id: "btn-recipe-add", primary: "" },
                  on: { click: _vm.newStock },
                },
                [_vm._v(_vm._s(_vm.$t("Recipe.addIngredientButton")))]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "overgroups-left" },
            [
              _vm.isTraniningRecipe && !_vm.isEmpty
                ? _c("Statistic", {
                    staticClass: "m-bottom-10",
                    attrs: {
                      recipe: _vm.recipe_Detail,
                      showEstimatedCost: _vm.showEstimatedCost,
                    },
                  })
                : _vm._e(),
              !_vm.isTraniningRecipe && !_vm.isEmpty
                ? _c("BoxView", {
                    attrs: {
                      data: _vm.statisticsInfos,
                      fields: _vm.statisticsInfosFields,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "toolbar-left" },
            [
              _c("ToggleOverGroups", {
                attrs: {
                  list: _vm.typeList,
                  selectedItem: _vm.selectedRecipeInfo,
                  useUpperCase: true,
                },
                on: { delegateChangeSelectedItem: _vm.changeTab },
              }),
              _c("div", { staticClass: "show-more" }, [
                _c("div", { staticClass: "Form-item dropdown-handle" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeOtherOptions,
                          expression: "closeOtherOptions",
                        },
                      ],
                      staticClass: "select dropdown-handle-button",
                      attrs: {
                        "aria-expanded": _vm.showOtherOptions,
                        tabindex: "0",
                      },
                      on: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toggleOtherOptions.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                            return _vm.toggleOtherOptions.apply(null, arguments)
                          },
                        ],
                        click: _vm.toggleOtherOptions,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dropdown-handle-button-values" },
                        [_vm._v(_vm._s(_vm.$t("Global.moreOptions")))]
                      ),
                      _c("Icon", {
                        staticClass:
                          "dropdown-handle-button-icon icon icon-down-arrow",
                        class: { open: _vm.showOtherOptions },
                        attrs: { name: "icon-down-arrow" },
                      }),
                    ],
                    1
                  ),
                  _vm.showOtherOptions
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "shortkey",
                              rawName: "v-shortkey",
                              value: ["esc"],
                              expression: "['esc']",
                            },
                          ],
                          staticClass: "MiniPopup left",
                        },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              id: "checkbox-recipe-show-alternative",
                              disabled: _vm.isTypeAllergen,
                              label: _vm.$t(
                                "Recipe.Checkbox_Show_Alternatives"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getList({ loadingName: null })
                              },
                            },
                            model: {
                              value: _vm.showAlternativeStocks,
                              callback: function ($$v) {
                                _vm.showAlternativeStocks = $$v
                              },
                              expression: "showAlternativeStocks",
                            },
                          }),
                          _c("CustomCheckbox", {
                            attrs: {
                              id: "checkbox-recipe-show-estimated-counts",
                              disabled: _vm.isTypeAllergen,
                              label: _vm.$t(
                                "Recipe.Checkbox_Show_Estimated_Counts"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.estimatedDataList([
                                  "cost",
                                  "costRatio",
                                  "totalCost",
                                ])
                              },
                            },
                            model: {
                              value: _vm.showEstimatedCost,
                              callback: function ($$v) {
                                _vm.showEstimatedCost = $$v
                              },
                              expression: "showEstimatedCost",
                            },
                          }),
                          _c("CustomCheckbox", {
                            attrs: {
                              id: "checkbox-recipe-show-last-purchase",
                              disabled: _vm.isTypeAllergen,
                              label: _vm.$t(
                                "Recipe.Checkbox_Show_Last_Purchase"
                              ),
                            },
                            on: { change: _vm.getDetails },
                            model: {
                              value: _vm.showLastPurchase,
                              callback: function ($$v) {
                                _vm.showLastPurchase = $$v
                              },
                              expression: "showLastPurchase",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "template",
            { slot: "toolbar-right" },
            [
              _vm.isTypeTotal && !_vm.isTypeAllergen
                ? _c("ImportExport", {
                    ref: "wrapper",
                    attrs: {
                      componentName: "recipe",
                      activeImportExports: ["export"],
                      downloadUrl: "Recipe/detailtotal/export",
                      downloadParams: _vm.downloadParams,
                      downloadFileName: _vm.downloadFileName,
                      downloadFileType: "xlsx",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "content-center" },
            [
              _c(
                "div",
                { staticClass: "RecipeItem" },
                [
                  _vm.$wait.is("pageLoading")
                    ? _c("TableLoading")
                    : [
                        !_vm.isEmpty
                          ? _c("div", { staticClass: "alt-toolbar" }, [
                              _c(
                                "div",
                                { staticClass: "alt-toolbar-left" },
                                [
                                  _vm.selectedRecipeInfo.id === 1
                                    ? _c("ContentTab", {
                                        staticClass: "alt-toolbar-item",
                                        attrs: {
                                          data: _vm.tabs,
                                          id: "recipe",
                                          size: "lg",
                                        },
                                        model: {
                                          value: _vm.selectedTab,
                                          callback: function ($$v) {
                                            _vm.selectedTab = $$v
                                          },
                                          expression: "selectedTab",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "alt-toolbar-right flexbox" },
                                [
                                  !_vm.isTraniningRecipe
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "create-training-recipe",
                                        },
                                        [
                                          _vm.isLoading
                                            ? _c("Loading")
                                            : _c(
                                                "Button",
                                                {
                                                  staticClass: "bgc-none",
                                                  attrs: {
                                                    size: "small",
                                                    variant: "link",
                                                    id: "switch-btn-create-training-recipe",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.addTrainingRecipe,
                                                  },
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      name: "icon-global-add",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Recipe.Change_Training_Recipe_Label"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isTypeTotal &&
                        !_vm.isTypeAllergen &&
                        _vm.selectedRecipeInfo.id === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "flexbox",
                                attrs: { id: "recipe-detail-table" },
                              },
                              [
                                _c("TableView", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEmpty,
                                      expression: "!isEmpty",
                                    },
                                  ],
                                  attrs: {
                                    componentName: "recipeTable",
                                    fields: _vm.detailFields,
                                    data: _vm.recipe_Detail.list,
                                    pointer: true,
                                    actions: ["edit", "remove"],
                                    addScrollToNoFixedColumns: true,
                                  },
                                  on: {
                                    delegateOnItemEdit: _vm.editContent,
                                    delegateOnItemDelete: _vm.deleteContent,
                                  },
                                }),
                                _c("portal", { attrs: { to: "orderRecipe" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "order-number" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Recipe.tableColumn_Order_Number"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("Button", {
                                        class: !_vm.recipeDetailOrderNumberList
                                          .length
                                          ? "disabled"
                                          : "",
                                        attrs: {
                                          size: "small",
                                          variant: "full",
                                          secondary: "",
                                          id: "order-recipe",
                                          iconName: "icon-navigation-transfer",
                                          iconClass: "order-number-icon",
                                        },
                                        on: { click: _vm.changeOrder },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("portal", {
                                  attrs: { to: "orderNumber" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              _c("customNumberInput", {
                                                attrs: {
                                                  name: `recipe-order-${row.rowIndex}-${row?.id}`,
                                                  id: "input-order-recipe",
                                                  extraDataEmit: row,
                                                },
                                                on: { blur: _vm.setOrderList },
                                                model: {
                                                  value: row.orderNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "orderNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.orderNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3477216653
                                  ),
                                }),
                                _c("portal", {
                                  attrs: { to: "loss1" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("customNumberInput", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        "greater_than:0|max_value:100",
                                                      expression:
                                                        "'greater_than:0|max_value:100'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: `loss1-${row.index}`,
                                                    error: _vm.veeErrors.has(
                                                      `loss1-${row.index}`
                                                    ),
                                                    placeholder: ",00",
                                                    isTypePercentage: true,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.changeInlineUpdate(
                                                        {
                                                          row,
                                                          scope: `row${row.index}`,
                                                        }
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.loss1,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "loss1",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.loss1",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    679687282
                                  ),
                                }),
                                _c("portal", {
                                  attrs: { to: "loss2" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("customNumberInput", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        "greater_than:0|max_value:100",
                                                      expression:
                                                        "'greater_than:0|max_value:100'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: `loss2-${row.index}`,
                                                    disabled:
                                                      row.loss1 === 0 ||
                                                      row.loss1 === null,
                                                    error: _vm.veeErrors.has(
                                                      `loss2-${row.index}`
                                                    ),
                                                    placeholder: ",00",
                                                    isTypePercentage: true,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.changeInlineUpdate(
                                                        {
                                                          row,
                                                          scope: `row${row.index}`,
                                                        }
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.loss2,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "loss2",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.loss2",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1064017116
                                  ),
                                }),
                                _c("portal", {
                                  attrs: { to: "netQuantity" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("customNumberInput", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.isAlternativeStockTab
                                                          ? "required|decimal:6|is_not:0"
                                                          : "",
                                                      expression:
                                                        "isAlternativeStockTab ? 'required|decimal:6|is_not:0' : ''",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: `netQuantity-${row.index}`,
                                                    error: _vm.veeErrors.has(
                                                      `netQuantity-${row.index}`
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.changeInlineUpdate(
                                                        {
                                                          row,
                                                          scope: `row${row.index}`,
                                                        }
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.netQuantity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "netQuantity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.netQuantity",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2261578055
                                  ),
                                }),
                                _c("portal", {
                                  attrs: { to: "unitName" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("customSelect", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.isAlternativeStockTab
                                                          ? "required"
                                                          : "",
                                                      expression:
                                                        "isAlternativeStockTab ? 'required' : ''",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: `unitName-${row.index}`,
                                                    error: _vm.veeErrors.has(
                                                      `unitName-${row.index}`
                                                    ),
                                                    selectedEmitValue: "id",
                                                    optionTitle: "name",
                                                    optionData:
                                                      _vm.getUnitIdOptions(row),
                                                    isDefaultTextActive: false,
                                                  },
                                                  on: {
                                                    inputSelected: function (
                                                      $event
                                                    ) {
                                                      return _vm.changeInlineUpdate(
                                                        {
                                                          row,
                                                          scope: `row${row.index}`,
                                                        }
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.unitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "unitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.unitId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3296397749
                                  ),
                                }),
                                _c("portal", { attrs: { to: "headerType" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "category portal-header-type",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "category-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Recipe.tableColumn_Ingredients_Type"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "category-info" },
                                        [
                                          _c("Icon", {
                                            staticClass:
                                              "show-MiniPopup category-info-icon",
                                            attrs: {
                                              name: "icon-global-info-filled",
                                            },
                                          }),
                                          _c(
                                            "ul",
                                            { staticClass: "category-popup" },
                                            _vm._l(
                                              _vm.popupInfoObject,
                                              function (item) {
                                                return _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "category-popup-row",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "category-popup-value",
                                                        class: `label-table-${item.name}`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "category-popup-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("portal", {
                                  attrs: { to: "detailType" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c(
                                            "span",
                                            {
                                              staticClass: "portal-detail-type",
                                            },
                                            [
                                              row.type === 2
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "show-MiniPopup relative label-table-recipe",
                                                      attrs: {
                                                        size: "small",
                                                        id: "btn-recipe-detail-badge",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goToRecipe(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Recipe.tableColumn_Ingredients_RecipeLabel"
                                                            )
                                                          )
                                                      ),
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "m-top-20 MiniPopup-left-primary popup detail-types",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "popup-row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Recipe.extraInfo_NetSalesPrice"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-value ff-mono u-textRight",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatCurrency6Digits"
                                                                      )(
                                                                        row.recipeNetSalesPrice
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "popup-row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Recipe.extraInfo_Cost"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-value ff-mono",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatCurrency6Digits"
                                                                      )(
                                                                        row.cost
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "popup-row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Recipe.extraInfo_CostRatio"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popup-row-value ff-mono",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "% " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "percentFixed"
                                                                        )(
                                                                          row.costRatio
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showAlternativeStocks &&
                                              row.isAlternativeStock
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "label-table-alternative",
                                                      attrs: { size: "small" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Recipe.tableColumn_Ingredients_AlternativeLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.showAlternativeStocks &&
                                              row.hasAlternativeStock
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "label-table-stock",
                                                      attrs: { size: "small" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Recipe.tableColumn_Ingredients_StockLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3564851945
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isTypeTotal && !_vm.isTypeAllergen
                          ? _c(
                              "div",
                              { attrs: { id: "recipe-detail-totals-table" } },
                              [
                                _c("TableView", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEmpty,
                                      expression: "!isEmpty",
                                    },
                                  ],
                                  attrs: {
                                    fields: _vm.detailTotalFields,
                                    componentName: "RecipeDetailTotalsTable",
                                    data: _vm.detailTotals,
                                    pointer: true,
                                    actions: [""],
                                    readOnly: true,
                                    addScrollToNoFixedColumns: true,
                                  },
                                }),
                                _c("portal", {
                                  attrs: { to: "calorie" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return row.hasNutritionValue
                                            ? _c(
                                                "div",
                                                {},
                                                [
                                                  _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "c-light m-right-15 show-MiniPopup relative",
                                                      attrs: {
                                                        size: "small",
                                                        id: `box-list-RecipesBox-row-${row.index}-hover`,
                                                        iconName:
                                                          "icon-calorie",
                                                        iconClass:
                                                          "icon-calorie",
                                                        justIcon: true,
                                                        clearVariant: true,
                                                      },
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.showTotalCalorie(
                                                            row
                                                          )
                                                        },
                                                        mouseleave: function (
                                                          $event
                                                        ) {
                                                          return _vm.showCalorieLeave()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.isShowCalorie
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "MiniPopup-left-primary m-top-15 c-dark custom-mini-popup-table calorie-box",
                                                            },
                                                            [
                                                              _vm.isCalorieBoxLoading
                                                                ? _c("Loading")
                                                                : _vm._l(
                                                                    _vm.totalCalorie,
                                                                    function (
                                                                      i,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "calorie-popup",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "m-right-5",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  i
                                                                                    .value[0]
                                                                                ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    i
                                                                                      .unit[0]
                                                                                  ) +
                                                                                  " |"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "m-right-5",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  i
                                                                                    .value[1]
                                                                                ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    i
                                                                                      .unit[1]
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c(
                                  "portal",
                                  {
                                    staticClass: "portal-header-type",
                                    attrs: { to: "totalHeaderType" },
                                  },
                                  [
                                    _c("div", { staticClass: "category" }, [
                                      _c(
                                        "span",
                                        { staticClass: "category-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Recipe.tableColumn_Ingredients_Type"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "category-info" },
                                        [
                                          _c("Icon", {
                                            staticClass:
                                              "show-MiniPopup category-info-icon",
                                            attrs: {
                                              name: "icon-global-info-filled",
                                            },
                                          }),
                                          _c(
                                            "ul",
                                            { staticClass: "category-popup" },
                                            _vm._l(
                                              _vm.popupInfoObject,
                                              function (item) {
                                                return _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "category-popup-row",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "category-popup-value",
                                                        class: `label-table-${item.name}`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "category-popup-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("portal", {
                                  attrs: { to: "totalDetailType" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c(
                                            "span",
                                            {},
                                            [
                                              _vm.showAlternativeStocks &&
                                              row.isAlternativeStock
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "label-table-alternative",
                                                      attrs: { size: "small" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Recipe.tableColumn_Ingredients_AlternativeLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.showAlternativeStocks &&
                                              row.hasAlternativeStock
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticClass:
                                                        "label-table-stock",
                                                      attrs: { size: "small" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Recipe.tableColumn_Ingredients_StockLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1983994143
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isTypeTotal &&
                        !_vm.isTypeAllergen &&
                        !_vm.isTypeCookingDetails &&
                        _vm.isTypeRecipeInfo
                          ? _c(
                              "div",
                              {
                                staticClass: "info-container",
                                attrs: { id: "recipe-detail-allergen-table" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "add-info-container" },
                                  [
                                    _c("span", [
                                      _c(
                                        "label",
                                        { staticClass: "Form-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Recipe.servingInfo"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "control" },
                                        [
                                          _c("customTextareaInput", {
                                            staticStyle: { resize: "none" },
                                            attrs: {
                                              name: "servingInfo",
                                              id: "input-recipes-form-field-serving-information",
                                            },
                                            model: {
                                              value:
                                                _vm.recipeHeader.servingInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.recipeHeader,
                                                  "servingInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "recipeHeader.servingInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("span", [
                                      _c(
                                        "label",
                                        { staticClass: "Form-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Recipe.mixInfo"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "control" },
                                        [
                                          _c("customTextareaInput", {
                                            staticStyle: { resize: "none" },
                                            attrs: {
                                              name: "mixInfo",
                                              id: "input-recipes-form-field-information",
                                            },
                                            model: {
                                              value:
                                                _vm.recipeHeader.information,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.recipeHeader,
                                                  "information",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "recipeHeader.information",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "add-info-container-preparation-time",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "add-info-container-preparation-time-label",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("Recipe.preparationTime")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "control" },
                                          [
                                            _c("customNumberInput", {
                                              attrs: {
                                                name: "preparationTime",
                                                id: "input-recipes-form-field-preparationTime",
                                              },
                                              model: {
                                                value:
                                                  _vm.recipeHeader
                                                    .preparationTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.recipeHeader,
                                                    "preparationTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "recipeHeader.preparationTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "add-info-button m-top-25" },
                                  [
                                    _c(
                                      "Button",
                                      {
                                        attrs: {
                                          id: "btn-recipe-add",
                                          primary: "",
                                          size: "medium",
                                          disabled: _vm.isRecipeInfoLoading,
                                        },
                                        on: { click: _vm.addRecipeInfo },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isRecipeInfoLoading,
                                                expression:
                                                  "!isRecipeInfoLoading",
                                              },
                                            ],
                                          },
                                          [
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("Recipe.addRecipeInfo")
                                                )
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                        _c("Loading", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isRecipeInfoLoading,
                                              expression: "isRecipeInfoLoading",
                                            },
                                          ],
                                          attrs: { theme: "disable" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isTypeTotal &&
                        _vm.isTypeAllergen &&
                        !_vm.isTypeCookingDetails &&
                        _vm.recipeAllergenList.length > 0
                          ? _c(
                              "div",
                              { attrs: { id: "recipe-detail-allergen-table" } },
                              [
                                _c("TableView", {
                                  attrs: {
                                    fields: _vm.getDetailInfo,
                                    componentName: "RecipeDetailAllergen",
                                    data: _vm.recipeAllergenList,
                                    pointer: true,
                                    readOnly: true,
                                    actions: [""],
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isTypeAllergen &&
                        _vm.isTypeCookingDetails &&
                        !_vm.isTypeTotal
                          ? _c(
                              "div",
                              { staticClass: "cookingDetails" },
                              [
                                _c("TableView", {
                                  attrs: {
                                    fields: _vm.cookingDetailInfo,
                                    readOnly: true,
                                    data: _vm.cookingTimeList,
                                  },
                                }),
                                _c("portal", {
                                  attrs: { to: "toPos" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "to-pos-radio-button",
                                            },
                                            [
                                              _c("CustomRadio", {
                                                attrs: {
                                                  checked: row.toPos,
                                                  label: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changeToPosType(
                                                      row
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: row.toPos,
                                                      expression: "row.toPos",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "m-left-10 to-pos-warning",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Recipe.sendToPosWarning"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1416711130
                                  ),
                                }),
                                _c("portal", {
                                  attrs: { to: "cookingTime" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (row) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("customNumberInput", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        "min_value:0|max_value:999",
                                                      expression:
                                                        "'min_value:0|max_value:999'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "recipe-add-cookingTime",
                                                    name: `cooktime-${row.index}`,
                                                    error: _vm.veeErrors.has(
                                                      `cooktime-${row.index}`
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.changeCookingTime(
                                                        row,
                                                        "cookingTime"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.cookingTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "cookingTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.cookingTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3169594157
                                  ),
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-info-button m-top-25 p-right-25",
                                  },
                                  [
                                    _c(
                                      "Button",
                                      {
                                        attrs: {
                                          id: "btn-recipe-add",
                                          primary: "",
                                          size: "medium",
                                          disabled: _vm.isRecipeInfoLoading,
                                        },
                                        on: { click: _vm.addRecipeInfo },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isRecipeInfoLoading,
                                                expression:
                                                  "!isRecipeInfoLoading",
                                              },
                                            ],
                                          },
                                          [
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Recipe.addCookingDetails"
                                                  )
                                                )
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                        _c("Loading", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isRecipeInfoLoading,
                                              expression: "isRecipeInfoLoading",
                                            },
                                          ],
                                          attrs: { theme: "disable" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isTypeTotalCalories &&
                        _vm.recipeHeader.hasNutritionValues
                          ? _c(
                              "div",
                              {
                                attrs: {
                                  id: "recipe-detail-nutrition-total-table",
                                },
                              },
                              [
                                _vm.isNutritionValueLoading
                                  ? _c("TableLoading")
                                  : _vm._e(),
                                _vm.recipeHeader.hasNutritionValues &&
                                !_vm.isNutritionValueLoading
                                  ? _c("TableView", {
                                      attrs: {
                                        fields: _vm.TotalNutritionFields,
                                        componentName:
                                          "RecipeTotalNutritionValues",
                                        data: _vm.totalCalorieValues,
                                        pointer: true,
                                        actions: [""],
                                        readOnly: true,
                                        addScrollToNoFixedColumns: true,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isTypeNutritionValueDetails &&
                        _vm.allNutritionDetails.length > 0 &&
                        _vm.recipeHeader.hasNutritionValues
                          ? _c(
                              "div",
                              {
                                attrs: { id: "recipe-nutrition-detail-table" },
                              },
                              [
                                _vm.isNutritionValueLoading
                                  ? _c("TableLoading")
                                  : _c(
                                      "div",
                                      { staticClass: "flexbox" },
                                      [
                                        _c("TableView", {
                                          attrs: {
                                            componentName:
                                              "recipeTableNutritionValuesStatic",
                                            fields:
                                              _vm.staticNutritionTableFields,
                                            data: _vm.staticNutritionData,
                                            pointer: true,
                                            readOnly: true,
                                          },
                                        }),
                                        _c("TableView", {
                                          attrs: {
                                            componentName:
                                              "recipeTableNutritionValues",
                                            fields:
                                              _vm.allNutritionDetailFields(),
                                            data: _vm.allNutritionDetails,
                                            pointer: true,
                                            readOnly: true,
                                            addScrollToNoFixedColumns: true,
                                          },
                                        }),
                                        _c("portal", {
                                          attrs: {
                                            to: "action-calorie-detail",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (row) {
                                                  return _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _c(
                                                        "Button",
                                                        {
                                                          staticClass:
                                                            "c-light m-right-15 show-MiniPopup relative",
                                                          attrs: {
                                                            size: "small",
                                                            id: `box-list-RecipesBox-row-${row.index}-hover`,
                                                            iconName:
                                                              "icon-calorie",
                                                            iconClass:
                                                              "icon-calorie",
                                                            justIcon: true,
                                                            clearVariant: true,
                                                          },
                                                          on: {
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.showTotalCalorie(
                                                                  row
                                                                )
                                                              },
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.showCalorieLeave()
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _vm.isShowCalorie
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "MiniPopup-left-primary m-top-15 c-dark custom-mini-popup-table calorie-box",
                                                                },
                                                                [
                                                                  _vm.isCalorieBoxLoading
                                                                    ? _c(
                                                                        "Loading"
                                                                      )
                                                                    : _vm._l(
                                                                        _vm.totalCalorie,
                                                                        function (
                                                                          i,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "calorie-popup",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "m-right-5",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      i
                                                                                        .value[0]
                                                                                    ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        i
                                                                                          .unit[0]
                                                                                      ) +
                                                                                      " |"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "m-right-5",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      i
                                                                                        .value[1]
                                                                                    ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        i
                                                                                          .unit[1]
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3684991377
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                  _c("portal", {
                    attrs: { to: "symbol" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (detail) {
                          return _c(
                            "div",
                            { staticClass: "allergens width-auto" },
                            [_c("Icon", { attrs: { name: detail.iconName } })],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _vm.isEmpty &&
                  (!_vm.isLoading || !_vm.isNutritionValueLoading)
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Recipe.emptyState_Title"),
                          description: _vm.$t("Recipe.emptyState_Description"),
                          buttonText: _vm.$t("Recipe.addIngredientButton"),
                        },
                        on: { emptyAddButton: _vm.newStock },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c("router-view", {
                key: _vm.$route.fullPath,
                on: {
                  getList: function ($event) {
                    return _vm.getList({ fetchAll: true })
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }