var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Despatches" },
    [
      _c("div", { staticClass: "single-head" }, [
        _c(
          "div",
          { staticClass: "single-head-col checkbox" },
          [
            _c("CustomCheckbox", {
              attrs: {
                id: "checkbox-despatch-header-select-all",
                checked: _vm.isCheckedAll && _vm.selectableList.length > 0,
                label: "",
                disabled: _vm.selectableList.length === 0,
              },
              on: { change: _vm.toggleAll },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "single-head-col name" }, [
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("Despatches.tableColumn_Header_SupplierName"))
            ),
          ]),
        ]),
        _c("div", { staticClass: "single-head-col date" }, [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("Despatches.tableHeader_RefundDate"))),
          ]),
        ]),
        _c("div", { staticClass: "single-head-col number" }, [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("Despatches.tableHeader_RefundNumber"))),
          ]),
        ]),
        _c("div", { staticClass: "single-head-col net" }, [
          _c("h6", { staticClass: "u-textRight" }, [
            _vm._v(_vm._s(_vm.$t("Despatches.tableColumn_NetTotalAmount"))),
          ]),
        ]),
        _c("div", { staticClass: "single-head-col tax" }, [
          _c("h6", { staticClass: "u-textRight" }, [
            _vm._v(_vm._s(_vm.$t("Despatches.tableColumn_TaxTotalAmount"))),
          ]),
        ]),
        _c("div", { staticClass: "single-head-col gross" }, [
          _c("h6", { staticClass: "u-textRight" }, [
            _vm._v(_vm._s(_vm.$t("Despatches.tableColumn_GrossTotalAmount"))),
          ]),
        ]),
      ]),
      _vm._l(_vm.tableData, function (despatch) {
        return _vm.renderComponent
          ? _c(
              "div",
              {
                staticClass: "Despatches-item",
                class: {
                  isOpened: despatch.showDetail,
                  "small-size": true,
                  "border-blue": despatch.showDetail,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header",
                    attrs: { tabindex: "0" },
                  },
                  [
                    _c("div", {
                      staticClass: "NestedTableHeaderLink",
                      attrs: { id: "btn-despatches-open-header" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetailAction(despatch)
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col" },
                      [
                        despatch.status === 1
                          ? _c("StatusTooltip", {
                              attrs: {
                                title: _vm.$t("Despatches.HeaderNotSaved"),
                                icon: "icon-row-status",
                              },
                            })
                          : _vm._e(),
                        despatch.accountingStatus === 3
                          ? _c("StatusTooltip", {
                              attrs: {
                                title: despatch.accountingMessage,
                                icon: "icon-row-status",
                              },
                            })
                          : _vm._e(),
                        _c("CustomCheckbox", {
                          class: { "hide-checkbox": despatch.status === 1 },
                          attrs: {
                            id: `checkbox-despatch-header-${despatch.id}`,
                            checked: _vm.selectedList.indexOf(despatch) > -1,
                            label: "",
                            disabled:
                              _vm.firstSelectedSupplierId(
                                despatch.supplier.id
                              ) && _vm.checkSameSupplier,
                          },
                          on: {
                            change: (isChecked) =>
                              _vm.clickCheckbox(isChecked, despatch),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col name ellipsis" },
                      [
                        _c(
                          "span",
                          { staticClass: "tooltip-relative" },
                          [
                            _vm._v(" " + _vm._s(despatch.supplier.name)),
                            _c("Tooltip", {
                              attrs: {
                                dark: "",
                                bottom: "",
                                text: _vm.getStoreGroupsCode(
                                  despatch.storeGroups
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "Despatches-header-col date" }, [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(despatch.despatchDate))
                      ),
                    ]),
                    _c("div", { staticClass: "Despatches-header-col number" }, [
                      _vm._v(_vm._s(despatch.despatchNumber)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-col net ff-mono u-textRight",
                      },
                      [
                        _vm.showCurrencySymbol && despatch.netTotalAmount
                          ? _c("span", [
                              _vm._v(_vm._s(despatch.currencyDefinitionSymbol)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "u-textRight" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency3Digits")(
                                despatch.netTotalAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-col tax ff-mono u-textRight",
                      },
                      [
                        _vm.showCurrencySymbol && despatch.taxTotalAmount
                          ? _c("span", [
                              _vm._v(_vm._s(despatch.currencyDefinitionSymbol)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "u-textRight" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency3Digits")(
                                despatch.taxTotalAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-col gross ff-mono u-textRight",
                      },
                      [
                        _vm.showCurrencySymbol && despatch.grossTotalAmount
                          ? _c("span", [
                              _vm._v(_vm._s(despatch.currencyDefinitionSymbol)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "u-textRight" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency3Digits")(
                                despatch.grossTotalAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col" },
                      [
                        despatch.description
                          ? _c(
                              "Button",
                              {
                                staticClass:
                                  "show-MiniPopup relative width-max-content",
                                attrs: {
                                  size: "small",
                                  variant: "info",
                                  iconName: "icon-global-info",
                                  justIcon: true,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "MiniPopup-right-primary m-top-15 c-dark",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(despatch.description)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-decompose font-size-small",
                      },
                      [_vm._v(_vm._s(despatch.decomposedInvoiceNumber))]
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col action" },
                      [
                        _c("Button", {
                          attrs: {
                            type: "button",
                            ghostsuccess: "",
                            justIcon: true,
                            variant: "icon switch",
                            iconName: "icon-arrow-down",
                            iconClass: "icon-arrow-down",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showDetailAction(despatch)
                            },
                          },
                        }),
                        _c("ActionButton", {
                          attrs: {
                            id: "refund-table-action-button",
                            data:
                              despatch.status === 1
                                ? ["export", "edit", "remove"]
                                : ["export"],
                            item: despatch,
                          },
                          on: {
                            onItemExport: function ($event) {
                              return _vm.showExportPopup(despatch.id)
                            },
                            onItemEdit: function ($event) {
                              return _vm.editAction(despatch)
                            },
                            onItemDelete: function ($event) {
                              return _vm.removeItemAction(despatch)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                despatch.showDetail
                  ? _c("RefundDetails", {
                      attrs: { despatch: despatch },
                      on: { editExpireDate: _vm.editExpireDate },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }