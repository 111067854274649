var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Invoices", attrs: { id: "Invoices" } },
    [
      _c(
        "div",
        { staticClass: "single-head", attrs: { id: "single-head-invoice" } },
        [
          _c(
            "div",
            {
              staticClass: "single-head-col checkbox",
              attrs: { id: "invoice-head-col-0" },
            },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-despatch-header-select-all",
                  checked: _vm.isCheckedAll && _vm.selectableList.length > 0,
                  label: "",
                  disabled: _vm.selectableList.length === 0,
                },
                on: { change: _vm.toggleAll },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col name",
              attrs: { id: "invoice-head-col-1" },
            },
            [
              _c("h6", { attrs: { id: "invoice-head-col-text-1" } }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.tableColumn_Header_SupplierName"))
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col integration-status",
              attrs: { id: "invoice-head-col-2" },
            },
            [
              _c("h6", { attrs: { id: "invoice-head-col-text-2" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("Despatches.tableColumn_Header_Integration_Status")
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col date",
              attrs: { id: "invoice-head-col-3" },
            },
            [
              _c("h6", { attrs: { id: "invoice-head-col-text-3" } }, [
                _vm._v(_vm._s(_vm.$t("Invoices.tableHeader_InvoiceDate"))),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col number",
              attrs: { id: "invoice-head-col-4" },
            },
            [
              _c("h6", { attrs: { id: "invoice-head-col-text-4" } }, [
                _vm._v(_vm._s(_vm.$t("Invoices.tableHeader_InvoiceNumber"))),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col net",
              attrs: { id: "invoice-head-col-5" },
            },
            [
              _c(
                "h6",
                {
                  staticClass: "u-textRight",
                  attrs: { id: "invoice-head-col-text-5" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_NetTotalAmount"))
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col tax",
              attrs: { id: "invoice-head-col-6" },
            },
            [
              _c(
                "h6",
                {
                  staticClass: "u-textRight",
                  attrs: { id: "invoice-head-col-text-6" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_TaxTotalAmount"))
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col gross",
              attrs: { id: "invoice-head-col-7" },
            },
            [
              _c(
                "h6",
                {
                  staticClass: "u-textRight",
                  attrs: { id: "invoice-head-col-text-7" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_GrossTotalAmount"))
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col roundingTolerance",
              attrs: { id: "invoice-head-col-8" },
            },
            [
              _c(
                "h6",
                {
                  staticClass: "u-textRight",
                  attrs: { id: "invoice-head-col-text-8" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_RoundingTolerance  "))
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "single-head-col isEInvoice",
              attrs: { id: "invoice-head-col-9" },
            },
            [
              _c(
                "h6",
                {
                  staticClass: "u-textRight",
                  attrs: { id: "invoice-head-col-text-9" },
                },
                [_vm._v(_vm._s(_vm.$t("Despatches.eInvoice")))]
              ),
            ]
          ),
          _c("div", {
            staticClass: "single-head-col invoice-partition align-center",
            attrs: { id: "invoice-head-col-9" },
          }),
          _c("div", {
            staticClass: "single-head-col invoice-partition align-center",
            attrs: { id: "invoice-head-col-10" },
          }),
        ]
      ),
      _vm._l(_vm.tableData, function (invoice, index) {
        return _c(
          "div",
          {
            staticClass: "Invoices-item",
            class: {
              isOpened: invoice.showDetail,
              "small-size": true,
              "border-blue": invoice.showDetail,
            },
            attrs: { id: `invoice-item-${index}` },
          },
          [
            _c(
              "div",
              {
                staticClass: "Invoices-header",
                attrs: { id: `invoice-item-header-${index}` },
              },
              [
                _c("div", {
                  staticClass: "NestedTableHeaderLink",
                  attrs: { id: "btn-invoices-open-header" },
                  on: {
                    click: function ($event) {
                      return _vm.showDetailAction(invoice)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col checkbox",
                    attrs: { id: `invoice-item-header-tooltip-${index}` },
                  },
                  [
                    invoice.accountingStatus === 3
                      ? _c("StatusTooltip", {
                          attrs: {
                            id: `invoice-item-header-status-tooltip-${index}`,
                            title: invoice.accountingMessage,
                            defaultMessage: _vm.$t(
                              "Despatches.Invoice_Integration_Message"
                            ),
                            icon: "icon-row-status",
                          },
                        })
                      : _vm._e(),
                    _c("CustomCheckbox", {
                      attrs: {
                        id: `checkbox-invoice-header-${invoice.id}`,
                        checked: _vm.selectedList.indexOf(invoice) > -1,
                        label: "",
                        disabled: _vm.selectableList.indexOf(invoice) === -1,
                      },
                      on: {
                        change: (isChecked) =>
                          _vm.clickCheckbox(isChecked, invoice),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "Invoices-header-col status",
                  attrs: { id: `invoice-item-header-status-${index}` },
                }),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col name",
                    attrs: { id: `invoice-item-header-supplier-name-${index}` },
                  },
                  [
                    _c("div", { staticClass: "Invoices-header-row ellipsis" }, [
                      _c(
                        "span",
                        {
                          staticClass: "tooltip-relative",
                          attrs: {
                            id: `invoice-item-header-supplier-name-text-${index}`,
                          },
                        },
                        [
                          _vm._v(_vm._s(invoice.supplier.name)),
                          _c("Tooltip", {
                            attrs: {
                              id: `invoice-item-header-supplier-name-tooltip-${index}`,
                              dark: "",
                              bottom: "",
                              text: _vm.getStoreGroupsCode(invoice.storeGroups),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col integration-status",
                    attrs: {
                      id: `invoice-item-header-integration-status-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "Invoices-header-row ellipsis",
                        attrs: {
                          id: `invoice-item-header-integration-status-text-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getAccountingStatus(invoice.accountingStatus)
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col date",
                    attrs: { id: `invoice-item-header-date-${index}` },
                  },
                  [_vm._v(_vm._s(_vm._f("dateFormat")(invoice.invoiceDate)))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col number",
                    attrs: { id: `invoice-item-header-number-${index}` },
                  },
                  [_vm._v(_vm._s(invoice.invoiceNumber))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col net ff-mono u-textRight",
                    attrs: { id: `invoice-item-header-net-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency3Digits")(invoice.netTotalAmount)
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col tax ff-mono u-textRight",
                    attrs: { id: `invoice-item-header-tax-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency3Digits")(invoice.taxTotalAmount)
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "Invoices-header-col gross ff-mono u-textRight",
                    attrs: { id: `invoice-item-header-gross-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency3Digits")(
                          invoice.grossTotalAmount
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "Invoices-header-col roundingTolerance ff-mono u-textRight",
                    attrs: {
                      id: `invoice-item-header-rounding-tolerance-${index}`,
                    },
                  },
                  [_vm._v(_vm._s(invoice.roundingTolerance))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Invoices-header-col isEInvoice",
                    attrs: { id: `invoice-item-header-isEInvoice-${index}` },
                  },
                  [_vm._v(_vm._s(_vm.isElectronicInvoice(invoice.isEInvoice)))]
                ),
                _c(
                  "div",
                  { staticClass: "Invoices-header-col description" },
                  [
                    invoice.description
                      ? _c(
                          "Button",
                          {
                            staticClass:
                              "show-MiniPopup relative width-max-content",
                            attrs: {
                              size: "small",
                              variant: "info",
                              id: `invoice-header-info-button-${invoice.index}`,
                              iconName: "icon-global-info",
                              justIcon: true,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "MiniPopup-right-primary m-top-15 c-dark",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(invoice.description)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("ActionButton", {
                  attrs: {
                    id: "despatches-invoice-action-button",
                    data:
                      invoice.accountingStatus === 3 ||
                      (invoice.accountingStatus === 0 &&
                        !invoice.integrationProductId)
                        ? ["export", "separateInvoice"]
                        : ["export"],
                    item: invoice,
                  },
                  on: {
                    onItemExport: function ($event) {
                      return _vm.showExportPopup(invoice.id)
                    },
                    onItemSeparateInvoice: function ($event) {
                      return _vm.invoicePartition(invoice)
                    },
                  },
                }),
              ],
              1
            ),
            invoice.showDetail
              ? _c("InvoiceDetail", {
                  attrs: {
                    id: `invoice-item-details-${index}`,
                    tableData: invoice.invoiceDetailList,
                    headerIndex: index,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.selectedInvoice.id
        ? _c("Alert", {
            attrs: {
              title: _vm.$t("Despatches.confirm_Inovice_Partition"),
              description: _vm.$t(
                "Despatches.confirm_Inovice_Partition_Message",
                { invoiceNumber: _vm.selectedInvoice.invoiceNumber }
              ),
              okButton: _vm.$t("Despatches.confirm_Inovice_Partition_OK"),
              icon: "icon-global-notify",
              loading: "alertForm",
            },
            on: {
              delegateOnAlertOk: _vm.invoiceDecompose,
              delegateOnAlertCancel: _vm.closePopup,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }