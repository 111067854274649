var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-overlay" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("Global.title_UserIsNotActive")))]),
      ]),
      _c("div", { staticClass: "modal-body" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("Global.description_UserIsNotActive")))]),
      ]),
      _c("div", { staticClass: "modal-footer" }, [
        _c("button", { on: { click: _vm.logout } }, [
          _vm._v(_vm._s(_vm.$t("Global.logout"))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }