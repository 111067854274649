var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.refrigeratorExtension.isActive
        ? _c("EmptyState", {
            staticClass: "emptyState",
            attrs: {
              iconName: "empty-state-alarm-big",
              title: _vm.$t("Dashboard.refrigerator_isNotActive"),
              description: _vm.$t("Dashboard.refrigerator_ActivationInfo"),
            },
          })
        : _c(
            "div",
            [
              _vm.isWaitingRefrigeratorList || !_vm.selectedDevice
                ? _c("TableLoading")
                : _c("div", { staticClass: "refrigerator" }, [
                    _c("div", { staticClass: "refrigerator-left" }, [
                      _c("div", { staticClass: "refrigerator-left-top" }, [
                        _c("div", { staticClass: "col-left" }, [
                          _c("div", { staticClass: "full-width" }, [
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-box-top-left" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Dashboard.refrigerator_roomTemperature"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "card-box-top-right" },
                                  [
                                    _c("CustomSelect", {
                                      attrs: {
                                        value:
                                          _vm.selectedDevice
                                            ?.shelfStorageLocationListHeaderId,
                                        selectedEmitValue:
                                          "shelfStorageLocationListHeaderId",
                                        optionTitle:
                                          "shelfStorageLocationListHeaderName",
                                        optionData: _vm.refrigeratorList,
                                        isDefaultTextActive: false,
                                        isSearchActive: true,
                                      },
                                      on: {
                                        inputSelected: _vm.getRefrigeratorInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "card-box-center" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-box-center-left" },
                                  [
                                    _c("p", { staticClass: "current-degree" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.refrigeratorDataList[0]?.value
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "card-box-center-right",
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-box-bottom-left" },
                                  [
                                    _c("div", { staticClass: "celcius" }, [
                                      _vm._v("°C"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "card-box-bottom-right" },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Dashboard.refrigerator_online"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-right" }, [
                          _c("div", { staticClass: "full-width" }, [
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "card-box-center" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-box-center-left" },
                                  [
                                    _c("Icon", {
                                      staticClass: "degree-info",
                                      attrs: { name: "thermometer-hot" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-box-center-right m-left-10",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "card-box-center-right-text",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Dashboard.refrigerator_cabinetTemperature"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "card-box-center-right-text-secondary",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.refrigeratorDataList[0]?.value
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "full-width" }, [
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "card-box-center" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-box-center-left" },
                                  [
                                    _c("Icon", {
                                      staticClass: "degree-info",
                                      attrs: { name: "thermometer-hot" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-box-center-right m-left-10",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "card-box-center-right-text",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Dashboard.refrigerator_evaporatorTemperature"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "card-box-center-right-text-secondary",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.refrigeratorDataList[1]?.value
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "refrigerator-left-bottom" }, [
                        _c(
                          "div",
                          { staticClass: "refrigerator-left-bottom-title" },
                          [
                            _c("div", { staticClass: "device-data" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Dashboard.refrigerator_deviceData")
                                )
                              ),
                            ]),
                            _c("div", { staticClass: "device-name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedDevice
                                    ?.shelfStorageLocationListHeaderName
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "refrigerator-left-bottom-content" },
                          [
                            !_vm.refrigeratorDataList?.length
                              ? _c("EmptyState", {
                                  staticClass: "emptyState-device-data",
                                  attrs: {
                                    iconName: "empty-state-alarm-big",
                                    title: _vm.$t(
                                      "Dashboard.refrigerator_emptyState_DeviceData"
                                    ),
                                    description: _vm.$t(
                                      "Dashboard.refrigerator_emptyState_DeviceData_EmptyDescription"
                                    ),
                                  },
                                })
                              : _c("RefrigeratorTable", {
                                  attrs: {
                                    currentData: _vm.refrigeratorDataList,
                                    historyData:
                                      _vm.refrigeratorHistoryDataList,
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "refrigerator-right" }, [
                      _c(
                        "div",
                        { staticClass: "alarm-title" },
                        [
                          _c("div", { staticClass: "alarm-title-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Dashboard.refrigerator_alarmStatus")
                              )
                            ),
                          ]),
                          _c("Icon", {
                            staticClass: "alarm-bell alarm-icon",
                            attrs: { name: "alarm-bell" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "alarm-status-current m-top-15" },
                        [
                          _c(
                            "span",
                            { staticClass: "alarm-status-current-text" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Dashboard.refrigerator_current"))
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Dashboard.refrigerator_dateAndTime"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Dashboard.refrigerator_status")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  !_vm.refrigeratorAlarmDataList?.length
                                    ? _c("EmptyState", {
                                        staticClass: "emptyState-alarm",
                                        attrs: {
                                          iconName: "empty-state-alarm",
                                          description: _vm.$t(
                                            "Dashboard.refrigerator_Alarm_EmptyDescription"
                                          ),
                                        },
                                      })
                                    : _vm._l(
                                        _vm.refrigeratorAlarmDataList,
                                        function (log) {
                                          return _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "date-time" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "date" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(log.time)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "time" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatTime(log.time)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "status" },
                                              [_vm._v(_vm._s(log.alarm))]
                                            ),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "alarm-status-history m-top-15" },
                        [
                          _c(
                            "span",
                            { staticClass: "alarm-status-history-text" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Dashboard.refrigerator_history"))
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Dashboard.refrigerator_dateAndTime"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Dashboard.refrigerator_status")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  !_vm.refrigeratorHistoryAlarmDataList?.length
                                    ? _c("EmptyState", {
                                        staticClass: "emptyState-alarm",
                                        attrs: {
                                          iconName: "empty-state-alarm",
                                          description: _vm.$t(
                                            "Dashboard.refrigerator_Alarm_History_EmptyDescription"
                                          ),
                                        },
                                      })
                                    : _vm._l(
                                        _vm.refrigeratorHistoryAlarmDataList,
                                        function (log) {
                                          return _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "date-time" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "date" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(log.time)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "time" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatTime(log.time)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "status" },
                                              [_vm._v(_vm._s(log.alarm))]
                                            ),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }