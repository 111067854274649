<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )

  template(slot="header-left-search")
    Search.search-box(
      componentName="onhand",
      suggestionValue="name"
      :showSuggestionType="true"
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="suggestions"
      :customSuggestionPlaceHolder="$t('OnHand.search_Placeholder')"
      :customSuggestionTitle="$t('OnHand.search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="onChangeQueryItem"
      @clearSearch="clearSearch"
    )
  template(slot="toolbar-left")
    applyFilter(
      :filterCheckList="filterCheckList",
      :filterToggleList="filterToggleList",
      @filterSubmit="getOnHandWithFilters"
      @onChangeAnyList="onChangeOverGroupList"
    )

    .show-more.m-left-15
      .Form-item.dropdown-handle
        .select.dropdown-handle-button(
          @keydown.enter="toggleOtherOptions"
          @keydown.prevent.space="toggleOtherOptions"
          @click="toggleOtherOptions"
          v-click-outside="closeOtherOptions"
          :aria-expanded="showOtherOptions"
          tabindex="0"
        )
          .dropdown-handle-button-values {{ $t('Global.moreOptions') }}
          Icon(:class="{'open': showOtherOptions}" name="icon-down-arrow").dropdown-handle-button-icon.icon.icon-down-arrow
          
  
      .MiniPopup.left(
        v-if="showOtherOptions"
        v-shortkey="['esc']"
      )
        CustomCheckbox.checkbox-show-exp-date(
          id="checkbox-show-exp-date"
          :label="$t('OnHand.ShowExpDateControlOnly')"
          :checked="showWithExpDate"
          @change="changeExpDateStatusForData"
        )

        CustomCheckbox.show-passive-recipes(
          id="checkbox-on-hand-show-with-base-currency"
          :checked="showWithBaseCurrency"
          :label="$t('OnHand.showWithBaseCurrency')"
          @change="showBaseCurrency")
  template(slot="toolbar-right" v-if="!isEmpty" )

    .Result-top-header-right__item(v-if="isSingleStock")
      span.title {{ $t('OnHand.result_Header_Quantity') }}
      span.value.ff-mono
        | {{ totalQuantity }} {{ singleStockItemBaseUnitCode }}
    .Result-top-header-right__separator(v-if="isSingleStock")
    .Result-top-header-right__item
      span.title {{ $t('OnHand.result_Header_total') }}
      span.value.ff-mono {{ totalAmount | formatCurrency3Digits }}

    ImportExport(
      componentName="onhand"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      :downloadParams="downloadParams()"
      downloadUrl="StockOnHand/stockonhand/export"
      downloadFileName="stockonhand-report"
      downloadFileType="xlsx"
    ) 
  template(slot="content-center")

    TableLoading(v-if="isPageLoading")
    TableView(
      v-else
      uniqueIdentifier="id"
      :componentName="'OnHandTable'"
      :fields="fields"
      :data="customStocks"
      :readOnly="true"
      :page="Page"
      :empty-state-title="$t('OnHand.emptyPage_Title')"
      :empty-state-desc="$t('OnHand.emptyPage_Description')"
      @sort="sort"
      @submitFilter="submitFilter"
      @clearFilter="clearFilter"
      @changePopupFilterSearch="changePopupFilterSearch"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )

    portal(to="stockItemName")
      div.stockItemName(slot-scope="row" @click="openModal(row)")
        span.tooltip-relative.show-MiniPopup {{ getName(row.stockItemName) }}
          Tooltip(
            v-if="row.stockItemName.length > 30"
            white
            right
            :text="row.stockItemName"
          )
        Icon.showExp-btn(
          v-if="row.expirationDateControlIsEnabled"
          name="icon-login-eye"
        )
    portal(to="stockItemMinimumQuantity")
      div(slot-scope="row")
        customNumberInput(
          :name="`stockItemMinimumQuantity-${row.rowIndex}-${row?.id}`"
          id="input-batch-quantity"
          v-validate="{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
          :error="veeErrors.has(`quantity-${row.rowIndex}-${row?.id}`)"
          v-model="row.stockItemMinimumQuantity"
          :extraDataEmit="row"
          @blur="onChangeInputMinimumQuantity"
        )
    portal(to="stockItemOptimumQuantity")
      div(slot-scope="row")
        customNumberInput(
          :name="`stockItemOptimumQuantity-${row.rowIndex}-${row?.id}`"
          id="input-batch-quantity"
          v-validate="{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
          :error="veeErrors.has(`quantity-${row.rowIndex}-${row?.id}`)"
          v-model="row.stockItemOptimumQuantity"
          :extraDataEmit="row"
          @blur="onChangeInputOptimumQuantity"
        )


    portal(to="orderByMinimumStock")
      div(slot-scope="row")
        span {{ row.stockItemMinimumQuantity > 0 && row.stockItemMinimumQuantity - row.stockItemUnitQuantity > -1 ? Number(Math.abs(row.stockItemMinimumQuantity - row.stockItemUnitQuantity).toFixed(3)) : "-" }}

    portal(to="orderByOptimumStock")
      div(slot-scope="row")
        span {{ row.stockItemOptimumQuantity > 0 && row.stockItemOptimumQuantity - row.stockItemUnitQuantity > -1 ? Number(Math.abs(row.stockItemOptimumQuantity - row.stockItemUnitQuantity).toFixed(3)) : "-" }}



    portal(to="requestType")
      div.requestType(slot-scope="row")
        customSelectInput.custom-select(
          name="requestType",
          :optionData="requestTypes"
          v-model="row.requestType",
          optionKey="requestType"
          optionIdKey="requestType"
          :selectedDataRequired="true"
          :isValueNumber="true"
          :extraDataEmit="row"
          @change="onInputSelected"
          :hideDefault="false"
          optionIdName="stockOnHand-request-type"
        )
    portal(to="fromStoreId")
      div(slot-scope="row")
        CustomSelect.custom-select(
          name="selectStore",
          v-model="row.fromStoreId"
          :isDisabled="calculateStats(row)"
          :optionData="allStoreList"
          optionTitle="name"
          selectedEmitValue="id"
          :isSearchActive="true"
          :extraDataEmit="row"
          :defaultTitle="$t('OnHand.fromStore_selection')"
          :selectedDataRequired="true"
          :hideDefault="true"
          @inputSelected="onInputSelectedStore($event, row)"
        )
    portal(to="isUsed")
      div.tooltip-relative(slot-scope="row")
        CustomMultiselect.m-left-5(
          id="onHand-add-shelf"
          :items="ShelfStorageList"
          :selected="selectedShelfList"
          :useCustomEmptyState="true"
          :customEmptyState="row.isUsed ? $t('OnHand.shelfList_selected') : $t('OnHand.shelfList_unselected')"
          :popupHeader="$t('OnHand.shelfStorageList_selection')"
          :unselectedText="$t('OnHand.shelfStorageList_selection')"
          :buttonText="$t('Global.apply')"
          buttonIconLeft="btn-success"
          :showAll="false"
          :isDisabled="!checkPermission('ShelfStorageLocationEdit')"
          :useCustomLocation="(stocks.length > 5 && (stocks.length < row.rowIndex + 5)) ? true : false"
          :isLoadingData="fetchingShelfListData"
          :dontDisableButton="ShelfStorageList.length ? true : false"
          @onClick="getShelfData(row)"
          @delegateCheckItem="onChangeSelectShelfList"
          @delegateSubmitItems="submitShelfList(row)"
        )
        Tooltip.small(
          v-if="!checkPermission('ShelfStorageLocationEdit')"
          white,
          right,
          :text="$t('OnHand.noPermissionForShelfListEdit')")  
    portal(to="stockItemSafeStockQuantity")
      div(slot-scope="row")
        customNumberInput(
          :name="`stockItemSafeStockQuantity-${row.rowIndex}-${row?.id}`"
          id="input-batch-quantity"
          v-validate="{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
          :error="veeErrors.has(`quantity-${row.rowIndex}-${row?.id}`)"
          v-model="row.stockItemSafeStockQuantity"
          :extraDataEmit="row"
          @blur="onChangeSafeQuantity"
        )
    Modal(:isOpen="showExpDateDataModal" @closeModal="closeModal")
      template(slot="title")
        span {{stockItemName}}
      template(slot="content")
        EmptyState.batch-empty-state(
          v-if="!stockItemsWithExpDate.length"
          :title="$t('OnHand.emptyPage_BatchTitle')"
          :description="$t('OnHand.emptyPage_BatchDescription')")
        TableView(
          v-else
          :data="stockItemsWithExpDate"
          :fields="batchListFields"
          :readOnly="true"
        )
        portal(to="daysLeft")
          div(slot-scope="row")
            .days(:class="getClassForDaysLeft(row.daysLeft)") {{row.daysLeft}}
      

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TableFoot from '@/view/global/table/foot'
import getFields from './fields/index'
import getBatchFields from './fields/batch-fields'
import OverGroupFilterComp from '@/view/global/business-filters/groups.vue'
import ToggleType from '@/view/global/toggle-over-groups'
import storeFilterComponent from '@/view/global/business-filters/store.vue'
import applyFilter from '@/view/global/applyFilter/index'
import popupFilter from '@/view/global/popup-filter'
import toggleSorting from '@/view/global/toggle-sorting'
import tableDetail from './components/table-detail'
import ImportExport from '@/view/global/import-export'
import CustomSelect from '@/view/global/custom-select'

import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'OnHand',

  components: {
    TableFoot,
    OverGroupFilterComp,
    ToggleType,
    storeFilterComponent,
    applyFilter,
    popupFilter,
    toggleSorting,
    tableDetail,
    ImportExport,
    CustomSelect
  },

  data () {
    return {
      fields: getFields(this.$t, this.$getCounts),
      batchListFields: getBatchFields(this.$t, false),
      search: '',
      form: {
        orderByOptimumStock: null,
        orderByMinimumStock: null,
      },
      fromStoreId: {},
      queryItem: null,
      stockItemList: [],
      selectedStockItems: [],
      selectedShelfList: [],
      overGroupFilter: [],
      selectedItemGroups: [],
      itemGroupList: [],
      storeFilter: [],
      customStocks: [],
      fromStoreArray: [],
      storeItemType: -1,
      selectedStockType: '',
      sortExpressionList: [],
      customId: 0,
      showWithExpDate: false,
      stockItemName: '',
      showExpDateDataModal: false,
      typeList: [
        {
          text: this.$t('Stocks.Item_Type_All'),
          value: null
        },
        {
          text: this.$t('Stocks.Item_Type_Stock'),
          value: 1
        },
        {
          text: this.$t('Stocks.Item_Type_Production'),
          value: 2
        }
      ],
      storeTypeList: [
        {
          text: this.$t('OnHand.StoreItemFilterType_All'),
          value: -1
        },
        {
          text: this.$t('OnHand.StoreItemFilterType_Min'),
          value: 0
        },
        {
          text: this.$t('OnHand.StoreItemFilterType_Opt'),
          value: 1
        }
      ],
      requestTypes: [
        {
          name: this.$t('OnHand.requestType_order'),
          requestType: 1
        },
        {
          name: this.$t('OnHand.requestType_transfer'),
          requestType: 2
        }
      ],
      showOtherOptions: false,
      showWithBaseCurrency: false
    }
  },

  async beforeMount () {
    // wathcing readyForFetch and fetching list
    this.$wait.start('pageLoading')
    await this.getPageData().then(() => {
      this.$wait.end('pageLoading')
    })
    this.$wait.end('pageLoading')
  },

  computed: {
    hasOpenendTableFilter () {
      return this.filteredFields.some(ff => ff.filter.showFilter)
    },
    filteredFields () {
      return this.fields.filter(f => !!f.filter)
    },

    ...mapGetters('OnHand', [
      'suggestions',
      'suggestionsIsEmpty',
      'totalAmount',
      'totalQuantity',
      'stocks',
      'Page',
      'pageHeaderLinks',
      'stockItemsWithExpDate',
      'ShelfStorageList',
    ]),

    ...mapGetters('Settings', [
      'checkPermission'
    ]),

    ...mapGetters('Group', {
      overGroupList: 'allOverGroups',
      allItemGroups: 'allItemGroups'
    }),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList',
      allStoreList: 'storeList'
    }),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isSuggestionLoading () {
      return this.$wait.is('search_Suggestion')
    },

    isFetchingData () {
      return this.$wait.is('get_STOCKS_ONHAND')
    },

    isSingleStock () {
      return this.queryItem !== null && this.queryItem.type === 3
    },

    singleStockItemBaseUnitCode () {
      return this.stocks.length > 0 ? this.stocks[0].stockItemBaseUnitCode : null
    },

    isStocksLoading () {
      return this.$wait.is(['get_STOCKS_ONHAND'])
    },

    isEmpty () {
      return !this.isStocksLoading && this.customStocks.length === 0
    },

    filterCheckList () {
      return [
        {
          id: 'over-group-list',
          name: this.$t('Global.toolbar_Over_Group_Label'),
          list: this.overGroupList,
          type: 'overGroupList',
          required: true
        },
        {
          id: 'store-list',
          name: this.$t('Global.toolbar_Store_Label'),
          list: this.storeList,
          type: 'storeList',
          required: true
        }
      ]
    },
    filterToggleList () {
      return [
        {
          id: 'store-items-toggle-filter',
          name: this.$t('OnHand.filter_type'),
          list: this.storeTypeList,
          type: 'StoreItemFilterType'
        },
        {
          id: 'stock-items-toggle-filter',
          name: this.$t('OnHand.filter_stock_type'),
          list: this.typeList,
          type: 'stockItemType'
        }
      ]
    },
    fetchingShelfListData() {
      return this.$wait.is('isFetchingShelfLocationData')
    }
  },

  methods: {

    ...mapMutations('OnHand', [
      'remove_SUGGESTIONS',
      'remove_STOCKS',
      
    ]),

    ...mapActions('OnHand', {
      setStoreItem: 'set_STORE_ITEM',
      getShelfStorageLocationList: 'getShelfLocationList',
      updateOnHandShelfStorageList: 'updateOnHandShelfStorageList'
    }),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE']),

    ...mapActions({
      search_Suggestion: 'OnHand/search_Suggestion',
      get_STOCKS_ONHAND: 'OnHand/get_STOCKS_ONHAND',
      setStoreItem: 'OnHand/set_STORE_ITEM',
      getStockItemsWithExpDate: 'OnHand/getStockItemsWithExpDate',
      getStores: 'Stores/getMiniStoreList'
    }),

    

    ...mapActions('Group', [
      'getGroupOver',
      'getItemGroupByOverGroupId',
      'getItemGroupAll'
    ]),
    ...mapActions('Stores', [
      'getMiniStoreList',
      'getStoreList'
    ]),

    getItemFieldShortName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    findSortableItem (name) {
      return this.sortExpressionList.find(item => item.columnName === name)
    },
    calculateOrder (name) {
      return this.sortExpressionList.findIndex(item => item.columnName === name) + 1
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getOnHand()
    },

    changePopupFilterSearch ({search, field}) {
      if (field.filter.backendSearch) {
        switch (field.filter.backendSearch) {
          case 'stockItem':
            if (search.length > 1) {
              this.searchStockItem({Text: search, types: [1, 2, 3]})
                .then(res => {
                  field.filter.list = res.data.items
                })
            } else {
              field.filter.list = []
            }
            break
        }
      }
    },

    async getItemGroups () {
      const itemGroupFilterList = this.fields.find(i => i.name === 'stockItemGroupName')
      await this.getItemGroupAll()
      itemGroupFilterList.filter.list = [...this.allItemGroups]
      itemGroupFilterList.filter.selectedList = [...this.allItemGroups]
      this.selectedItemGroups = [...this.itemGroupList]
    },

    clearFilter (field) {
      this.stockItemList = []
      this.selectedStockItems = []
      this.selectedItemGroups = []
      this[field.filter.selectedListName] = field.filter.selectedList
      this.getOnHand()
    },
    getArray (arrayName) {
      return this[arrayName]
    },
    toggleAll (isSelectedAll, field) {
      if (isSelectedAll) {
        this[field.filter.selectedList] = []
      } else {
        this[field.filter.list].forEach(item => this.onChangePopupFilterCheckbox({item, isChecked: true, field}))
      }
    },
    onChangePopupFilterCheckbox ({ item, isChecked, field }) {
      if (isChecked) {
        this[field.filter.selectedList].push(item)
      } else {
        this[field.filter.selectedList].splice(this[field.filter.selectedList].findIndex(i => i.id === item.id), 1)
      }
    },
    closeTableFilter () {
      this.filteredFields.map(ff => { ff.filter.showFilter = false })
    },
    async submitFilter (field) {
      this[field.filter.selectedListName] = field.filter.selectedList
      await this.getOnHand()
    },
    
    calculatePopupPosition ({field, targetRef, parentRef = 'myTable'}) {
      const isArray = !!this.$refs[targetRef].length
      field.filter.showFilter = true
      const target = isArray ? this.$refs[targetRef][0].getBoundingClientRect() : this.$refs[targetRef].getBoundingClientRect() // clicked element
      const parent = this.$refs[parentRef].getBoundingClientRect() // that relative element
      const filterWidth = 281

      field.filter.position = {
        top: (target.top - parent.top + target.height) + 'px',
        left: ((target.left - parent.left) - (filterWidth / 2) + (target.width / 2)) + 'px'
      }
    },

    async onChangeOverGroupList (filterName, selectedList) {
      if (filterName !== 'overGroupList') return
      const overGroupIds = selectedList.map(item => item.id)
      this.getItemGroupByOverGroupId(overGroupIds)
        .then(res => {
          this.itemGroupList = res.data.itemGroupList
          this.selectedItemGroups = [...this.itemGroupList]
        })
    },

    async getPageData () {
      await this.getGroupOver()
      await this.getMiniStoreList()
      await this.getItemGroups()
      await this.getStoreList({pageNumber: 1, pageSize: 9999, ignorePermission: true})
      this.storeItemType = await this.storeTypeList[0].value
      this.selectedStockType = this.typeList[0].value
      this.storeFilter = await [...this.storeList]
      this.overGroupFilter = await [...this.overGroupList]
      await this.getOnHand()
    },
    getClassForDaysLeft (remainingDay) {
      if (remainingDay < 31) return remainingDay < 1 ? 'days-expired' : 'days-close'
    },
    downloadParams () {
      const item = this.queryItem
      let singularSearch = {
        overGroupIds: null,
        majorGroupIds: null,
        itemGroupIds: null,
        stockItemIds: null,
        sortExpressionList: null
      }

      if (item) {
        switch (item.type) {
          case 0:
            singularSearch.overGroupIds = [item.id]
            break
          case 1:
            singularSearch.majorGroupIds = [item.id]
            break
          case 2:
            singularSearch.itemGroupIds = [item.id]
            break
          case 3:
            singularSearch.stockItemIds = [item.id]
            break
        }
      }
      const params = {
        storeItemFilterType: this.storeItemType,
        overGroupIds: this.overGroupFilter ? this.overGroupFilter.map(item => item.id) : null,
        itemGroupIds: this.selectedItemGroups ? this.selectedItemGroups.map(item => item.id) : null,
        stockItemType: this.selectedStockType,
        storeIds: this.storeFilter ? this.storeFilter.map(store => store.id) : null,
        stockItemIds: this.selectedStockItems.map(stockItem => stockItem.id),
        showExpirationDateControlIsEnabledOnly: this.showWithExpDate,
        item: this.queryItem,
        sortExpressionList: this.sortExpressionList
      }
      return item ? {params, ...singularSearch} : params
    },

    clearList () {
      this.remove_STOCKS()
    },

    clearSearch () {
      this.search = ''
      this.queryItem = null
      this.remove_STOCKS()
      this.getOnHand()
    },

    onChangeQueryItem (item) {
      this.queryItem = item
      this.search = item.name
      this.getOnHand()
    },

    async changePage (page) {
      await this.getOnHand(page)
    },

    changePageSize (pageSize) {
      this.getOnHand(1, pageSize)
    },

    changeExpDateStatusForData () {
      this.showWithExpDate = !this.showWithExpDate
      this.getOnHand()
    },

    async getOnHandWithFilters (filters) {
      this.storeFilter = filters.storeList
      this.overGroupFilter = filters.overGroupList
      this.selectedStockType = filters.stockItemType
      this.storeItemType = filters.StoreItemFilterType !== null ? filters.StoreItemFilterType : -1
      await this.getOnHand()
    },
    async getOnHand (page, pageSize = this.Page.size) {
      this.closeTableFilter()
      this.remove_SUGGESTIONS()
      await this.get_STOCKS_ONHAND({
        item: this.queryItem,
        storeItemFilterType: this.storeItemType,
        storeIds: this.storeFilter ? this.storeFilter.map(store => store.id) : null,
        page: page,
        pageSize,
        stockItemType: this.selectedStockType,
        showExpirationDateControlIsEnabledOnly: this.showWithExpDate,
        overGroupIds: this.overGroupFilter ? this.overGroupFilter.map(item => item.id) : null,
        stockItemIds: this.selectedStockItems.map(stockItem => stockItem.id),
        itemGroupIds: this.selectedItemGroups ? this.selectedItemGroups.map(item => item.id) : null,
        sortExpressionList: this.sortExpressionList,
        showWithBaseCurrency: this.showWithBaseCurrency
      })
      this.customStocks = JSON.parse(JSON.stringify(this.stocks))
      // Creating customKey for each coming item for re-rendering component inside tableDetail
      this.customStocks.forEach((item) => {
        item.customId = this.customId + 1
        this.customId++
      })
      this.storeIdCalculate(this.customStocks)
    },
    onChangeSearch (search) {
      this.search = search
      if (this.search && this.search.length >= 2) {
        this.search_Suggestion(this.search)
      }
      if (!this.search) {
        this.clearSearch()
      }
    },
    closeModal () {
      this.showExpDateDataModal = false
    },
    openModal (item) {
      if (!item.expirationDateControlIsEnabled) return
      this.stockItemName = item.stockItemName
      const payload = {
        storeIds: [item.storeId],
        stockItemIds: [item.stockItemId]
      }
      this.getStockItemsWithExpDate(payload)
      this.showExpDateDataModal = true
      this.TOGGLE_MENU_MINIMALIZE(true)
    },

    onChangeSelectShelfList (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedShelfList = [...this.ShelfStorageList]
        } else {
          this.selectedShelfList.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedShelfList = []
        } else {
          this.selectedShelfList.splice(this.selectedShelfList.indexOf(event.item), 1)
        }
      }
    },

    onInputSelected (item) {
      if (item.selectedItem === null || typeof item.selectedItem === 'undefined') {
        item.extraDataEmit.fromStoreId = null
        item.extraDataEmit.requestType = 0
        this.fromStoreArray= this.fromStoreArray.filter(data => data.customId !== item.extraDataEmit.customId);
        this.setOnHandStockItems(item.extraDataEmit)
        // It's working correctly for the Request Type; it brings it to the "not selected" position.
        return
      }
      if (item.selectedItem.requestType) item.extraDataEmit.requestType = 0
      if (item.selectedItem.id) item.extraDataEmit.id = item.selectedItem.id
      if (item.selectedItem.requestType === 2) {
        // Here, we are performing a transfer for the request type and pushing it to a local variable
        // because we need to manage the disabled state.
        item.extraDataEmit.requestType = 2
        this.fromStoreArray.push(item.extraDataEmit)
      } else if (item.selectedItem.requestType === 1) {
        //We are placing an order for the request type, and it's working correctly.
        item.extraDataEmit.fromStoreId = null
        item.extraDataEmit.requestType = 1
        this.fromStoreArray = this.fromStoreArray.filter(data => data.customId !== item.extraDataEmit.customId);
        this.setOnHandStockItems(item.extraDataEmit)
      }
    },

    calculateStats (rowData) {
    // We are checking the disabled state, and if it's selected, we return the disabled state.
    // We use CustomId for control because it's the sequential ID for all data; others can get mixed up.
      let status = this.fromStoreArray.some(item => item.customId === rowData.customId)
      return !status
    },

    storeIdCalculate(allData) {
    // The naming may not be correct
    // We are sending the requestType value from the data to the local variable so that we can control the disabled state for those with a "transfer" request type.
      let filteredData = allData.filter(item => item.requestType === 2)
      this.fromStoreArray = [...filteredData]
    },

  onInputSelectedStore: vueWaitLoader(async function(value, item){
      // We send the selected fromStoreId and refresh the data.
      item.fromStoreId = value.id
      item.requestType = 2
      this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.setOnHandStockItems(item)
        this.getOnHand(this.Page.number)
      })
    },'isOnInputSelectedStore'),

    onChangeSafeQuantity: vueWaitLoader(async function(item) {
      // SafeStockQuantity update function
      item.extraDataEmit.stockItemSafeStockQuantity = item.inputData
      this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.setOnHandStockItems(item.extraDataEmit)
        this.getOnHand(this.Page.number)
      })
    },'isOnChangeSafeQuantity'),

    onChangeInputMinimumQuantity: vueWaitLoader(async function(item) {
      // Inputminumum quantitiy update function
      item.extraDataEmit.stockItemMinimumQuantity = item.inputData
      this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.setOnHandStockItems(item.extraDataEmit)
        this.getOnHand(this.Page.number)
      })
    },'isOnChangeInputMinimumQuantity'),

    onChangeInputOptimumQuantity: vueWaitLoader(async function(item) {
      item.extraDataEmit.stockItemOptimumQuantity = item.inputData
      this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.setOnHandStockItems(item.extraDataEmit)
        this.getOnHand(this.Page.number)
      })
    },'isOnChangeInputOptimumQuantity'), 
    

    setOnHandStockItems (item) {
      const payload = {
        storeId: item.storeId,
        stockItemId: item.stockItemId,
        optimumQuantity: item.stockItemOptimumQuantity || null,
        minimumQuantity: item.stockItemMinimumQuantity || null,
        fromStoreId: item.fromStoreId,
        safeStockQuantity: item.stockItemSafeStockQuantity || null
      }
      if (item.requestType) payload.requestType = item.requestType
      this.setStoreItem(payload)
      this.getOnHand(this.Page.number)
    },

    getShelfData: vueWaitLoader(async function(stockItem) {
      const payload = {
        storeId: stockItem.storeId,
        stockItemId: stockItem.stockItemId,
        unitId: stockItem.stockItemUnitId
      }
      await this.getShelfStorageLocationList(payload).then(res => {
        if (res) {
          this.ShelfStorageList.map(i => {
            if (i.isUsed) this.selectedShelfList.push(i)
          })
        }
      })
    },'isFetchingShelfLocationData'),
    async submitShelfList(item) {
      const payload = {
        shelfStorageLocationListHeaderIds: this.selectedShelfList.map(i => i.id),
        storeId: item.storeId,
        stockItemId: item.stockItemId,
        unitId: item.stockItemUnitId
      }
      await this.updateOnHandShelfStorageList(payload).then(res => {
        const message = this.$t('OnHand.shelfUpdated_successfully')
        if (res) {
          this.notifyShow({ message })
          this.getOnHand(this.Page.number)
        }
      })
    },
    getName (name) {
      return (name && name.length > 30 ) ? name.substr(0, 30).concat('...') : name
    },


    toggleOtherOptions () {
      this.showOtherOptions = !this.showOtherOptions
    },
    closeOtherOptions () {
      this.showOtherOptions = false
    },
    showBaseCurrency () {
      this.showWithBaseCurrency = !this.showWithBaseCurrency
      this.getOnHand()
    },

  }
}
</script>

<style lang="scss" scoped>

  .Result {
    &-top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &-title {

        svg {
          margin-right: 5px;
          color: $color-primary;
        }
      }
      &-left {
        display: flex;
        align-items: center;
      }
      &-right {
        display: flex;
        align-items: center;

        &__item {

          &:not(:last-child) {
            margin-right: 10px;
          }

          .title {
            font-size: $font-size-small;
            color: $color-success;
            margin-right: 20px;
            font-weight: $font-weight-bold;
          }

          .value {
            font-weight: $font-weight-bold;
          }
        }

        &__separator {
          display: flex;
          align-items: center;
          width: 1px;
          height: 35px;
          margin-right: 10px;

          &::after {
            content: "";
            width: 100%;
            height: 30px;
            background-color: $color-gray;
          }
        }
      }
    }
  }

  .showExpiration {
    display: flex;
    align-items: center;
    padding-right: 30px;
  }

  .Tablee-body .Tableee-row .Tableee-col{
    cursor: auto !important;
    .icon-login-eye {
      color: #B6B6B9;
      margin-left: 8px;
      margin-top: 1px;
    }
  }

  .showExp-btn {
    color: #B6B6B9;
    position: absolute;
    right: 0;
    margin-right: 10px;
  }

  .toolbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  .u-textRight {
    display: inline-block;
    width: 100%;
  }

  .Tableee-col .txt {
    width: 100px;
    margin: auto;
  }

  .icon-global-settings {
    width: 18px;
    height: 18px;
    color: darken($color: $color-gray, $amount: 20);
    margin-left: 5px;
    margin-bottom: -4px;
    cursor: pointer;
  }
  .sortable-title {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .Tableee-scroll {
    .Tableee-main {
      overflow-x: auto;
      overflow-y: hidden;
      min-height: 180vh !important;
    }
  }

  .is-with-pad {
    padding-bottom: 300px;
  }
  .batch-empty-state {
    align-items: flex-start !important;
  }
  :deep() .Modal-Body {
    width: 80% !important;
    left: 51% !important;
    &-content {
      width: 100% !important;
      overflow-y: hidden !important;
    }
  }

  :deep(.Tableee-col) {
    position: relative !important;
  }

  .stockItemName {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }

  .show-more {
    position: relative;
    .dropdown-handle {
      display: flex;
      align-items: center;
      font-size: $font-size-micro;
      user-select: none;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;

      .disabled-button {
        pointer-events: none;
        opacity: 0.6;
      }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      height: 35px;
      color: inherit;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        color: $color-success;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }
  }
    .MiniPopup {
      z-index: $z-index-lg;
      width: max-content;
      top: 40px;
      border: 1px solid $color-gray;

      .label {
        justify-content: start;
        padding: 10px;
        &:hover{
          background-color: $color-gray;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
  
   .custom-select {
    position: relative;
  }

  .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;

    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      color: $color-success;
      position: relative;
      padding-right: 25px;
      height: 35px;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }

      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

    }
  }

  .days {
    background: #FF5D39;
    padding: 2px 6px;
    border-radius: 2.43507px;
    color: white;
    position: absolute;
    right: 20%;
    top: 30%;
    width: fit-content;
    &-expired {
      background: #FF5D39;
    }
    &-close {
      background: rgba(255, 119, 13, 0.9);
    }
  }
  :deep(.Header) {
    z-index: $z-index-md !important;
  }
  :deep() .Tableee-head .Tableee-col {
    position: sticky !important;
    top: 0 !important;
    &:first-child {
      left: 0 !important;
      z-index: $z-index-sm !important;
    }
    &:nth-child(2) {
      left: 75px !important;
      z-index: $z-index-sm !important;
    }
    &:nth-child(3) {
      left: 150px !important;
      z-index: $z-index-xs !important;
    }
  }

  :deep() .Tableee-body .Tableee-col {
    &:first-child {
      position: sticky !important;
      left: 0 !important;
      z-index: $z-index-xs !important;
    }
    &:nth-child(2) {
      position: sticky !important;
      left: 75px !important;
      z-index: $z-index-xs !important;
      min-width: 150px !important;
    }
  }
  :deep() .Filter-popup {
    z-index: 1500 !important;
    margin-left: 30px !important;
  }

  :deep() .Popover.left .Popover-wrapper{
    z-index: 600 !important
  }

  :deep() .requestType {
    width: 110px !important;
  }


  :deep() .show-MiniPopup {
    z-index: 1500 !important;
  }
</style>
