<template lang="pug">
  NoPermission(v-if="!hasTabPermission")
  div(v-else)
    .SettingsContainer.m-bottom-15
      //- user.type === 3 (admin)

      .UserAgreement(v-if="UserInfo.type === 3")
        a.link(
          @click="openAgreement")
            |  {{ $t('Settings.showUserAgreement') }}
      Modal(:isOpen="isOpenAgreementModal",
      @closeModal="closeAgreementModal")
        template(slot="title")
          | {{ $t('UserAgreement.title') }}
        template(slot="content")
          div.UserAgreement-agreementText(v-html="agreement.agreementText")
      form.Form(@submit.prevent="submit")

        .Form-row

          .Form-col
            .Form-item.firstname.required
              label.Form-item-label {{ $t('Settings.User_FormField_firstname') }}
              .control
                input.txt(
                  disabled
                  name="firstname"
                  autocomplete="off"
                  v-model="form.firstname"
                  type="text"
                  id="input-settings-form-field-firstname")

          .Form-col
            .Form-item.surname.required
              label.Form-item-label {{ $t('Settings.User_FormField_surname') }}
              .control
                input.txt(
                  disabled
                  name="surname"
                  autocomplete="off"
                  v-model="form.surname"
                  type="text"
                  id="input-settings-form-field-surname")

        .Form-row

          .Form-col
            .Form-item.email
              label.Form-item-label {{ $t('Settings.User_FormField_email') }}
              .control
                input.txt(
                name="emailAddress"
                disabled
                v-model="form.emailAddress"
                type="email")

          .Form-col
            .Form-item
              label.Form-item-label
                | {{ $t('Settings.User_FormField_language') }}
              .control.form-item-select
                Icon(name="icon-down-arrow")
                select.select(
                id="select-settings-user-language"
                name="languageId"
                v-model="form.languageId"
                v-validate="'required'"
                :data-vv-as="$t('Settings.User_FormField_language')"
                :class="{ 'is-danger': veeErrors.has('languageId') }")
                  option(
                  v-for="language in clearLanguages"
                  :key="language.id"
                  :value="language.id"
                  :id="`option-settings-user-language${language.id}`")
                    | {{ $t('Languages.' + language.isoCode) }}
        .Form-row
          .Form-col
            .Form-item
              label.Form-item-label {{ $t('Register.phoneNumber') }}
              .control  
                PhoneNumber(
                  :phoneNumber="newPhoneNumber"
                  :selectedCountry="country"
                  :isDisabled="true"
                  :existingPhoneNumber="newPhoneNumber"
                  @getCountryCode="getCountryCode"
                  @getPhoneNumber="getPhoneNumber"
                )
          .Form-col
            .Form-item
              label.Form-item-label {{ $t('Global.timeZone') }}
              .control
                CustomSelect(
                  name="timeZone"
                  v-model="form.timeZoneKey"
                  :optionData="TimeZoneList"
                  optionTitle="value"
                  selectedEmitValue="id"
                  componentName="time-zone-select"
                  :useCustomSearchKey="true"
                  :isDefaultTextActive="false"
                  :defaultTitle="$t('Register.formFieldTimeZoneSelect')"
                  :data-vv-as="$t('Global.timeZone')"
                  :isSearchActive="true"
                  :searchIn="true"
                  v-validate="'required'"
                  :error="veeErrors.has('timeZone')")

                showValidateError(
                  v-show="veeErrors.has('timeZone')"
                  :errorName="veeErrors.first('timeZone')")
        
        .Form-row.m-top-20
          .Form-col.align-self-flex-end
            CustomCheckbox.width-max-content.m-bottom-10(
              id="custom-checkbox-out-of-office"
              :checked="form.isOutOfOffice"
              @change="checkOutOfOffice"
              :label="$t('Settings.Tenant_outOfOffice')")
            CustomCheckbox.width-max-content.m-bottom-10(
              id="custom-checkbox-showCurrencySymbol"
              :checked="form.showCurrencySymbol"
              @change="showCurrencySymbol"
              :label="$t('Settings.Tenant_showCurrencySymbol')")

        .Form-row.submit
          .Form-item.submit
            Button(
            primary
            variant="full"
            size="medium"
            id="btn-settings-submit-user"
            type="submit",
            :disabled="shouldDisableSubmit")
              Loading(theme="disable", v-show="isLoading")
              span(
              v-show="!isLoading"
              ) {{ $t('Settings.User_FormField_submit') }}

</template>

<script>
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import PhoneNumber from '@/view/global/phone-number'
import { mapGetters, mapActions } from 'vuex'
import CustomSelect from '@/view/global/custom-select'
import AuthorizationContext from "@/sso/keycloak-instance";

export default {
  name: 'SettingsUser',
  mixins: [hasTabPermission],

  components: {
    NoPermission,
    PhoneNumber,
    CustomSelect
  },

  data () {
    return {
      form: {
        id: '',
        firstname: '',
        surname: '',
        emailAddress: '',
        languageId: '',
        isOutOfOffice: false,
        phoneNumber: null,
        showCurrencySymbol: false,
        timeZoneKey: null
      },
      isOpenAgreementModal: false,
      isOpenPasswordChange: false,
      newPhoneNumber: null,
      country: {},
      phone: null
    }
  },

  async created () {
    if (this.UserInfo.type === 3) {
      this.getUserAgreement()
    }
    this.form.id = this.UserInfo.id
    this.form.isOutOfOffice = this.UserInfo.isOutOfOffice
    this.form.firstname = this.isSupportUser ? 'Simpra' : AuthorizationContext.token.given_name
    this.form.surname = this.isSupportUser ? 'Support' : AuthorizationContext.token.family_name
    this.form.emailAddress = this.UserInfo.emailAddress
    this.form.languageId = this.languageId
    this.form.showCurrencySymbol = this.UserInfo.showCurrencySymbol
    this.form.timeZoneKey = this.UserInfo.timeZoneKey ? this.UserInfo.timeZoneKey : this.Tenant.timeZoneKey
    if (this.UserInfo.phoneNumber) {
      this.phone = this.UserInfo.phoneNumber.split('-')
      this.country.code = this.phone[0]
      this.newPhoneNumber = this.phone[1]
    }
    await this.getUTCList()
  },
  computed: {

    ...mapGetters('Global', [
      'TimeZoneList',
      'Languages',
      'isSupportUser'
    ]),

    ...mapGetters('Auth', [
      'User',
      'agreement',
      'languageId',
      'Tenant'
    ]),

    ...mapGetters('Settings', [
      'UserInfo',
    ]),

    clearLanguages () {
      return this.Languages.map(lang => ({
        ...lang,
        isoCode: lang.isoCode.split('-')[0]
      }))
    },

    isLoading () {
      return this.$wait.is(['Auth/updateUser'])
    },

    shouldDisableSubmit () {
      const hasChanged = Object.keys(this.form).some(key => {
        return this.form[key] !== this.User[key] || this.newPhoneNumber !== null || this.country !== null
      })

      return this.isLoading ||
        !hasChanged
    },

    selectedLangIsoCode () {
      const lang = this.Languages.find(item => item.id === this.form.languageId)
      return lang.isoCode.split('-')[0]
    }

  },

  methods: {

    ...mapActions('i18n', [
      'langChange'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Auth', [
      'updateUser',
      'getUserAgreement'
    ]),

    ...mapActions('Global', [ 'getUTCList' ]),

    ...mapActions('Settings', [ 'fetchUserForPermission' ]),

    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) return
        this.form.phoneNumber = this.newPhoneNumber ? this.country.code + '-' + this.newPhoneNumber : null
        this.updateUser(this.form).then(async res => {
          await this.fetchUserForPermission()
          // show notyfy
          if (res) {
            this.langChange({
              lang: this.selectedLangIsoCode,
              app: this
            })
            const message = this.$t('Settings.User_FormMessage_success')
            this.notifyShow({ message })
            this.getUserAgreement() 
            // change lang
          }
        })
      })
    },

    getPhoneNumber(number) {
      this.newPhoneNumber = number
    },

    getCountryCode (item) {
      if (item) this.country = item
      else {
        this.country = {
          short: 'TR',
          long: 'Turkey',
          code: '+90'
        }
      }
    },

    checkOutOfOffice () {
      this.form.isOutOfOffice = !this.form.isOutOfOffice
    },

    showCurrencySymbol () {
      this.form.showCurrencySymbol = !this.form.showCurrencySymbol
    },

    openPasswordChange () {
      this.isOpenPasswordChange = !this.isOpenPasswordChange
    },
    openAgreement () {
      this.isOpenAgreementModal = true
    },
    closeAgreementModal () {
      this.isOpenAgreementModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .Form {
    &-row.submit {
      display: flex;
      justify-content: flex-end;
    }

    &-item.submit {
      width : 240px;
    }
  }

  :deep() .not-label{
    max-width: 240px;
  }
  .passwordFade-enter-active, .passwordFade-leave-active {
    transition: opacity .5s;
  }
  .passwordFade-enter, .passwordFade-leave-to {
    opacity: 0;
  }

  .PasswordDisplay{
    display:flex;
    align-self: center;
    .link {
      color: $color-success;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .UserAgreement {
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 600;
    .link {
      color: $color-success;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
    &-agreementText {
      p {
        color: $color-light;
        line-height: 1.5;
      }
    }
  }
  :deep() .phone {
    width: 100% !important;
    &-number {
      width: 80% !important;
    }
    &-country-code {
      width: 20% !important;
    }
  }

  :deep() .select-dropdown-custom-selected {
    width: 100% !important;
    height: 54px !important;
  }
</style>
