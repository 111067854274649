<template lang="pug">
div
  TrainingRecipeConfirmation.training-recipe-container(
      v-if="isTraniningRecipe"
      :isLoading="isOverwritingRecipe"
      @clickCancel="cancelTrainingRecipe"
      @clickOverwrite="overwriteTrainingRecipe"
      @clickAsNew="asNewTrainingRecipe"
    )
  Page
    template(slot="header-breadcrumb-left")
      Loading(v-if="$wait.is('pageLoading')")
      .Breadcrumb.m-top-5(v-else)
        router-link(
          id="link-recipe-breadcrumb"
          :to="{ name: 'Recipes' }"
        ) {{ $t('Recipe.breadcrumb_Recipes') }}
        span {{ ">" }}
        .relative-pairs-tooltip
          a(
            href=""
            id="link-recipe-breadcrumb-active"
          ).isSelected
            | {{ recipeBreadcrumb }}
          PairsTooltip(
            :list="breadCrumbHoverInfo"
            itemKey="key"
            itemValue="value"
          )
    template(slot="header-left-dropdown")
      PageTabButtons.page-tabs.m-left-10(
        :selectedList = "pageHeaderLinks"
        translationGroup="TabItems"
        matchedRoute="Recipes"
      )
    template(slot="header-right-content")
      Button(
        size="medium"
        id="btn-recipe-add"
        primary
        v-show="!isEmpty"
        @click="newStock",
      ) {{ $t('Recipe.addIngredientButton') }}

    template(slot="overgroups-left")
      Statistic(:recipe="recipe_Detail" v-if="isTraniningRecipe && !isEmpty" :showEstimatedCost="showEstimatedCost").m-bottom-10
      BoxView(
        v-if="!isTraniningRecipe && !isEmpty"
        :data="statisticsInfos"
        :fields="statisticsInfosFields"
      )    

    template(slot="toolbar-left")
      ToggleOverGroups(
        :list="typeList"
        :selectedItem="selectedRecipeInfo"
        :useUpperCase="true"
        @delegateChangeSelectedItem="changeTab"
      )
      .show-more
        .Form-item.dropdown-handle
          .select.dropdown-handle-button(
            @keydown.enter="toggleOtherOptions"
            @keydown.prevent.space="toggleOtherOptions"
            @click="toggleOtherOptions"
            v-click-outside="closeOtherOptions"
            :aria-expanded="showOtherOptions"
            tabindex="0"
          )
            .dropdown-handle-button-values {{ $t('Global.moreOptions') }}
            Icon(:class="{'open': showOtherOptions}" name="icon-down-arrow" ).dropdown-handle-button-icon.icon.icon-down-arrow
            
    
          .MiniPopup.left(
            v-if="showOtherOptions"
            v-shortkey="['esc']"
          )
            CustomCheckbox(
              id="checkbox-recipe-show-alternative"
              :disabled= 'isTypeAllergen'
              v-model="showAlternativeStocks"
              @change="getList({loadingName: null})"
              :label="$t('Recipe.Checkbox_Show_Alternatives')"
            )

            CustomCheckbox(
              id="checkbox-recipe-show-estimated-counts"
              :disabled= 'isTypeAllergen'
              v-model="showEstimatedCost"
              @change="estimatedDataList(['cost', 'costRatio', 'totalCost'])"
              :label="$t('Recipe.Checkbox_Show_Estimated_Counts')"
            )

            CustomCheckbox(
              id="checkbox-recipe-show-last-purchase"
              :disabled= 'isTypeAllergen'
              v-model="showLastPurchase"
              @change="getDetails"
              :label="$t('Recipe.Checkbox_Show_Last_Purchase')"
            )
    template(slot="toolbar-right")
      ImportExport(
        componentName="recipe"
        v-if="isTypeTotal && !isTypeAllergen"
        ref="wrapper"
        :activeImportExports=['export']
        downloadUrl="Recipe/detailtotal/export"
        :downloadParams="downloadParams"
        :downloadFileName="downloadFileName"
        downloadFileType="xlsx"
      ) 


    template(slot="content-center")

      .RecipeItem
        TableLoading(v-if="$wait.is('pageLoading')")
        template(v-else)
          .alt-toolbar(v-if="!isEmpty")
            .alt-toolbar-left

              ContentTab.alt-toolbar-item(
                v-if="selectedRecipeInfo.id === 1"
                :data="tabs"
                id="recipe"
                v-model="selectedTab"
                size="lg"
              )

            .alt-toolbar-right.flexbox
              .create-training-recipe(v-if="!isTraniningRecipe")
                Loading(v-if="isLoading")
                Button.bgc-none(
                  size="small",
                  variant="link"
                  id="switch-btn-create-training-recipe"
                  @click="addTrainingRecipe"
                  v-else
                )
                  Icon(name="icon-global-add")
                  span {{ $t('Recipe.Change_Training_Recipe_Label') }}

          div.flexbox(id="recipe-detail-table" v-if="!isTypeTotal && !isTypeAllergen && selectedRecipeInfo.id === 0")
            TableView(
            v-show="!isEmpty"
            :componentName="'recipeTable'"
            :fields="detailFields"
            :data="recipe_Detail.list"
            :pointer="true"
            :actions="['edit', 'remove']"
            :addScrollToNoFixedColumns="true"
            @delegateOnItemEdit="editContent"
            @delegateOnItemDelete="deleteContent")
            
            portal(to="orderRecipe")
              .order-number 
                span {{ $t("Recipe.tableColumn_Order_Number") }}
                Button(
                  size="small"
                  variant="full"
                  secondary
                  id="order-recipe"
                  iconName="icon-navigation-transfer"
                  iconClass="order-number-icon"
                  :class="!recipeDetailOrderNumberList.length ? 'disabled' : ''"
                  @click="changeOrder"
                )


            portal(to="orderNumber")
              div(slot-scope="row")
                customNumberInput(
                  :name="`recipe-order-${row.rowIndex}-${row?.id}`"
                  id="input-order-recipe"
                  v-model="row.orderNumber"
                  :extraDataEmit="row"
                  @blur="setOrderList"
                )

            portal(to="loss1")
              div(slot-scope="row")
                .control
                  customNumberInput(
                    :name="`loss1-${row.index}`"
                    v-model="row.loss1"
                    :error="veeErrors.has(`loss1-${row.index}`)"
                    v-validate="'greater_than:0|max_value:100'"
                    placeholder=",00"
                    :isTypePercentage="true"
                    @blur="changeInlineUpdate({row, scope:`row${row.index}` })"
                  )
            portal(to="loss2")
              div(slot-scope="row")
                .control
                  customNumberInput(
                    :name="`loss2-${row.index}`"
                    v-model="row.loss2"
                    :disabled="row.loss1 === 0 || row.loss1 === null"
                    :error="veeErrors.has(`loss2-${row.index}`)"
                    v-validate="'greater_than:0|max_value:100'"
                    placeholder=",00"
                    :isTypePercentage="true"
                    @blur="changeInlineUpdate({row, scope:`row${row.index}` })"
                  )
            portal(to="netQuantity")
              div(slot-scope="row")
                .control
                  customNumberInput(
                    :name="`netQuantity-${row.index}`"
                    v-model="row.netQuantity"
                    :error="veeErrors.has(`netQuantity-${row.index}`)"
                    v-validate="isAlternativeStockTab ? 'required|decimal:6|is_not:0' : ''"
                    @blur="changeInlineUpdate({row, scope: `row${row.index}`})"
                  )

            portal(to="unitName")
              div(slot-scope="row")
                .control
                  customSelect(
                    :name="`unitName-${row.index}`"
                    :error="veeErrors.has(`unitName-${row.index}`)"
                    v-validate="isAlternativeStockTab ? 'required' : ''"
                    selectedEmitValue="id"
                    optionTitle="name"
                    :optionData="getUnitIdOptions(row)"
                    :isDefaultTextActive="false"
                    v-model="row.unitId"
                    @inputSelected="changeInlineUpdate({row, scope: `row${row.index}`})"
                  )
                  

            portal(to="headerType")
              .category.portal-header-type
                span.category-text {{ $t('Recipe.tableColumn_Ingredients_Type') }}
                span.category-info
                  Icon.show-MiniPopup.category-info-icon(name="icon-global-info-filled")
                  ul.category-popup
                    li.category-popup-row(
                      v-for="item in popupInfoObject"
                    )
                      span.category-popup-value(:class="`label-table-${item.name}`") {{ item.value }}
                      span.category-popup-text {{ item.text }}

            portal(to="detailType")
              span.portal-detail-type(slot-scope="row")
                Button.show-MiniPopup.relative.label-table-recipe(
                size="small"
                id="btn-recipe-detail-badge"
                v-if="row.type === 2"
                @click="goToRecipe(row)")  {{ $t('Recipe.tableColumn_Ingredients_RecipeLabel') }}
                  ul.m-top-20.MiniPopup-left-primary.popup.detail-types
                    li.popup-row
                      .popup-row-label {{ $t('Recipe.extraInfo_NetSalesPrice') }}
                      .popup-row-value.ff-mono.u-textRight {{ row.recipeNetSalesPrice | formatCurrency6Digits }}
                    li.popup-row
                      .popup-row-label {{ $t('Recipe.extraInfo_Cost') }}
                      .popup-row-value.ff-mono {{ row.cost | formatCurrency6Digits }}
                    li.popup-row
                      .popup-row-label {{ $t('Recipe.extraInfo_CostRatio') }}
                      .popup-row-value.ff-mono % {{ row.costRatio | percentFixed }}
                Button.label-table-alternative(v-if="showAlternativeStocks && row.isAlternativeStock" size="small") {{ $t('Recipe.tableColumn_Ingredients_AlternativeLabel') }}
                Button.label-table-stock(v-if="!showAlternativeStocks && row.hasAlternativeStock" size="small") {{ $t('Recipe.tableColumn_Ingredients_StockLabel') }}

          div(id="recipe-detail-totals-table" v-if="isTypeTotal && !isTypeAllergen")
            TableView(
            v-show="!isEmpty"
            :fields="detailTotalFields"
            :componentName="'RecipeDetailTotalsTable'"
            :data="detailTotals"
            :pointer="true"
            :actions="['']"
            :readOnly="true"
            :addScrollToNoFixedColumns="true")

            portal(to="calorie")
              div(slot-scope="row" v-if="row.hasNutritionValue")
                Button.c-light.m-right-15.show-MiniPopup.relative(
                  size="small"
                  :id="`box-list-RecipesBox-row-${row.index}-hover`"
                  :iconName="'icon-calorie'"
                  :iconClass="'icon-calorie'"
                  @mouseenter="showTotalCalorie(row)"
                  @mouseleave="showCalorieLeave()"
                  :justIcon="true"
                  :clearVariant="true"
                )
                  .MiniPopup-left-primary.m-top-15.c-dark.custom-mini-popup-table.calorie-box(v-if="isShowCalorie")
                    Loading(v-if="isCalorieBoxLoading")
                    .calorie-popup(v-else v-for="i, index in totalCalorie")
                      span.m-right-5 {{i.value[0]}} {{i.unit[0]}} |
                      span.m-right-5 {{i.value[1]}} {{i.unit[1]}}

            portal.portal-header-type(to="totalHeaderType")
              .category
                span.category-text {{ $t('Recipe.tableColumn_Ingredients_Type') }}
                span.category-info
                  Icon.show-MiniPopup.category-info-icon(name="icon-global-info-filled")
                  ul.category-popup
                    li.category-popup-row(
                      v-for="item in popupInfoObject"
                    )
                      span.category-popup-value(:class="`label-table-${item.name}`") {{ item.value }}
                      span.category-popup-text {{ item.text }}

            portal(to="totalDetailType")
              span(slot-scope="row")
                Button.label-table-alternative(v-if="showAlternativeStocks && row.isAlternativeStock" size="small") {{ $t('Recipe.tableColumn_Ingredients_AlternativeLabel') }}
                Button.label-table-stock(v-if="!showAlternativeStocks && row.hasAlternativeStock" size="small") {{ $t('Recipe.tableColumn_Ingredients_StockLabel') }}

          div(id="recipe-detail-allergen-table" v-if="!isTypeTotal && !isTypeAllergen && !isTypeCookingDetails && isTypeRecipeInfo").info-container

            .add-info-container
              span
                label.Form-item-label {{ $t('Recipe.servingInfo') }}
                .control
                  customTextareaInput(
                  name="servingInfo"
                  style="resize: none"
                  v-model="recipeHeader.servingInfo"
                  id="input-recipes-form-field-serving-information")
              span
                label.Form-item-label {{ $t('Recipe.mixInfo') }}
                .control
                  customTextareaInput(
                  name="mixInfo"
                  style="resize: none"
                  v-model="recipeHeader.information"
                  id="input-recipes-form-field-information")
              
              .add-info-container-preparation-time
                label.add-info-container-preparation-time-label {{ $t('Recipe.preparationTime') }}
                .control
                  customNumberInput(
                  name="preparationTime"
                  v-model="recipeHeader.preparationTime"
                  id="input-recipes-form-field-preparationTime")

            .add-info-button.m-top-25
              Button(
                id="btn-recipe-add"
                primary
                size="medium"
                :disabled="isRecipeInfoLoading"
                @click="addRecipeInfo"
              )
                span(v-show="!isRecipeInfoLoading")
                  template {{ $t('Recipe.addRecipeInfo') }}
                Loading(theme="disable", v-show="isRecipeInfoLoading")

          div(id="recipe-detail-allergen-table" v-if="!isTypeTotal && isTypeAllergen && !isTypeCookingDetails && recipeAllergenList.length>0")
            TableView(
              :fields="getDetailInfo"
              :componentName="'RecipeDetailAllergen'"
              :data="recipeAllergenList"
              :pointer="true"
              :readOnly="true"
              :actions="['']")
          .cookingDetails(v-if="!isTypeAllergen && isTypeCookingDetails && !isTypeTotal")
            TableView(
            :fields="cookingDetailInfo"
            :readOnly="true"
            :data="cookingTimeList"
            )
            portal(to="toPos")
              div.to-pos-radio-button(slot-scope="row")
                CustomRadio(
                :checked="row.toPos"
                label= ''
                @change="changeToPosType(row)")
            
                span.m-left-10.to-pos-warning(v-show="row.toPos") {{$t('Recipe.sendToPosWarning')}}
         
            portal(to="cookingTime")
              div(slot-scope="row")
                .control
                  customNumberInput(
                    id="recipe-add-cookingTime"
                    :name="`cooktime-${row.index}`"
                    :error="veeErrors.has(`cooktime-${row.index}`)"
                    v-validate="'min_value:0|max_value:999'"
                    v-model="row.cookingTime"
                    @blur="changeCookingTime(row, 'cookingTime')"
                  )
            .add-info-button.m-top-25.p-right-25
              Button(
                id="btn-recipe-add"
                primary
                size="medium"
                :disabled="isRecipeInfoLoading"
                @click="addRecipeInfo"
              )
                span(v-show="!isRecipeInfoLoading")
                  template {{ $t('Recipe.addCookingDetails') }}
                Loading(theme="disable", v-show="isRecipeInfoLoading")
          div(id="recipe-detail-nutrition-total-table" v-if="isTypeTotalCalories && recipeHeader.hasNutritionValues")
            TableLoading(v-if="isNutritionValueLoading")
            TableView(
            :fields="TotalNutritionFields"
            :componentName="'RecipeTotalNutritionValues'"
            :data="totalCalorieValues"
            v-if="recipeHeader.hasNutritionValues && !isNutritionValueLoading"
            :pointer="true"
            :actions="['']"
            :readOnly="true"
            :addScrollToNoFixedColumns="true")

          div(id="recipe-nutrition-detail-table" v-if="isTypeNutritionValueDetails && allNutritionDetails.length > 0 && recipeHeader.hasNutritionValues")
            TableLoading(v-if="isNutritionValueLoading")
            .flexbox(v-else)
              TableView(
              :componentName="'recipeTableNutritionValuesStatic'"
              :fields="staticNutritionTableFields"
              :data="staticNutritionData"
              :pointer="true"
              :readOnly="true"
              )

              TableView(
              :componentName="'recipeTableNutritionValues'"
              :fields="allNutritionDetailFields()"
              :data="allNutritionDetails"
              :pointer="true"
              :readOnly="true"
              :addScrollToNoFixedColumns="true")

              portal(to="action-calorie-detail")
                div(slot-scope="row")
                  Button.c-light.m-right-15.show-MiniPopup.relative(
                    size="small"
                    :id="`box-list-RecipesBox-row-${row.index}-hover`"
                    :iconName="'icon-calorie'"
                    :iconClass="'icon-calorie'"
                    @mouseenter="showTotalCalorie(row)"
                    @mouseleave="showCalorieLeave()"
                    :justIcon="true"
                    :clearVariant="true"
                  )
                    .MiniPopup-left-primary.m-top-15.c-dark.custom-mini-popup-table.calorie-box(v-if="isShowCalorie")
                      Loading(v-if="isCalorieBoxLoading")
                      .calorie-popup(v-else v-for="i, index in totalCalorie")
                        span.m-right-5 {{i.value[0]}} {{i.unit[0]}} |
                        span.m-right-5 {{i.value[1]}} {{i.unit[1]}}

        portal(to="symbol")
          .allergens.width-auto(slot-scope="detail")
            Icon(:name="detail.iconName")

        EmptyState(
        v-if="isEmpty && (!isLoading || !isNutritionValueLoading)",
        :title="$t('Recipe.emptyState_Title')",
        :description="$t('Recipe.emptyState_Description')",
        :buttonText="$t('Recipe.addIngredientButton')",
        @emptyAddButton="newStock")

      router-view(@getList="getList({fetchAll: true})" :key="$route.fullPath")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
import PairsTooltip from '@/view/global/pairs-tooltip'
import ContentTab from '@/view/global/selected-buttons'
import getDetailFields from './fields/detail'
import getDetailTotalFields from './fields/detail-total'
import getDetailInfo from './fields/detail-info'
import cookingDetailInfo from './fields/cooking-details'
import totalNutritionFields from './fields/detail-total-nutrition-values'
import ImportExport from '@/view/global/import-export'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import TrainingRecipeConfirmation from './components/recipe-training-confirmation'
import Statistic from './components/statistic'
import { vueWaitLoader } from '@/utils/baseOperations'
import customSelect from '@/view/global/custom-select'


export default {
  name: 'RecipeDetail',

  components: {
    PairsTooltip,
    ContentTab,
    ImportExport,
    TrainingRecipeConfirmation,
    Statistic,
    ToggleOverGroups,
    customSelect
  },

  data () {
    return {
      detailFields: getDetailFields(this.$t),
      detailTotalFields: getDetailTotalFields(this.$t),
      getDetailInfo: getDetailInfo(this.$t),
      cookingDetailInfo: cookingDetailInfo(this.$t),
      TotalNutritionFields: totalNutritionFields(this.$t),
      detailTotals: [],
      totalCalorieValues: [],
      allNutritionDetails: [],
      allNutritionDetailTitles: [],
      staticNutritionData: [],
      recipeHeader: {},
      selectedTab: '',
      showNutritionDetails: false,
      isShowCalorie: false,
      hasNutritionValues: false,
      totalCalorie: null,
      selectedRecipeInfo: {
        name: this.$t('Recipe.details_Header'),
        id: 0
      },
      showAlternativeStocks: false,
      showEstimatedCost: false,
      showLastPurchase: false,
      showOtherOptions: false,
      tabs: [

        {
          text: this.$t('Recipe.DetailTextContentTotal'),
          value: 'detailTotals'
        },
        {
          text: this.$t('Recipe.DetailAllergenList'),
          value: 'allergenList'
        },
        {
          text: this.$t('Recipe.CookingDetails'),
          value: 'cookingDetails'
        },
        {
          text: this.$t('Recipe.recipeInfo'),
          value: 'recipeInfo'
        },
        {
          text: this.$t('Recipe.totalNutritionValues'),
          value: 'totalCalories'
        },
        {
          text: this.$t('Recipe.nutritionValueDetails'),
          value: 'nutritionValueDetails'
        }
      ],

      cookingTimeList: [
        {
          toPos: false,
          powerValue: '600',
          cookingTime: 0
        },
        {
          toPos: false,
          powerValue: '750',
          cookingTime: 0
        },
        {
          toPos: false,
          powerValue: '900',
          cookingTime: 0
        }
      ],

      typeList: [
        {
          name: this.$t('Recipe.details_Header'),
          id: 0
        },
        {
          name: this.$t('Recipe.ingredient_Detail'),
          id: 1
        }
      ],

      popupInfoObject: [
        {
          value: this.$t('Recipe.tableColumn_Ingredients_StockLabel'),
          text: this.$t('Recipe.tableColumn_Ingredients_StockLabel_Text'),
          name: 'stock'
        },
        {
          value: this.$t('Recipe.tableColumn_Ingredients_RecipeLabel'),
          text: this.$t('Recipe.tableColumn_Ingredients_RecipeLabel_Text'),
          name: 'recipe'
        },
        {
          value: this.$t('Recipe.tableColumn_Ingredients_AlternativeLabel'),
          text: this.$t('Recipe.tableColumn_Ingredients_AlternativeLabel_Text'),
          name: 'alternative'
        }
      ],
      recipeDetailOrderNumberList: []
    }
  },

  watch: {
    selectedTab () {
      this.getList({loadingName: null})
    },
    '$route': {
      handler: function (to, from) {
        if (to.name === from.name) this.getList({fetchAll: true})
      },
      deep: true
    }
  },


  async beforeMount () {
    await this.getList({fetchAll: true})
    this.cookingTimeList.map(i => {
      if (i.powerValue === '600') i.cookingTime = this.recipeHeader.cookingTime600W
      if (i.powerValue === '750') i.cookingTime = this.recipeHeader.cookingTime750W
      if (i.powerValue === '900') i.cookingTime = this.recipeHeader.cookingTime900W
    })
    if (this.recipeHeader.preferedCookingTime !== null) this.cookingTimeList[this.recipeHeader.preferedCookingTime - 1].toPos = true
    await this.setRecipeOrderList()
  },

  beforeDestroy () {
    if (this.isTraniningRecipe) this.cancelTrainingRecipe()
  },

  computed: {
    ...mapGetters('Recipes', [
      'recipe_Detail',
      'pageHeaderLinks',
      'recipeAllergenList',
      'calorieList'
    ]),

    ...mapGetters('Units', [
      'UnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),

    isTraniningRecipe () {
      return this.recipeHeader.status === 2
    },

    isNutritionDetailLoading () {
      return this.$wait.is(['getAllNutritionValues'])
    },

    downloadUrl () {
      return `Recipe/detailtotal/export`
    },

    fixedDetailFields () {
      return this.detailFields.splice(0, 3)
    },

    staticNutritionTableFields () {
      return [
        {
          name: 'action-calorie-detail',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Recipes.calorie'),
          title: this.$t('Recipes.calorie')
        },
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('Recipe.stockItemName'),
          title: this.$t('Recipe.stockItemName')
        }
      ]
    },

    nutritionDetailFields () {
      return [
        {
          name: 'action-calorie',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Recipes.calorie'),
          title: this.$t('Recipes.calorie')
        },
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('Recipe.stockItemName'),
          title: this.$t('Recipe.stockItemName')
        }
      ]
    },

    downloadParams () {
      const params = {
        recipeHeaderId: this.$route.params.recipeHeaderId,
        showAlternativeStocks: this.showAlternativeStocks
      }
      return params
    },

    downloadFileName () {
      const fileName = `Detail_Totals_${this.recipeHeader.name}`
      return fileName
    },

    calculatedPortion () {
      const totalUnit = this.findUnit(this.recipe_Detail.unitId)
      const portionUnit = this.findUnit(this.recipe_Detail.portionUnitId)

      const dividedMultiplier = this.recipe_Detail.totalQuantity * totalUnit.baseQuantity
      const denominatorMultiplier = this.recipe_Detail.portionQuantity * portionUnit.baseQuantity

      return this.roundPortionNumbers(dividedMultiplier / denominatorMultiplier)
    },

    calculatedWeight () {
      return this.roundPortionNumbers(this.recipe_Detail.list.reduce((x, y) => x + y.kgQuantity, 0)) || 0
    },

    calculatedWeightPortion () {
      return this.roundPortionNumbers(this.calculatedWeight / this.calculatedPortion) || 0
    },

    breadCrumbHoverInfo () {
      return [
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Quantity'),
          value: this.recipe_Detail.totalQuantity + ' ' + this.recipe_Detail.totalUnitName
        },
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Portion_Quantity'),
          value: this.recipe_Detail.portionQuantity + ' ' + this.recipe_Detail.portionUnitName
        },
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Calculated_Portions'),
          value: this.calculatedPortion
        },
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Portion_costPerQuantity'),
          value: this.recipeHeader.costPerQuantity,
          isCurrency: true
        },
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Calculated_Weight'),
          value: this.calculatedWeight
        },
        {
          key: this.$t('Recipe.Bread_Crumb_Hover_Calculated_Weight_Portion'),
          value: this.calculatedWeightPortion
        }
      ]
    },

    statisticsInfos () {
      return [
        {
          name: 'GrossSalesPrice',
          text: this.$t('Recipe.stats_GrossSalesPrice'),
          value: this.recipe_Detail?.grossSalesPrice
        },
        {
          name: 'NetSalesPrice',
          text: this.$t('Recipe.stats_NetSalesPrice'),
          value: this.recipe_Detail?.netSalesPrice
        },
        {
          name: 'Cost',
          text: this.$t('Recipe.stats_Cost'),
          value: !this.showEstimatedCost ? this.recipe_Detail?.cost : this.recipe_Detail?.estimatedCost
        },
        {
          name: 'CostRatio',
          text: this.$t('Recipe.stats_CostRatio'),
          value: '% ' + (!this.showEstimatedCost ? this.$options.filters.percentFixed(this.recipe_Detail?.costRatio) : this.$options.filters.percentFixed(this.recipe_Detail?.estimatedCostRatio))
        },
        {
          name: 'Profit',
          text: this.$t('Recipe.stats_Profit'),
          value: !this.showEstimatedCost ? this.$options.filters.formatCurrency6Digits(this.recipe_Detail?.profit) : this.$options.filters.formatCurrency6Digits(this.recipe_Detail?.estimatedProfit)
        },
        {
          name: 'ProfitRatio',
          text: this.$t('Recipe.stats_ProfitRatio'),
          value: '% ' + (!this.showEstimatedCost ? this.$options.filters.percentFixed(this.recipe_Detail?.profitRatio) : this.$options.filters.percentFixed(this.recipe_Detail?.estimatedProfitRatio))
        },
        {
          name: 'CostPerQuantity',
          text: this.$t('Recipe.Bread_Crumb_Hover_Portion_costPerQuantity'),
          value: this.recipeHeader.costPerQuantity
        }
      ]
    },

    statisticsInfosFields () {
      return [
        {
          name: 'text',
          type: 'currency',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'value',
          type: 'currency',
          is_visible: true
        }
      ]
    },

    recipeBreadcrumb () {
      let productionStockItemName = this.recipeHeader.productionStockItemName
        ? ` | ${this.$t('Recipe.Bread_Crumb_Production_Name')}: ${this.recipeHeader.productionStockItemName}`
        : ''
      return this.recipe_Detail ? this.recipe_Detail.name + productionStockItemName : ''
    },

    isEmpty () {
      return this.isTypeTotal ? this.detailTotals.length === 0 : this.recipe_Detail && this.recipe_Detail.list.length === 0
    },

    isLoading () {
      return this.$wait.is(['get_RECIPE_DETAIL_TOTALS', 'get_RECIPE_DETAIL', 'create_TRAINING_RECIPE', 'addTrainingRecipe'])
    },

    isNutritionValueLoading () {
      return this.$wait.is(['getAllNutritionValueDetails', 'getTotalCaloriValues'])
    },

    isCalorieBoxLoading () {
      return this.$wait.is('ShowCalorieBox')
    },

    isRecipeInfoLoading () {
      return this.$wait.is('addRecipeInfo')
    },

    isTypeTotal () {
      return this.selectedTab === 'detailTotals'
    },

    isTypeAllergen () {
      return this.selectedTab === 'allergenList'
    },

    isTypeCookingDetails () {
      return this.selectedTab === 'cookingDetails'
    },

    isTypeRecipeInfo () {
      return this.selectedTab === 'recipeInfo'
    },

    isTypeNutritionValueDetails () {
      return this.selectedTab === 'nutritionValueDetails'
    },

    isTypeTotalCalories () {
      return this.selectedTab === 'totalCalories'
    },

    isOverwritingRecipe () {
      return this.$wait.is(['submitTraningRecipe'])
    },
    isAlternativeStockTab () {
      return this.activeTabIndex === 0 && this.showAlternativeStocks
    },
  },

  methods: {
    ...mapActions('Recipes', [
      'get_RECIPE_DETAIL',
      'get_RECIPE_DETAIL_TOTALS',
      'updateOrderNumber',
      'create_TRAINING_RECIPE',
      'finalize_TRAINING_RECIPE',
      'getRecipeAllergens',
      'update_RECIPE_HEADER',
      'update_RECIPE_DETAIL',
      'get_RECIPE_HEADER',
      'getTotalCalorie',
      'getAllNutritionValues'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    setRecipeOrderList () {
      this.recipeDetailOrderNumberList = [...this.recipe_Detail.list].map(i => {
        return {
          item1: i.id,
          item2: i.orderNumber
        }
      })
    },
    
    cancelTrainingRecipe: vueWaitLoader(async function () {
      await this.finalize_TRAINING_RECIPE({trainingRecipeHeaderId: this.recipeHeader.id, processType: 3})
        .then(res => {
          if (!res) return
          this.$router.push({
            params: {
              recipeHeaderId: this.recipeHeader.sourceRecipeHeaderId
            }
          })
        })
    }, 'submitTraningRecipe'),

    changeTab (item) {
      item.id === 1 ? this.selectedTab = 'detailTotals' : this.selectedTab = ''
      this.selectedRecipeInfo = item
    },

    allNutritionDetailFields () {
      return this.allNutritionDetailTitles.map(i => {
        return ({name: i, is_visible: true, short_title: i, title: i})
      })
    },

    showTotalCalorie: vueWaitLoader(async function (item) {
      this.isShowCalorie = true
      const res = await this.getAllNutritionValues({
        RecipeHeaderId: this.$route.params.recipeHeaderId,
        StockItemId: item.stockItemId,
        FetchCaloriesOnly: true
      })
      if (res) this.totalCalorie = [{value: res.data.itemList[0]?.values, unit: res.data.title}]
    }, 'ShowCalorieBox'),

    showCalorieLeave () {
      this.isShowCalorie = false
    },

    addRecipeInfo: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        const payload = {
          ...this.recipeHeader,
          information: this.recipeHeader.information,
          servingInfo: this.recipeHeader.servingInfo,
          preparationTime: this.recipeHeader.preparationTime,
          cookingTime600W: this.cookingTimeList[0].cookingTime,
          cookingTime750W: this.cookingTimeList[1].cookingTime,
          cookingTime900W: this.cookingTimeList[2].cookingTime,
          preferedCookingTime: this.getPreferedCookingTime()
        }
        await this.update_RECIPE_HEADER(payload)
        const message = this.$t('Recipe.info_Updated')
        this.notifyShow({ message })
      })
    }, 'addRecipeInfo'),

    changeCookingTime (value, type) {
      let cookTime = this.cookingTimeList.find(i => i.powerValue === value.powerValue)
      cookTime[type] = value[type]
    },

    changeToPosType (value) {
      this.cookingTimeList.map(i => {
        if (i.powerValue === value.powerValue) i.toPos = !value.toPos
        else i.toPos = false
      })
    },

    getPreferedCookingTime () {
      return this.cookingTimeList[0].toPos
        ? 1
        : this.cookingTimeList[1].toPos
          ? 2
          : this.cookingTimeList[2].toPos
            ? 3
            : null
    },

    overwriteTrainingRecipe: vueWaitLoader(async function () {
      await this.finalize_TRAINING_RECIPE({trainingRecipeHeaderId: this.$route.params.recipeHeaderId, processType: 2})
        .then(res => {
          if (!res) return
          this.$router.push({
            params: {
              recipeHeaderId: this.recipeHeader.sourceRecipeHeaderId
            }
          })
        })
    }, 'submitTraningRecipe'),

    estimatedDataList (propertyNames) {
      this.detailFields.map(item => {
        if (propertyNames.indexOf(item.name) !== -1 || item.name.startsWith('estimated')) {
          item.name =
              this.showEstimatedCost
                ? 'estimated' + item.name.charAt(0).toUpperCase() + item.name.slice(1)
                : item.name.replace('estimated', '')
          if (!this.showEstimatedCost) item.name = item.name.charAt(0).toLowerCase() + item.name.slice(1)
        }
      })
    },

    asNewTrainingRecipe () {
      this.$router.push({
        name: 'RecipeTraining',
        params: {
          item: this.recipeHeader
        }
      })
    },

    getUnitIdOptions (row) {
      const relatedUnitList = this.getRelatedUnitList(row.baseUnitId,true).map(i => {
        return { id: i.id, name: i.name };
      });
      const unitList = [...relatedUnitList]
      return unitList;
    },

    addTrainingRecipe: vueWaitLoader(async function (isChecked) {
      await this.create_TRAINING_RECIPE(this.$route.params.recipeHeaderId)
        .then(res => {
          this.$router.push({
            params: {
              recipeHeaderId: res.data.trainingRecipeHeaderId
            }
          })
        })
    }, 'addTrainingRecipe'),

    changeInlineUpdate ({row,scope}) {
      this.$validator.validateAll(scope)
        .then(async (result) => {
          if (!result) return
          const detail = {
            id: row.id,
            netQuantity: row.netQuantity,
            unitId: row.unitId,
            loss1: row.loss1,
            loss2: row.loss1 === 0 ? 0 : row.loss2,
            stockItemId: row.type === 1 ? row.stockItemId : null,
            recipeId: row.type === 2 ? row.recipeId : null
          }
          await this.update_RECIPE_DETAIL({
            detail: detail,
            type: row.type
          })
          this.getList({loadingName: null})
        })
    },

    setOrderList (item) {
      const recipe = item.extraDataEmit
      const orderNumber = item.inputData
      this.recipeDetailOrderNumberList.find(i => {
        if (i.item1 === recipe.id) i.item2 = orderNumber
      })
    },

    async changeOrder () {
      await this.updateOrderNumber({recipeDetailOrderNumberList: this.recipeDetailOrderNumberList}).then(res => {
        if (res) {
          this.getList({loadingName: 'pageLoading'}).then(res => {
            if (res) this.setRecipeOrderList()
          })
        }
      })
    },

    async getList ({loadingName = 'pageLoading', fetchAll = false}) {
      if (this.isTypeAllergen) return
      this.$wait.start(loadingName)
      if (this.isTypeTotalCalories) await this.getTotalCaloriValues()
      if (this.isTypeNutritionValueDetails) {
        await this.getNutritionValueDetails()
      }
      if (this.isTypeTotal || fetchAll) {
        await this.getDetailTotals()
        await this.getDetails()
      } else {
        await this.getDetails()
      }
      await this.getRecipeAllergens({ RecipeId: this.$route.params.recipeHeaderId })
      await this.setRecipeOrderList()
      this.$wait.end(loadingName)
    },

    async getDetailTotals () {
      const res = await this.get_RECIPE_DETAIL_TOTALS({
        recipeHeaderId: this.$route.params.recipeHeaderId,
        showAlternativeStocks: this.showAlternativeStocks
      })
      this.detailTotals = res.data.itemList
    },

    getTotalCaloriValues: vueWaitLoader(async function () {
      if (!this.recipeHeader.hasNutritionValues) return
      const res = await this.getTotalCalorie({
        RecipeHeaderId: this.$route.params.recipeHeaderId,
        FetchCaloriesOnly: null
      })
      if (res) this.totalCalorieValues = res.data.itemList
    }, 'getTotalCaloriValues'),

    getNutritionValueDetails: vueWaitLoader(async function () {
      const res = await this.getAllNutritionValues({
        RecipeHeaderId: this.$route.params.recipeHeaderId,
        FetchCaloriesOnly: false
      })
      if (res) {
        this.staticNutritionData = res.data.itemList.map(i => i) || []
        this.allNutritionDetails = res.data.itemList.map(i => i.values) || []
        this.allNutritionDetails = this.allNutritionDetails.map((i, index) => {
          const b = i.map((el, key) => {
            return {
              [res.data.title[key]]: el
            }
          })
          return Object.assign({}, ...b)
        })
        this.allNutritionDetailTitles = res.data.title
      }
      
    }, 'getAllNutritionValueDetails'),

    async getDetails () {
      await this.get_RECIPE_DETAIL({
        recipeHeaderId: this.$route.params.recipeHeaderId,
        showAlternativeStocks: this.showAlternativeStocks,
        costCalculationType: this.showLastPurchase ? 1 : null
      })
        .then(async (res) => {
          if (!res) return
          this.recipeHeader = await res.data.recipeHeader
        })
    },

    roundPortionNumbers (value) {
      if (value.toString().indexOf('.') > -1) {
        return Number(value).toFixed(3)
      } else {
        return Number(value)
      }
    },

    tabChange (index) {
      this.activeTabIndex = index
    },

    toggleOtherOptions () {
      this.showOtherOptions = !this.showOtherOptions
    },

    closeOtherOptions () {
      this.showOtherOptions = false
    },

    closePopup () {
      this.selectedItem = {}
      this.popup.new.status = false
      this.popup.edit.status = false

      this.formNewStockItem.stockItem = null
      this.formNewStockItem.grossQuantity = null
    },

    newStock () {
      this.$router.push({
        name: 'RecipeDetailNew',
        params: {
          recipeHeaderId: this.$route.params.recipeHeaderId
        }
      })
    },

    editContent (detail) {
      this.$router.push({
        name: 'RecipeDetailEdit',
        params: {
          detail,
          detailId: detail.id,
          recipeHeaderId: this.$route.params.recipeHeaderId
        }
      })
    },

    goToRecipe (detail) {
      this.$router.push({
        name: 'Recipe',
        params: {
          recipeHeaderId: detail.recipeId
        }
      })
    },

    deleteContent (detail) {
      this.$router.push({
        name: 'RecipeDetailRemove',
        params: {
          detailId: detail.id,
          item: detail
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";
  @import "~@/stylesheet/config/mixin";

  .info-container{
    width: 100%;

    .add-info-container{
      display: flex;
      width: 100%;
      justify-content: space-around;
      span{
        margin-top: 30px;
        width: 40%;
      }
      &-preparation-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        .control {
          position: relative;
          top: -50px;
        }
      }
    }
  }
  .add-info-button{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    Button{
      width: auto
    }
  }
  .toolbar{
    align-items: center;
  }


  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .label-table-recipe {
    padding: 5px 7px;
    font-size: $font-size-small;
    background-color: lighten($color-success, 50%);
    color: $color-success;
    border: 1px solid $color-success;
    border-radius: $border-radius;
  }
  .label-table-stock {
    padding: 5px 7px;
    font-size: $font-size-small;
    background-color: lighten($color-primary, 40%);
    color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: $border-radius;
  }
  .label-table-alternative {
    padding: 5px 7px;
    font-size: $font-size-small;
    background-color: lighten($color-orange, 45%);
    color: $color-orange;
    border: 1px solid $color-orange;
    border-radius: $border-radius;
  }

  .popup {
    padding: 0;

    &-row:not(:last-child) {
      border-bottom: 1px solid $color-gray;
    }
    &-row {
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-label {
        color: $color-success;
        font-size: $font-size-normal;
      }

      &-value {
        font-weight: $font-weight-bold;
        color: $color-dark;
      }
    }
  }

  :deep() .selectedButton {
    border: none !important;
    &.formTab button {
      font-size: 16px !important;
    }
  }

  .alt-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-left {
      display: flex;
      align-items: flex-start;
    }

    &-checkbox{
      display: flex !important;
      align-items: center !important;
      height: 39px - 4px; // height - border
    }
    &-loading {
      display: flex !important;
      align-items: flex-end !important;
      height: 39px - 4px; // height - border
    }

    &-item:not(:first-child) {
      margin-left: 25px;
    }
  }

  $info-icon-size: 15px;
  .category {
    display: flex;

    &-info {
      position: relative;
      height: auto;

      &-icon {
        margin-left: 2.4px;
        width: $info-icon-size;
        height: $info-icon-size;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &-popup {
      top: 35px !important;
      @include MiniPopupMixin(
        $display: none,
        $width: 186px,
        $max-width: 340px,
        $triangle-direction: 'left',
        $padding-hor: 0,
        $padding-ver: 0,
      );

      color: $color-dark;

      &-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
      }

      &-row:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }

      &-value {
        font-weight: $font-weight-bold;
      }

      &-text {
        margin-left: 11px;
        color: $color-dark;
        font-size: $font-size-normal;
      }
    }
  }

  .portal-detail-type,
  .portal-header-type {
    display: flex;
    justify-content: center;
    max-height: 100%;
  }

  :deep(.Tableee) {
    &-main {
      min-height: 400px !important;
    }
    &-scroll {
      width: 100%;
      overflow: auto;
    }
  }

  .training-recipe-container {
    position: sticky;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
    width: 100%;
    z-index: $z-index-xl + 10;
  }

  :deep() .Boxlist-item {
    min-width: 120px!important;
    width: calc(12% - 20px);
    flex: 1;
    border: 1px solid $color-gray;
    box-shadow: none;
    margin-left: 20px!important;
  }

  :deep() .matched-title-name-text {
    word-break: break-word;
    color: $color-success;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }

  :deep() .Boxlist-item:hover > .box-header {
    padding-right: 15px!important;
  }

  :deep() .Boxlist-item:hover {
    box-shadow: none;
  }

  :deep() .box-content {
    padding: 0 20px 15px!important;
    .box-row .value {
      font-size: 1.2em!important;
      font-weight: $font-weight-bold!important;
      font-family: "SF Mono", Menlo, monospace;
    }
  }

  :deep() .Tableee-col.vue-portal-target{
    display: flex;
    align-items: center;
  }

  :deep() .portal.vue-portal-target{
    display:flex;
    justify-content: center;
  }


  :deep() .Tableee-col.totalDetailType,
  :deep() .Tableee-col.orderNumber {
    padding: 0;
  }
  :deep() .Tableee-col.symbol{
    display: flex;
    align-items: center;
  }
  :deep() .Tableee-col.orderNumber {
    padding-left: 10px;
    padding-right: 10px;

    .order-number-input {
      width: 55px;
      font-size: $font-size-small;
      font-family: $font-family-mono;
    }
  }
  :deep() .portal.vue-portal-target{
    justify-content: flex-start;
  }
  .custom-mini-popup{
    max-width: 330px;
    left: calc(50% - 175px);
  }

  .allergen-box{
    max-width: 330px;
  }
   :deep() .Tableee-head .Tableee-col.fixed {
    &:nth-child(1) {
      left: 0;
      z-index: $z-index-md;
    }
    &:nth-child(2) {
      left: 75px;
      z-index: $z-index-md;
    }
    &:nth-child(3) {
      left: 140px;
      z-index: $z-index-md;
      border-right: 1px solid rgba(152, 150, 150, 0.202);
    }
  }
  :deep() .Tableee-body .Tableee-row td.fixed {
    &:nth-child(1) {
      left: 0;
      z-index: $z-index-sm;
    }
    &:nth-child(2) {
      left: 75px;
    }
    &:nth-child(3) {
      left: 140px;
      border-right: 1px solid rgba(152, 150, 150, 0.202);
    }
  }

  :deep(.Tableee-body .Tableee-col) {
    z-index: $z-index-xs !important;
  }
  :deep(.percentage) {
    top: 25% !important;
  }
  .control {
    .custom-number {
      min-width: 65px !important;
    }
  }
  :deep() {
    .icon-calorie {
      width: 20px;
      height: 20px;
    }
  }
  .show-more {
    position: relative;
    .dropdown-handle {
      display: flex;
      align-items: center;
      font-size: $font-size-micro;
      user-select: none;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;

      .disabled-button {
        pointer-events: none;
        opacity: 0.6;
      }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      height: 35px;
      color: inherit;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;
      

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        color: $color-success;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }
  }
    .MiniPopup {
      z-index: $z-index-lg;
      width: max-content;
      top: 40px;
      border: 1px solid $color-gray;

      .label {
        justify-content: start;
        padding: 10px;
        &:hover{
          background-color: $color-gray;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
  .to-pos {
    &-radio-button {
      display: flex;
    }
    &-warning {
      color: $color-warning
    }
  }

  .order-number {
    width: 100%;
    display: flex;
    align-items: center;
    .btn {
      padding: 10px !important;
      margin-left: 10px;
    }
  }
  :deep() .icon-navigation-transfer {
    margin-right: 0 !important;
    width: 20px !important;
    height: 20px !important;
  }
  .detail-types  {
    top: -15px;
    left: 55px;
  }
</style>
