  <template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search(
      componentName="Despatch",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('Despatches.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-dropdown")
    .form-item-select.right-date.m-right-10
      CustomDatepicker#DespatchDatepicker(
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isLoading || $wait.is('pageLoading')"
        v-model="dates",
        :range="true")
  template(slot="header-right-content")
    Button(
      id="btn-despatch-new"
      type="button",
      primary
      size="medium"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      v-if="!despatch_IsEmpty"
      @click="despatchHeaderNew")
        span {{ $t('Despatches.addDespatchButton') }}

  template(slot="toolbar-left")
    SupplierFilter(@submitFilter="getListWithSupplierFilter"
    ref="despatchSupplierFilter")

    storeFilter(
      v-if="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive"
      @submitFilter="getListWithStoreFilter"
      ref="despatchStoreFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
        @click='clearFilter'
        )
          Icon.icon-clear-filter(name="icon-clear-filter")

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-despatch-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")
  template(slot="toolbar-right")
    .total(v-if="!despatch_IsEmpty")
      TotalViewList(:totalCountArray="totalCountData")
    Button(
    primary
    size="small"
    id="btn-despatch-selected-save"
    type="button",
    iconName="icon-btn-success"
    iconClass="icon-btn-success"
    @click="finalizeSelectedDespatches",
    :disabled="isEmptySelectedDespatches || isSelectedOneMoreThanSupplier || isInvoiceButtonActive")
      span {{ $t('Despatches.SaveSelectedDespatchesButton') }}
      .alert(v-show="isSelectedOneMoreThanSupplier") {{ $t('Despatches.UmustSelectOneSupplier') }}
        .alert-arrow

  
  template(slot="content-center")

    TableLoading(v-if="isLoading || $wait.is('pageLoading')")

    // EMPTY PAGE

    EmptyState(
    v-if="despatch_IsEmpty && !isLoading && !$wait.is('pageLoading')",
    :title="$t('Despatches.emptyState_Title')",
    :description="$t('Despatches.emptyState_Description')",
    :buttonText="$t('Despatches.emptyState_Button')",
    @emptyAddButton="despatchHeaderNew")

    // LIST
    DespatchTable(v-if="!despatch_IsEmpty && !isLoading && !$wait.is('pageLoading')"
      :tableData = "despatch_List",
      :showDetailAction = "despatchDetail",
      :showExportDespatch = "showExportDespatch"
      :editAction = "despatchHeaderEdit"
      :customAction = "convertToTransfer"
      :removeItemAction = "despatchRemove"
      :selected="selectedDespatches"
      :RoleArea="RoleArea"
      :isSelectedOneMoreThanSupplier="isSelectedOneMoreThanSupplier"
      @delegateCheckItem="handleCheckbox"
      @delegateCheckAllItem="toggleAll")



    router-view(
      @takePrint="showExportDespatch"
      @takeInvoicePrint="showExportInvoice"
      @showDrafts="showDrafts = true"
    )


    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )

  template(slot="footer")
    TableFoot(
    :page="DespatchPage"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize"
    )
</template>

<script>
import DespatchTable from './despatches-table'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import exportPopup from '@/view/global/export-popup'
import TableFoot from '@/view/global/table/foot.vue'
import storeFilter from '@/view/global/business-filters/store.vue'


//
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'Despatch',

  data () {
    return {
      // groupTypes: [{
      //   name: this.$t('Despatches.groupTypes_OverGroup'),
      //   value: 1
      // }, {
      //   name: this.$t('Despatches.groupTypes_Invoice'),
      //   value: 2
      // }, {
      //   name: this.$t('Despatches.groupTypes_Despatch'),
      //   value: 3
      // }
      // ],
      activeGroupType: 1,
      supplierFilter: [],
      storeFilter: [],
      selectedDespatches: [],
      dates: [],
      isInvoiceButtonActive: false,
      searchText: null,
      showDrafts: false
    }
  },

  components: {
    DespatchTable,
    SupplierFilter,
    exportPopup,
    TableFoot,
    storeFilter
  },

  mixins: [settingsExportPopup],

  async created () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    await this.setShowDraftStatus()
    this.dates = getDateFilterSetting('Date_Despatch')
    await this.UPDATE_START_DATE(this.dates[0])
    await this.UPDATE_END_DATE(this.dates[1])
    await this.getTaxes()
    this.getDespatchListWithParams (1, this.DespatchPage.size, true)
    this.$wait.end('pageLoading')
  },

  async beforeMount () {
    await this.setRoleArea()
  },

  async beforeDestroy () {
    this.RESET()
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.UPDATE_START_DATE(this.dates[0])
      this.UPDATE_END_DATE(this.dates[1])
      this.getDespatchListWithParams()
    }
  },

  computed: {
    ...mapGetters('Despatch', [
      'despatch_IsEmpty',
      'despatch_List',
      'DespatchPage',
      'startDate',
      'endDate',
      'pageHeaderLinks'
    ]),

    ...mapGetters('Settings', [
      'RoleArea',
      'tenantMultiCurrencyIsActive',
      'tenantMustHaveSingleStoreInSameDespatchActive'
    ]),

    ...mapGetters('DocumentSettings', [
      'DocumentSettingList'
    ]),

    totalCountData () {
      return [
        {
          totalCountLabel: this.$t('Despatches.SelectedDespatchesNetTotal'),
          totalCountValue: this.$options.filters.formatCurrency3Digits(this.calculateNetTotal),
          extraClass: 'with-bg'
        },
        {
          totalCountLabel: this.$t('Despatches.SelectedDespatchesTax'),
          totalCountValue: this.$options.filters.formatCurrency3Digits(this.calculateTaxTotal),
          extraClass: 'with-bg'
        },
        {
          totalCountLabel: this.$t('Despatches.SelectedDespatchesGrossTotal'),
          totalCountValue: this.$options.filters.formatCurrency3Digits(this.calculateGrossTotal),
          extraClass: 'with-bg'
        }
      ]
    },

    isSelectedOneMoreThanSupplier () {
      let selectedSupplierList = this.selectedDespatches.filter(despatch => {
        return this.selectedDespatches[0].supplier.id !== despatch.supplier.id
      })
      return selectedSupplierList.length > 0
    },

    isLoading () {
      return this.$wait.is(['get_DESPATCH_LIST']) || this.$wait.is(['getMiniSuppliersList'])
    },

    calculateNetTotal () {
      let result = 0
      this.selectedDespatches.forEach(despatch => {
        result += despatch.netTotalAmount
      })
      return result
    },

    calculateTaxTotal () {
      let result = 0
      this.selectedDespatches.forEach(despatch => {
        result += despatch.taxTotalAmount
      })
      return result
    },

    calculateGrossTotal () {
      let result = 0
      this.selectedDespatches.forEach(despatch => {
        result += despatch.grossTotalAmount
      })
      return result
    },

    isEmptySelectedDespatches () {
      return this.selectedDespatches.length === 0
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Tax', [
      'getTaxes'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH_LIST',
      'get_DESPATCH'
    ]),

    ...mapActions('Supplier', [
      'getMiniSuppliersList'
    ]),

    ...mapActions('Settings', [
      'setRoleArea'
    ]),

    ...mapActions('DocumentSettings', [
      'getDocumentFilterSettings'
    ]),

    ...mapMutations('Despatch', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'UPDATE_START_DATE',
      'UPDATE_END_DATE',
      'UPDATE_SUPPLIER_IDS',
      'UPDATE_PARENT_STORE_IDS',
      'RESET'
    ]),

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.supplierFilter = list
        this.getDespatchListWithParams()
      }
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        await this.getDespatchListWithParams()
      }
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getDespatchListWithParams()
    },

    finalizeSelectedDespatches () {
      this.$router.push({
        name: 'MultipleDespatchFinalize',
        params: {
          despatchesList: this.selectedDespatches
        }
      })
      this.selectedDespatches = []
    },

    clearFilter () {
      this.$refs.despatchSupplierFilter.clearSupplierFilter(false)
      this.$refs.despatchStoreFilter.clearStoreFilter(false)
    },

    onChangeSearch (search) {
      this.searchText = search
      if (this.searchText.length === 1) return
      this.getDespatchListWithParams()
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Despatch') this.showDrafts = i.isChecked
      })
    },

    getDespatchListWithParams (page = 1, pageSize = this.DespatchPage.size, isInitialFetch = false) {
      let supList = []
      let storeList = []
      this.selectedDespatches = []
      if (!isInitialFetch) {
        this.supplierFilter.forEach(sup => supList.push(sup.id))
        this.storeFilter.forEach(store => storeList.push(store.id))
      }
      this.UPDATE_PARENT_STORE_IDS(storeList)
      this.UPDATE_SUPPLIER_IDS(supList)
      this.get_DESPATCH_LIST({
        SupplierList: supList,
        recordFilterType: this.activeGroupType,
        startDate: this.dates[0],
        endDate: this.dates[1],
        to: page,
        pageSize,
        showDrafts: this.showDrafts,
        DespatchNumber: this.searchText,
        ParentStoreIds: this.tenantMustHaveSingleStoreInSameDespatchActive ? storeList : null
      })
    },

    changePage (page) {
      this.getDespatchListWithParams(page)
    },

    changePageSize (pageSize) {
      this.getDespatchListWithParams(1, pageSize)
    },

    // onChangeGroupType(groupType) {
    //   if (this.activeGroupType === groupType.value) {
    //     this.activeGroupType = null
    //     if (!this.includeSupplier) {
    //       this.includeSupplier = true
    //       this.isSupplierPrimary = false
    //     }
    //   } else {
    //     this.activeGroupType = groupType.value
    //   }
    //   this.getDespatchListWithParams()
    // },


    handleCheckbox ({ isChecked, despatch }) {
      this.isInvoiceButtonActive = false
      if (isChecked) {
        this.selectedDespatches.push(despatch)
        this.get_DESPATCH(despatch.id).then(res => {
          if (res.status === 200) {
            if (res.data.despatchHeader.supplier.isEInvoicePayer) this.isInvoiceButtonActive = true
          }
        })
      } else {
        this.selectedDespatches.splice(this.selectedDespatches.indexOf(despatch), 1)
      }
    },

    toggleAll (isChecked) {
      if (isChecked) {
        this.selectedDespatches = this.despatch_List.filter(despatch => despatch.status !== 1)
      } else {
        this.selectedDespatches = []
      }
    },

    async despatchDetail (despatch) {
      if (despatch.showDetail) {
        this.HIDE_DETAIL(despatch)
        return
      }
      await this.get_DESPATCH(despatch.id).then(res => {
        if (res.status === 200) {
          this.SHOW_DETAIL(res.data)
        }
      })
    },
    despatchRemove (despatch) {
      this.selectedDespatches = []
      this.$router.push({
        name: 'DespatchRemove',
        params: {
          id: despatch.id,
          item: despatch
        }
      })
    },
    despatchHeaderEdit (despatch) {
      this.selectedDespatches = []
      if (despatch.isLive) {
        alert(this.$t('Despatches.error_CantModifyRecordedPurchase'))
        return
      }
      this.$router.push({
        name: 'DespatchHeaderEdit',
        params: {
          id: despatch.id,
          despatchHeader: despatch
        }
      })
    },
    convertToTransfer (despatch) {
      this.$router.push({
        name: 'DespatchConvertToTransferForm',
        params: {
          id: despatch.id,
          date: despatch.despatchDate
        }
      })
    },
    despatchHeaderNew () {
      this.selectedDespatches = []
      this.$router.push({
        name: 'DespatchHeaderNew'
      })
    },
    showExportDespatch (despatchId, isFinalizeAction = false) {
      this.exportFrame(despatchId, 2, isFinalizeAction, 'Despatch')
    },
    showExportInvoice (invoiceId, isFinalizeAction = false) {
      this.exportFrame(invoiceId, 3, isFinalizeAction, 'Invoice')
    }
  }
}
</script>

<style scoped lang="scss">

:deep(.Modal) {
  &-Body {
    z-index: $z-index-xxxl !important;
    &-content {
      width: 80vw !important;
    }
  }
  &-Overlay[data-v-b9dcd536] {
    z-index: $z-index-xxl !important;
  }
}
:deep(.Header) {
  z-index: 400 !important
}
.group-types {

  &-item {
    padding: 8px 10px;
    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: $font-size-micro;
    line-height: 1;
    font-family: $font-family;
    color: $color-light;
    cursor: pointer;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;
    user-select: none;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active {
      background: $color-success;
      color: #fff;
      border-color: transparent;
    }
  }
}

.total {
  display: flex;
  align-items: center;
}
.btn {
  position: relative;
  .alert {
    position: absolute;
    font-size: $font-size-small;
    background-color: white;
    box-shadow: 0 0 0 2px rgba($color-warning, 1);
    border-radius: $border-radius;
    padding: 15px;
    width: 200px;
    top: 110%;
    z-index: 2;

    &-arrow {
      position: absolute;
      top: -18px;
      left: 45%;
      width: 15px;
      border: 8px solid;
      border-color: transparent transparent $color-warning transparent;
    }
  }
}
:deep() .Search-input {
  margin-left: 0 !important;
}
</style>
