<template lang="pug">
.Tableee-container
  .Despatches-details
    .table-scroll
      table(id="despatches-table-detail")
        thead.Despatches-details-head(v-if="!despatch.detailIsEmpty" id="despatches-table-detail-header")
          tr.Despatches-details-head-row(id="despatches-table-detail-row")
            th.Despatches-details-head-col.stockItemName(id="despatches-table-detail-header-checkbox")
              CustomCheckbox(
                :id="`checkbox-despatch-detail-select-all-${rowIndex}`"
                label=""
                :checked="isHeaderChecked()"
                @change="isChecked => toggleHeader(isChecked)"
              )
            th.Despatches-details-head-col.stockItemName.u-textLeft(id="despatches-table-detail-header-stock-item-name")
              h6 {{ $t('Despatches.detail_TableColumn_StockItemName') }}
            th.Despatches-details-head-col.order-stockOnHand.u-textLeft(id="despatches-table-detail-stockOnHand-quantity")
              h6 {{ $t('Despatches.detail_TableColumn_StockOnHandQuantity') }}
            th.Despatches-details-head-col.quantity.u-textLeft(id="despatches-table-detail-header-quantity")
              h6 {{ $t('Despatches.detail_TableColumn_Quantity') }}
            th.Despatches-details-head-col.order-quantity.u-textLeft(id="despatches-table-detail-header-order-quantity")
              h6 {{ $t('Despatches.detail_TableColumn_OrderQuantity') }}
            th.Despatches-details-head-col.unitCode.u-textCenter(id="despatches-table-detail-header-unit-code")
              h6 {{ $t('Despatches.detail_TableColumn_UnitCode') }}
            th.Despatches-details-head-col.netUnitPrice.u-textRight(id="despatches-table-detail-header-net-unit-price")
              h6 {{ $t('Despatches.detail_TableColumn_NetUnitPrice') }}
            th.Despatches-details-head-col.netAmount.u-textRight(id="despatches-table-detail-header-net-amount")
              h6 {{ $t('Despatches.detail_TableColumn_NetAmount') }}
            th.Despatches-details-head-col.baseQuantity.u-textRight(id="despatches-table-detail-header-base-quantity")
              h6 {{ $t('Despatches.detail_TableColumn_BaseQuantity') }}
            th.Despatches-details-head-col.baseUnit(id="despatches-table-detail-header-base-unit")
              h6 {{ $t('Despatches.detail_TableColumn_BaseUnit') }}
            th.Despatches-details-head-col.basePrice.u-textRight(id="despatches-table-detail-header-base-price")
              h6 {{ $t('Despatches.detail_TableColumn_BasePrice') }}
            th.Despatches-details-head-col.discount1.u-textRight(id="despatches-table-detail-header-discount-1")
              h6 {{ $t('Despatches.detail_TableColumn_Discount1') }}
            th.Despatches-details-head-col.discount2.u-textRight(id="despatches-table-detail-header-discount-2")
              h6 {{ $t('Despatches.detail_TableColumn_Discount2') }}
            th.Despatches-details-head-col.discountedUnitPrice.u-textRight(id="despatches-table-detail-header-discounted-unit-price")
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedUnitPrice') }}
            th.Despatches-details-head-col.discountedBaseUnitPrice.u-textRight(id="despatches-table-detail-header-discounted-base-unit-price")
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedBaseUnitPrice') }}
            th.Despatches-details-head-col.taxRatio.u-textRight(id="despatches-table-detail-header-tax-ratio")
              h6 {{ $t('Despatches.detail_TableColumn_TaxRatio') }}
            th.Despatches-details-head-col.discountedNetUnitPrice.u-textRight(id="despatches-table-detail-header-discounted-net-unit-price")
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedNetUnitPrice') }}
            th.Despatches-details-head-col.storeName(id="despatches-table-detail-header-tax-amount")
              h6 {{ $t('Despatches.detail_Tax_Amount') }}
            th.Despatches-details-head-col.storeName(id="despatches-table-detail-header-gross-amount")
              h6 {{ $t('Despatches.detail_Gross_Amount') }}
            th.Despatches-details-head-col.storeName(id="despatches-table-detail-header-store-name")
              h6 {{ $t('Despatches.detail_TableColumn_StoreName') }}
            th.Despatches-details-head-col.action

        tbody.Despatches-details-body(
        id="despatches-table-detail-body"
        v-if="!despatch.detailIsEmpty && renderComponent")
          tr.Despatches-details-body-row(
            :class="{'focus-within-dotted-blue': detail.isFocused}"
            v-for="(detail, index) in despatch.despatchDetailList"
            tabindex="0"
            data-vv-scope=`detail${index}`
          )
            td.Despatches-details-body-col(:id="`despatches-detail-checkbox-${rowIndex}-${index}`")
              CustomCheckbox(
                :id="`checkbox-despatch-header-select-detail-${rowIndex}-${index}`"
                label=""
                :checked="isDetailChecked(detail.id)"
                @change="isChecked => toggleDetail(detail, isChecked)"
              )
            td.Despatches-details-body-col.stockItemName(:id="`despatches-stock-item-${rowIndex}-name-${index}`")
              | {{ detail.stockItem.name }}
            td.Despatches-details-body-col.order-stockOnHandQuantity.ff-mono.u-textRight(:id="`despatches-order-${rowIndex}-stockOnHandQuantity-${index}`")
              | {{ detail.stockOnHandQuantity || '-' }}
            td.Despatches-details-body-col.relative(:id="`despatches-quantity-${rowIndex}-input-col-${index}`")
              .control
                customNumberInput.number-input(
                  :id="`despatches-quantity-${rowIndex}-input-${index}`"
                  name="quantity"
                  :class="{ 'is-danger': ((detail.remainingOrderQuantity > 0) && (detail.remainingOrderQuantity > detail.quantity) && (detail.showOrderPopup) && (isCanEdit)) }"
                  :value="detail.quantity"
                  :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                  v-validate="checkPermission('IgnoreQuantityDeviation') === false ? {min_value: detail.minQuantityDeviationResult ? detail.minQuantityDeviationResult : -999999999999, max_value: detail.maxQuantityDeviationResult ? detail.maxQuantityDeviationResult : 999999999999} : {min_value: -999999999999, max_value: 99999999999}"
                  @blur="mutateData(detail, 'quantity', `detail${index}`)"
                  @input="checkQuantityValue"
                )

                .unit-price-validation(v-show="showNegativeQuantityValidation")
                  span {{ $t('Despatches.detail_TableColumn_Quantity_cannot_negative') }}
                .unit-price-validation(
                  v-show="detail.showQuantityValidation || checkQuantityDeviationStatus(detail) === false"
                )
                  span {{$t('Despatches.deviationValidation')}}
                  .m-left-5 {{ detail.minQuantityDeviationResult }} - {{detail.maxQuantityDeviationResult}}

              .order-status(
                :id="`despatches-order-status-${rowIndex}-input-${index}`"
                v-if="detail.canIgnoreQuantity && detail.quantity !== null"
              )
                .order-status-content
                  span {{ $t('Despatches.popup_controlQuantityDeviation') }}
                  .order-status-content-close-order
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete2-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isDeviationCompleted', `detail${index}`, false)")
                        span {{ $t('Despatches.Order_Detail_Popup_No') }}
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete1-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isDeviationCompleted', `detail${index}`, detail.quantity)")
                        span {{ $t('Despatches.Order_Detail_Popup_Yes') }}

              .order-status(
                :id="`despatches-order-status-${rowIndex}-input-${index}`"
                v-if="(detail.remainingOrderQuantity > 0) && (detail.remainingOrderQuantity > detail.quantity) && (detail.showOrderPopup) && (isCanEdit)"
              )
                .order-status-content
                  span {{ $t('Despatches.Order_Detail_Content_Message') }}
                  .order-status-content-close-order
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete2-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isOrderCompleted', `detail${index}`, false)")
                        span {{ $t('Despatches.Order_Detail_Popup_No') }}
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete1-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isOrderCompleted', `detail${index}`, true)")
                        span {{ $t('Despatches.Order_Detail_Popup_Yes') }}
            td.Despatches-details-body-col.order-quantity.ff-mono.u-textRight.tooltip-relative(:id="`despatches-order-${rowIndex}-quantity-${index}`")
              | {{ detail.remainingOrderQuantity || '-' }}
              Tooltip(
                v-if="detail.remainingOrderQuantity > 0"
                white,
                right,
                :text="detail.orderNumber"
              )
            td.Despatches-details-body-col.baseUnitCode.u-textCenter(:id="`despatches-base-unit-${rowIndex}-code-${index}`")
              | {{ findUnit(detail.unitId).name }}
            td.Despatches-details-body-col.relative(:id="`despatch-detail-${rowIndex}-col-unitPrice-${index}`")
              .control.tooltip-relative.currency
              
                currency-input.txt.netUnitPrice.u-textRight.ff-mono.ff-mono.u-textRight.tooltip-relative(
                  v-if="isCanEdit && isPriceAvailable && detail.orderDetailId"
                  name="unitPrice"
                  :id="`despatch-detail-${rowIndex}-unitPrice-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`detail${index}.unitPrice`) }"
                  :value="detail.unitPrice"
                  :disabled="detail.priceType === 2"
                  :data-vv-as="$t('Despatches.detail_TableColumn_NetUnitPrice')"
                  v-validate="checkPermission('IgnorePriceDeviation') === false ? {required: true, min_value: detail.minPriceDeviationResult ? detail.minPriceDeviationResult : 0, max_value: detail.maxPriceDeviationResult ? detail.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [detail.quantity, detail.netAmount], required: true} : {greater_than:0, required: true, multiplyTwoField: [detail.quantity, detail.netAmount]}"
                  @blur.native="mutateData(detail, 'unitPrice', `detail${index}`)"
                  @focus.native="SET_FOCUS(detail)"
                  autocomplete="off"
                )
                .currency-symbol(v-if="showCurrencySymbol && isCanEdit && isPriceAvailable && detail.orderDetailId") {{despatch.currencyDefinitionSymbol}}

                currency-input.txt.netUnitPrice.u-textRight.ff-mono.ff-mono.u-textRight(
                  v-if="isCanEdit && isPriceAvailable && !detail.orderDetailId"
                  name="unitPrice"
                  :id="`despatch-detail-${rowIndex}-unitPrice-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`detail${index}.unitPrice`) }"
                  :value="detail.unitPrice"
                  :disabled="detail.priceType === 2"
                  :data-vv-as="$t('Despatches.detail_TableColumn_NetUnitPrice')"
                  v-validate="checkPermission('IgnorePriceDeviationWithoutOrder') === false ? {required: true, min_value: detail.minPriceDeviationResult ? detail.minPriceDeviationResult : 0, max_value: detail.maxPriceDeviationResult ? detail.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [detail.quantity, detail.netAmount], required: true} : {greater_than:0, required: true, multiplyTwoField: [detail.quantity, detail.netAmount]}"
                  @blur.native="mutateData(detail, 'unitPrice', `detail${index}`)"
                  @focus.native="SET_FOCUS(detail)"
                  autocomplete="off"
                )

                .currency-symbol(v-if="showCurrencySymbol && isCanEdit && isPriceAvailable && !detail.orderDetailId") {{despatch.currencyDefinitionSymbol}}


                tooltip.small(
                  v-if="detail.priceType === 2"
                  white,
                  right,
                  :text="$t('Despatches.priceTypeWarning')")

              .unit-price-validation(
                  v-show="detail.showPriceValidation || checkPriceDeviationStatus(detail) === false"

                )
                  span {{$t('Despatches.deviationValidation')}}
                  .m-left-5 {{ detail.minPriceDeviationResult }} - {{detail.maxPriceDeviationResult}}

              span.netUnitPrice.ff-mono.u-textRight.tooltip-relative(v-if="!isCanEdit || !isPriceAvailable") 
                span(v-if="showCurrencySymbol") {{ detail.currencyDefinitionSymbol }}
                span {{ detail.unitPrice | formatCurrency6Digits }}

              .price-status(
                :id="`despatches-order-status-price-${rowIndex}-input-${index}`"
                v-if="detail.canIgnorePrice"
              )
                .order-status-content
                  span {{ $t('Despatches.popup_controlPriceDeviation') }}
                  .order-status-content-close-order
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete2-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isPriceDeviationCompleted', `detail${index}`, false)")
                        span {{ $t('Despatches.Order_Detail_Popup_No') }}
                    Button(
                      :id="`radio-despatch-detail-${rowIndex}-order-is-complete1-${index}`"
                      type="button"
                      size="medium"
                      variant="border-black"
                      @click="mutateData(detail, 'isPriceDeviationCompleted', `detail${index}`, detail.unitPrice)")
                        span {{ $t('Despatches.Order_Detail_Popup_Yes') }}

            td.Despatches-details-body-col(:id="`despatch-detail-${rowIndex}-col-netAmount-${index}`")
              .control.tooltip-relative.currency
                currency-input.txt.netAmount.u-textRight.ff-mono.ff-mono.u-textRight.tooltip-relative(
                  v-if="isCanEdit && isPriceAvailable"
                  name="netAmount"
                  :id="`despatch-detail-${rowIndex}-netAmount-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`detail${index}.netAmount`) }"
                  :value="detail.netAmount"
                  :disabled="detail.priceType === 2"
                  v-validate="'greater_than:0|required'"
                  @blur.native="mutateData(detail, 'netAmount', `detail${index}`)"
                  @focus.native="SET_FOCUS(detail)"
                  autocomplete="off"
                )

                .currency-symbol(v-if="showCurrencySymbol && isCanEdit && isPriceAvailable") {{ detail.currencyDefinitionSymbol }}


                .netAmount.ff-mono.u-textRight(v-if="!isCanEdit || !isPriceAvailable") 
                  span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}

                tooltip.small(
                  v-if="detail.priceType === 2"
                  white,
                  right,
                  :text="$t('Despatches.priceTypeWarning')")

            td.Despatches-details-body-col.baseQuantity.ff-mono.u-textRight(:id="`despatches-base-${rowIndex}-quantity-${index}`")
              | {{ detail.baseQuantity }}
            td.Despatches-details-body-col.baseUnitCode(:id="`despatches-base-unit-code-${index}`")
              | {{ detail.stockItem.baseUnitCode }}
            td.Despatches-details-body-col.basePrice.ff-mono.u-textRight(:id="`despatches-base-${rowIndex}-price-${index}`") 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.netBaseUnitPrice | formatCurrency6Digits }}
              
            td.Despatches-details-body-col(:id="`despatches-detail-${rowIndex}-col-discount1-${index}`")
              .control.tooltip-relative
                input.txt.discount1.ff-mono.u-textRight.no-spinners(
                  v-if="isCanEdit"
                  :id="`despatches-detail-${rowIndex}-discount1-${index}`"
                  name="discount1"
                  :class="{ 'is-danger': veeErrors.has(`detail${index}.discount1`) }"
                  :value="detail.discount1"
                  :disabled="!isPriceAvailable || detail.priceType === 2"
                  :ref="`discount1-${index}`"
                  @change="mutateData(detail, 'discount1', `detail${index}`)"
                  type="number"
                  autocomplete="off"
                  step="any"
                  v-validate="'greater_than:-1|max_value:100'"
                  @focus="SET_FOCUS(detail)"
                  @blur="SET_NOFOCUS(detail)"
                )
                .discount1.ff-mono.u-textRight.discounts(v-else) %{{ detail.discount1 | percentFixed }}
                tooltip.small(
                  v-if="detail.priceType === 2"
                  white,
                  right,
                  :text="$t('Despatches.priceTypeWarning')")
            td.Despatches-details-body-col(:id="`despatches-detail-${rowIndex}-col-discount2-${index}`")
              .control.tooltip-relative
                input.discount2.txt.no-spinners.ff-mono.u-textRight(
                  v-if="isCanEdit"
                  :id="`despatches-detail-${rowIndex}-discount2-${index}`"
                  name="discount2"
                  :disabled="!Number(detail.discount1) || !isPriceAvailable || detail.priceType === 2"
                  :class="{ 'is-danger': veeErrors.has(`detail${index}.discount2`) }"
                  @change="mutateData(detail, 'discount2', `detail${index}`)"
                  :value="detail.discount2"
                  autocomplete="off"
                  step="any"
                  type="number"
                  v-validate="'greater_than:-1|max_value:100'"
                  @focus="SET_FOCUS(detail)"
                  @blur="SET_NOFOCUS(detail)"
                )
                .discount2.ff-mono.u-textRight.discounts(v-else) %{{ detail.discount2 | percentFixed }}
                tooltip.small(
                  v-if="detail.priceType === 2"
                  white,
                  right,
                  :text="$t('Despatches.priceTypeWarning')")
            td.Despatches-details-body-col.discountedUnitPrice.ff-mono.u-textRight(:id="`despatches-discounted-${rowIndex}-unit-price-${index}`") 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.discountedUnitPrice | formatCurrency6Digits }}
  
            td.Despatches-details-body-col.DiscountedBaseUnitPrice.ff-mono.u-textRight(:id="`despatches-discounted-${rowIndex}-base-unit-price-${index}`") 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.discountedBaseUnitPrice | formatCurrency6Digits }}
            td.Despatches-details-body-col.taxRatio(:id="`despatches-detail-${rowIndex}-col-tax-ratio-${index}`").tooltip-relative
              div(
                v-if="isCanEdit && isPriceAvailable"
              )
                select.txt.tax(
                name="taxId"
                :class="{ 'is-danger': veeErrors.has(`detail${index}.taxId`) }"
                :id="`despatches-${rowIndex}-tax-ratio-select-${index}`"
                v-validate="'required'"
                @change="mutateData(detail, 'taxId', `detail${index}`)"
                @click="SET_FOCUS(detail)"
                @blur="SET_NOFOCUS(detail)"
                :value="detail.taxId")
                  option(
                  v-for="Tax in Taxes"
                  :key="Tax.id"
                  :value="Tax.id"
                  :id="`option-despatch-${rowIndex}-popup-${Tax.id}`"
                  ) {{ '%'+Tax.ratio + ' - '+ Tax.name }}
                Icon.icon-down-arrow(name="icon-down-arrow")

              .taxRatio.ff-mono.u-textRight(v-else) %{{ detail.taxRatio | percentFixed }}

            td.Despatches-details-body-col.discountedNetUnitPrice.ff-mono.u-textRight(
              :id="`despatches-discounted-${rowIndex}-net-unit-price-${index}`"
            ) 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.quantity ? $options.filters.formatCurrency6Digits(detail.discountedNetUnitPrice) : '0'}}
            td.Despatches-details-body-col.netAmount.ff-mono.u-textRight(:id="`despatches-${rowIndex}-net-amount-${index}`") 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.quantity ? $options.filters.formatCurrency6Digits(detail.taxAmount) : '0' }}
            td.Despatches-details-body-col.netAmount.ff-mono.u-textRight(:id="`despatches-${rowIndex}-gross-amount${index}`") 
              span(v-if="showCurrencySymbol") {{detail.currencyDefinitionSymbol}}
              span {{ detail.quantity ? $options.filters.formatCurrency6Digits(detail.grossAmount) : '0' }}
            td.Despatches-details-body-col.storeName(:id="`despatches-store-${rowIndex}-name-${index}`")
              | {{ detail.storeName }}
            td.Despatches-details-body-col.action(:id="`despatches-detail-${rowIndex}-col-button-${index}`")
              ActionButton(
                id="despatches-detail-action-button"
                :data="!isCanEdit ? ['edit'] :  ['edit', 'remove']"
                :item="detail"
                @onItemEdit="despatchDetailEdit(detail)"
                @onItemDelete="despatchDetailRemove(detail)"
              )
    .Despatches-details-foot
      Button.btn-inline(
        :id="`btn-despatch-${rowIndex}-detail-expire-date-edit`"
        type="button"
        size="small"
        secondary
        :disabled="!(selectedDetail.length)"
        @click="editExpireDate(despatch)")
        span(:id="`btn-despatch-${rowIndex}-detail-expire-date-settings`") {{ $t('Despatches.expireDateSettings') }}
      Button.btn-inline(
        :id="`btn-despatch-${rowIndex}-detail-new`"
        type="button"
        size="small"
        secondary
        :disabled="selectedDetail.length === 0"
        @click="despatchMultipleDetailEdit")
        span(:id="`btn-despatch-${rowIndex}-detail-multiple-edit`") {{ $t('Despatches.detail_multipleEdit') }}
      Button.btn-inline.m-right-10(
      :id="`btn-despatch-${rowIndex}-detail-new`"
      type="button"
      size="small"
      secondary
      :disabled="isListSupplierCheck"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="despatchDetailNew(despatch)")
        span(:id="`btn-despatch-${rowIndex}-detail-new-span`") {{ $t('Despatches.detail_NewStockItemButton') }}

      Button.btn-inline.m-right-10(
      :id="`btn-despatch-${rowIndex}-detail-save`"
      type="button",
      size="small"
      primary
      iconName="icon-btn-success"
      iconClass="icon-btn-success"
      @click="despatchSaved(despatch)",
      :disabled="despatch.despatchDetailList < 1 || !priceDeviationStatus || !quantityDeviationStatus || showNegativeQuantityValidation"
      v-if="despatch.status === 1")
        span(:id="`btn-despatch-${rowIndex}-detail-save-span`") {{ $t('Despatches.detail_SaveDespatchButton') }}
    
  ExpireDate(
    v-show="showExpireDateEdit"
    :data="batchList"
    :despatchHeader="despatchHeader"
    @editExpireDate="editExpireDate"
    @getUpdatedBatch="editExpireDate"
    @closeModal="closeExpireDateScreen"
  )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import StatusTooltip from '@/view/global/alert-status-tooltip'
import ExpireDate from './components/expire-date-table'

export default {
  name: 'DespatchTable',

  props: {
    despatch: {
      type: Object,
      require: true
    },
    isCanEdit: {
      type: Boolean,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      selectedDetail: [],
      isOnlyListUsage: false,
      isPriceAvailable: false,
      renderComponent: true,
      disabledSaveButton: false,
      priceDeviationStatus: true,
      quantityDeviationStatus: true,
      showNegativeQuantityValidation: false,
      showCurrencySymbol: false,
      showExpireDateEdit: false,
      despatchHeader: {},
      expireDateData: {}
    }
  },

  components: {
    StatusTooltip,
    ExpireDate
  },

  mounted () {
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    this.pricePermission.find(i => {
      if (i.permission === 70) this.isPriceAvailable = true
    })
    this.showCurrencySymbol = this.UserInfo.showCurrencySymbol
  },

  computed: {
    ...mapGetters('Units', [
      'findUnit'
    ]),

    ...mapGetters('Tax', [
      'Taxes',
      'findTaxItem'
    ]),
    ...mapGetters('Settings', [
      'permissions', 'pricePermission', 'checkPermission', 'UserInfo'
    ]),
    ...mapGetters('Despatch', ['batchList']),

    isListSupplierCheck (){
      if (this.despatch.usageWithListIsNotRequired) return false
      else return this.isOnlyListUsage
    }
  },

  methods: {
    ...mapActions('Despatch', [
      'get_DESPATCH_FINALIZE',
      'update_DESPATCH_DETAIL',
      'getExpireDateData'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE']),

    ...mapMutations('Despatch', [
      'HIDE_OTHERS_DETAILS',
      'UPDATE_DETAIL_FROM_UI',
      'SET_FOCUS',
      'SET_NOFOCUS'
    ]),

    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },
    closeActionBar (item) {
      item.showActions = false
    },

    checkQuantityValue (item) {
      item < 0 ? this.showNegativeQuantityValidation = true : this.showNegativeQuantityValidation = false
    },

    checkPriceDeviationStatus (detail) {
      if ((detail.maxPriceDeviationResult && (detail.unitPrice > detail.maxPriceDeviationResult)) || (detail.minPriceDeviationResult && (detail.unitPrice < detail.minPriceDeviationResult))) {
        if (detail.orderDetailId) {
          if (!this.checkPermission('IgnorePriceDeviation')) {
            this.priceDeviationStatus = false
            return false
          } else {
            this.priceDeviationStatus = true
            return true
          }
        } else {
          if (!this.checkPermission('IgnorePriceDeviationWithoutOrder')) {
            this.priceDeviationStatus = false
            return false
          } else {
            this.priceDeviationStatus = true
            return true
          }
        }
      }
    },

    checkQuantityDeviationStatus (detail) {
      if ((detail.maxQuantityDeviationResult && detail.quantity > detail.maxQuantityDeviationResult) || (detail.minQuantityDeviationResult && detail.quantity < detail.minQuantityDeviationResult)) {
        if (!this.checkPermission('IgnoreQuantityDeviation')) {
          this.quantityDeviationStatus = false
          return false
        } else {
          this.quantityDeviationStatus = true
          return true
        }
      }
    },

    async editExpireDate (despatch) {
      if (despatch) this.despatchHeader = despatch
       const payload = {
        despatchDetailIds: this.selectedDetail.map(i => i.id)
      }
      await this.getExpireDateData(payload)
      this.showExpireDateEdit = true
      this.TOGGLE_MENU_MINIMALIZE(true)
    },

    closeExpireDateScreen () {
      this.showExpireDateEdit = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    async mutateData (detail, areaName = '', scope, value = event.target.value) {
      let copyDetail = JSON.parse(JSON.stringify(detail))
      copyDetail.showPriceValidation = false
      copyDetail.showQuantityValidation = false
      this.disabledSaveButton = false
      switch (areaName) {
        case 'unitPrice':
          copyDetail.canIgnorePrice = false
          if ((copyDetail.maxPriceDeviationResult && value.replace(',', '.') > copyDetail.maxPriceDeviationResult) || (copyDetail.minPriceDeviationResult && value.replace(',', '.') < copyDetail.minPriceDeviationResult)) {
            if (copyDetail.orderDetailId) {
              if (this.checkPermission('IgnorePriceDeviation')) {
                copyDetail.showPriceValidation = false
                this.disabledSaveButton = false
                copyDetail.unitPrice = Number(value.replace(',', '.'))
                copyDetail.canIgnorePrice = true
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.disabledSavedButton = false
                this.disabledSaveButton = false
                this.checkPriceDeviationStatus(detail)
                return
              } else {
                copyDetail.unitPrice = Number(value.replace(',', '.'))
                copyDetail.showPriceValidation = true
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.disabledSavedButton = true
                this.checkPriceDeviationStatus(detail)
                return
              }
            } else {
              if (this.checkPermission('IgnorePriceDeviationWithoutOrder')) {
                copyDetail.showPriceValidation = false
                this.disabledSaveButton = false
                copyDetail.unitPrice = Number(value.replace(',', '.'))
                copyDetail.canIgnorePrice = true
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.checkPriceDeviationStatus(detail)
                return
              } else {
                this.disabledSaveButton = true
                copyDetail.showPriceValidation = true
                copyDetail[areaName] = Number(value.replace(',', '.'))
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.disabledSavedButton = true
                this.checkPriceDeviationStatus(detail)
                return
              }
            }
          } else {
            copyDetail.showPriceValidation = false
            copyDetail.unitPrice = Number(value.replace(',', '.'))
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
            this.checkPriceDeviationStatus(detail)
          }
          this.checkPriceDeviationStatus(detail)
          break
        case 'quantity':
          if (value < 0) return
          if (copyDetail.orderDetailId) {
            copyDetail.canIgnoreQuantity = false
            if ((copyDetail.maxQuantityDeviationResult && value > copyDetail.maxQuantityDeviationResult) || (copyDetail.minQuantityDeviationResult && value < copyDetail.minQuantityDeviationResult)) {
              if (this.checkPermission('IgnoreQuantityDeviation')) {
                copyDetail.showQuantityValidation = false
                copyDetail.showOrderPopup = false
                copyDetail[areaName] = Number(value)
                if (value !== '') copyDetail.canIgnoreQuantity = true
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.disabledSavedButton = false
              } else {
                copyDetail[areaName] = Number(value)
                copyDetail.showQuantityValidation = true
                await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
                this.disabledSavedButton = true
                this.checkQuantityDeviationStatus(detail)
                return
              }
            } else {
              copyDetail[areaName] = Number(value)
              copyDetail.showOrderPopup = true
              copyDetail.showQuantityValidation = true
              await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
              this.disabledSavedButton = false
            }
          } else {
            copyDetail.showQuantityValidation = false
            copyDetail[areaName] = Number(value)
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
          }
          this.checkQuantityDeviationStatus(detail)
          break
        case 'netAmount':
          copyDetail.netAmount = Number(value.replace(',', '.'))
          break

        case 'isOrderCompleted':
          copyDetail.isOrderCompleted = value
          copyDetail.showOrderPopup = false
          break

        case 'isDeviationCompleted':
          copyDetail.canIgnoreQuantity = false
          if (value !== false) {
            copyDetail.finalQuantity = Number(value)
            copyDetail.quantity = Number(value)
            copyDetail.showOrderPopup = true
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
          } else {
            copyDetail.canIgnoreQuantity = false
            copyDetail.quantity = copyDetail.finalQuantity
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
            return
          }
          break

        case 'isPriceDeviationCompleted':
          copyDetail.canIgnorePrice = false
          if (value !== false) {
            copyDetail.finalPrice = Number(value)
            copyDetail.unitPrice = Number(value)
            copyDetail.canIgnorePrice = false
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
          } else {
            copyDetail.canIgnorePrice = false
            copyDetail.unitPrice = copyDetail.finalPrice
            await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
            return
          }
          break

        case 'taxId':
          copyDetail[areaName] = value
          break

        default:
          copyDetail[areaName] = Number(value)
      }

      if (!Number(copyDetail.discount1)) {
        copyDetail.discount2 = 0
      }

      await this.calculateDetail(copyDetail, areaName)
      await this.UPDATE_DETAIL_FROM_UI({detail, copyDetail})
      await this.$emit('updateHeaderTotal', this.despatch)
      await this.sendDetail(copyDetail, scope)
      this.SET_NOFOCUS(detail)
    },

    calculateDetail (detail, areaName = '') {
      /*
        * Eğer netAmount değeri kullanıcı tarafından değiştirilirse birim fiyat otomatik belirlenecek
        */
      const isNetAmount = detail.netAmount && detail.quantity && (areaName === 'netAmount')
      const isTaxIncluded = this.despatch.unitPriceType === 2
      const taxRatio = this.findTaxItem(detail.taxId).ratio

      if (isNetAmount) detail.unitPrice = detail.quantity ? detail.netAmount / detail.quantity : null
      detail.baseQuantity = detail.quantity ? detail.baseUnitQuantity * detail.quantity : null

      const baseUnitPrice =
            isNetAmount
              ? isTaxIncluded
                ? ((detail.netAmount / detail.quantity) / (1 + (taxRatio / 100)))
                : ((detail.netAmount / detail.quantity))
              : (isTaxIncluded ? (detail.unitPrice / (1 + (taxRatio / 100))) : detail.unitPrice)

      detail.netBaseUnitPrice = baseUnitPrice / detail.baseUnitQuantity
      detail.netBaseUnitPrice = detail.netBaseUnitPrice === Infinity ? 0 : detail.netBaseUnitPrice
      detail.netAmount = isNetAmount ? detail.netAmount : detail.quantity ? detail.unitPrice * detail.quantity : null

      const discountedUnitPriceWithDiscount1 = detail.unitPrice - ((detail.unitPrice / 100) * detail.discount1)
      const discountedBaseUnitPriceWithDiscount1 = detail.netBaseUnitPrice - ((detail.netBaseUnitPrice / 100) * detail.discount1)

      const discountedNetUnitPriceWithDiscount1 = detail.netAmount - ((detail.netAmount / 100) * detail.discount1)
      const discountedNetUnitPriceAllDiscounts = discountedNetUnitPriceWithDiscount1 - ((discountedNetUnitPriceWithDiscount1 / 100) * detail.discount2)

      detail.discountedUnitPrice = discountedUnitPriceWithDiscount1 - ((discountedUnitPriceWithDiscount1 / 100) * detail.discount2)
      detail.discountedBaseUnitPrice = discountedBaseUnitPriceWithDiscount1 - ((discountedBaseUnitPriceWithDiscount1 / 100) * detail.discount2)
      detail.discountedNetUnitPrice =
          isTaxIncluded ? discountedNetUnitPriceAllDiscounts / (1 + (taxRatio / 100))
            : discountedNetUnitPriceAllDiscounts

      detail.taxAmount = (detail.discountedNetUnitPrice * taxRatio) / 100
      detail.grossAmount = detail.discountedNetUnitPrice + detail.taxAmount

      /* OLD CALCULATION
        * @unitPriceWithoutTax => eğer brüt ise net ile ilgili alanları değiştirebilmek için kdv hariç birim fiyat
        * Eğer netAmount değeri kullanıcı tarafından değiştirilirse birim fiyat otomatik belirlenecek
        */
      /* const isNetAmount = detail.netAmount && detail.quantity && (areaName === 'netAmount')
        const taxRatio = this.findTaxItem(detail.taxId).ratio
        const unitPriceWithoutTax = (detail.unitPrice / (1 + (taxRatio / 100)))
        const unitPrice = isNetAmount ? (detail.netAmount / detail.quantity) : (this.despatch.unitPriceType === 1 ? detail.unitPrice : unitPriceWithoutTax)
        if (isNetAmount) {
          detail.unitPrice = detail.quantity ? detail.netAmount / detail.quantity : null
        }
        detail.baseQuantity = detail.quantity ? detail.baseUnitQuantity * detail.quantity : null
        detail.netBaseUnitPrice = unitPrice / detail.baseUnitQuantity
        detail.netBaseUnitPrice = detail.netBaseUnitPrice === Infinity ? 0 : detail.netBaseUnitPrice
        detail.netAmount = isNetAmount ? detail.netAmount : detail.quantity ? detail.unitPrice * detail.quantity : null

        const discountedUnitPriceWithDiscount1 = detail.unitPrice - ((detail.unitPrice / 100) * detail.discount1)
        const discountedBaseUnitPriceWithDiscount1 = detail.netBaseUnitPrice - ((detail.netBaseUnitPrice / 100) * detail.discount1)
        const discountedNetUnitPriceWithDiscount1 = detail.netAmount - ((detail.netAmount / 100) * detail.discount1)

        detail.discountedUnitPrice = discountedUnitPriceWithDiscount1 - ((discountedUnitPriceWithDiscount1 / 100) * detail.discount2)
        detail.discountedBaseUnitPrice = discountedBaseUnitPriceWithDiscount1 - ((discountedBaseUnitPriceWithDiscount1 / 100) * detail.discount2)
        detail.discountedNetUnitPrice = discountedNetUnitPriceWithDiscount1 - ((discountedNetUnitPriceWithDiscount1 / 100) * detail.discount2)

        detail.taxAmount = (detail.discountedNetUnitPrice * taxRatio) / 100
        detail.grossAmount = detail.discountedNetUnitPrice + detail.taxAmount */
    },

    sendDetail (detail, scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) return

        if (!detail.discount1 || +detail.discount1 === 0) {
          detail.discount2 = 0
        }
        const params = {
          id: detail.id,
          quantity: detail.quantity,
          unitPrice: detail.unitPrice,
          taxId: detail.taxId,
          unitId: detail.unitId,
          discount1: detail.discount1,
          discount2: detail.discount2,
          isOrderCompleted: detail.isOrderCompleted
        }
        this.update_DESPATCH_DETAIL(params)
      })
    },

    despatchDetailNew (despatch) {
      this.HIDE_OTHERS_DETAILS(despatch)
      this.$router.push({
        name: 'DespatchDetailNew',
        params: {
          id: despatch.id,
          despatchHeader: despatch
        }
      })
    },

    despatchDetailEdit (detail) {
      this.$router.push({
        name: 'DespatchDetailEdit',
        params: {
          detailId: detail.id,
          despatchDetail: detail
        }
      })
    },

    despatchSaved (despatch) {
      this.$router.push({
        name: 'DespatchFinalize',
        params: {
          id: despatch.id,
          despatchHeader: despatch
        }
      })
    },

    despatchDetailRemove (detail) {
      this.$router.push({
        name: 'DespatchDetailRemove',
        params: {
          detailId: detail.id,
          item: detail
        }
      })
    },

    toggleHeader (isChecked) {
      if (isChecked) {
        this.despatch.despatchDetailList.map(detail => {
          if (!this.isDetailChecked(detail.id)) this.selectedDetail.push(detail)
        })
      } else {
        this.despatch.despatchDetailList.map(allDetails => {
          this.selectedDetail.splice(this.selectedDetail.findIndex(d => d.id === allDetails.id))
        })
      }
    },

    isHeaderChecked () {
      return this.despatch.despatchDetailList.length === this.selectedDetail.filter(detail => detail.despatchHeaderId === this.despatch.id).length
    },

    toggleDetail (item, isChecked) {
      if (isChecked) {
        this.selectedDetail.push(item)
      } else {
        this.selectedDetail.splice(this.selectedDetail.findIndex(detail => detail.id === item.id), 1)
      }
    },

    isDetailChecked (detailId) {
      return this.selectedDetail.some(item => item.id === detailId)
    },

    despatchMultipleDetailEdit () {
      this.$router.push({
        name: 'multipleDespatchEdit',
        params: {
          despatchHeader: this.despatch,
          despatchDetails: this.selectedDetail
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

  table {
    border-spacing: 50px 50px;
  }
  .Despatches {

    &-details {
      border-top: 1px solid $color-gray;
      padding: 15px 0;
      background: $color-lighter-blue;
    .btn-edit {
      color: $color-light;
      background-color: $color-gray;
    }

    .btn-remove {
      color: $color-warning;
      background-color: #fcedec;
    }

    .btn-switch {
      color: $color-success;
    }

      &-head {
        &-row {
          .Despatches-details-head-col {
            &:first-child {
              position: sticky;
              left: -1px;
              z-index: $z-index-xxs;
            }
          }
        }
        &-col {
          padding: 7px 30px;
          white-space: nowrap;
          background-color: $color-lighter-blue;
          h6 {
            font-size: $font-size-micro;
            color: $color-success;
            margin-bottom: 6px;
          }
        }
      }

      &-body {
        margin-top: 10px;
        margin-bottom: 20px;

        &-row {
          border-top: 2px solid $color-gray;
          border-radius: $border-radius;
          line-height: 1;
          box-sizing: border-box;
          align-items: center;

          & + & {
            margin-top: 10px;
          }

          .Despatches-details-body-col {
            &:first-child {
              position: sticky;
              left: -1px;
              z-index: $z-index-xxs;
            }
          }

          &:focus-within {
            border-top: 2px dashed $color-primary !important;
            border-bottom: 2px dashed $color-primary !important;
            + tr {
              border-top: 2px dashed $color-primary !important;
            }
          }

          &:hover {
            border-top: 1px solid $color-light-blue;
            border-bottom: 1px solid $color-light-blue;
            + tr {
              border-top: 1px solid $color-light-blue;
            }
          }
        }

        &-col {
          padding: 10px 30px;
          white-space: nowrap;
          background-color: $color-lighter-blue;
          .discount1,
          .discount2,
          .tax {
            min-width: 110px;
            min-height: 43px;
            max-width: unset !important;
          }

          .quantity {
            min-width: 98px;
            min-height: 43px;
            &.is-danger {
              background-color: $color-white!important;
            }
          }

          .netUnitPrice,
          .netAmount {
            min-width: 98px;
            min-height: 43px;
          }

          .discounts {
            min-height: auto;
          }

          &.action {
            justify-content: flex-end;
            opacity: 1;
            transition: opacity $transition;
            .body {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      &-foot {
        display: flex;
        justify-content: flex-end;

        .btn {
          min-width: 133px;
          height: 44px;
          margin-left: 10px;
        }
      }

      &-body-row:focus-within > .action,
      &-body-row:hover > .action {
        opacity: 1;
      }
    }
  }
  .table-scroll {
    overflow-y: auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

.taxRatio {
  position: relative;
  select.txt {
    appearance: none;
    width: 160px;
    position: relative;
    padding-right: 14%;
  }
  .icon-down-arrow {
    position: absolute;
    color: $color-success;
    top: calc(50% - 7px);
    right: 40px;
    width: 6%;
  }
}
// 26px icon size
.order-status {
  position: absolute;
  top: calc(50% - 13px);
  right: 50px;
  width: 0;

  &-content {
    @include MiniPopupMixin(
      $width: 400px,
      $margin: -22px 0 0 30px,
      $padding-hor: 10px,
      $padding-ver: 50px,
      $triangle-direction: 'rightCenter',
      $display: block,
      $color: lighten($color-light-blue, 30%),
      $triangle-size: 8px
    );

    &-close-order {
      display: flex;
      justify-content: center;
      margin-top: 7px;
      .btn {
        height: 30px;
        font-weight: $font-weight-normal;
        &:first-child{
          margin-right: 15px;
        }
      }
    }
  }
}
.price-status {
  @extend .order-status;
}
.unit-price-validation {
  display: flex;
  color: $color-warning;
}

.number-input {
  width: 100px;
}

:deep() .appTooltip .text {
  max-width: unset;
}
.currency {
  position: relative;
  .txt {
    margin-left: 5px;
  }
  &-symbol {
    position: absolute;
    left: 10px;
    top: 33%;
  }
}
</style>
