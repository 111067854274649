var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-title" }, [
        _c("div", { staticClass: "header-title-left" }, [
          _c(
            "div",
            { staticClass: "header-day-message" },
            [_c("DayMessage")],
            1
          ),
          _c(
            "div",
            { staticClass: "header-custom-multiselect" },
            [
              _vm.selectedType.id !== 1
                ? _c("storeSelect", {
                    attrs: {
                      showSelectedName: true,
                      userDefaultStoreId: _vm.UserInfo.defaultStoreId,
                    },
                    on: { submitFilter: _vm.submitFilter },
                  })
                : _vm._e(),
              _vm.selectedType.id === 1
                ? _c("storeGroupSelect", {
                    on: {
                      submitFilter: _vm.submitStoreGroupFilter,
                      setDefaultStoreGroup: _vm.setDefaultStoreGroup,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.typeList,
              selectedItem: _vm.selectedType,
              useUpperCase: true,
            },
            on: { delegateChangeSelectedItem: _vm.changeTab },
          }),
        ],
        1
      ),
      _vm.hasPermissionDashboard && _vm.selectedType.id === 0
        ? _c(
            "template",
            { slot: "content-center" },
            [
              _vm.isLoading
                ? _c("TableLoading")
                : !_vm.isEmpty && !_vm.isLoading
                ? _c("div", { staticClass: "Dashboard" }, [
                    _c("div", { staticClass: "container-custom" }, [
                      _vm.selectedType.id === 0
                        ? _c("div", { staticClass: "col-full" }, [
                            _c(
                              "div",
                              { staticClass: "toolbar" },
                              [
                                _c("div", { staticClass: "tabs" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "report-tab",
                                      class: {
                                        isSelected: _vm.periodType === 1,
                                      },
                                      attrs: {
                                        id: "option-store-period-type-1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.periodFilter(1)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Dashboard.store_period_selection-weekly"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "report-tab",
                                      class: {
                                        isSelected: _vm.periodType === 2,
                                      },
                                      attrs: {
                                        id: "option-store-period-type-2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.periodFilter(2)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Dashboard.store_period_selection-monthly"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("CustomCheckbox", {
                                  staticClass: "show-with-other-expense",
                                  attrs: {
                                    id: "checkbox-recipes-show-passive",
                                    label: _vm.$t(
                                      "Dashboard.showWithOtherExpense"
                                    ),
                                  },
                                  on: { change: _vm.getPageData },
                                  model: {
                                    value: _vm.showWithOtherExpense,
                                    callback: function ($$v) {
                                      _vm.showWithOtherExpense = $$v
                                    },
                                    expression: "showWithOtherExpense",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-full" },
                        [
                          _c("Reports", {
                            attrs: {
                              selectedType: _vm.selectedType.type,
                              periodType: _vm.periodType,
                            },
                            on: {
                              getSummary: function ($event) {
                                return _vm.periodFilter(_vm.periodType)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-half p-right-15" }, [
                      _c(
                        "div",
                        { staticClass: "dashboard-bottom" },
                        [_c("SupplierDebt")],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-half p-left-15" }, [
                      _c(
                        "div",
                        { staticClass: "dashboard-bottom" },
                        [_c("Stock")],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.hasPermissionDashboard && _vm.selectedType.id === 1
        ? _c(
            "template",
            { slot: "content-center" },
            [
              _vm.salesForecastIsActive
                ? _c("ForecastTable")
                : _c("EmptyState", {
                    staticClass: "emptyState",
                    attrs: {
                      iconName: "empty-state-forecasting",
                      title: _vm.$t(
                        "Dashboard.emptyState_salesForecasting_title"
                      ),
                      description: _vm.$t(
                        "Dashboard.emptyState_salesForecasting_description"
                      ),
                      buttonText: _vm.$t(
                        "Dashboard.emptyState_emptyState_salesForecasting_ButtonText"
                      ),
                      showIcon: false,
                    },
                    on: { emptyAddButton: _vm.getPageInfo },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasPermissionRefrigerator && _vm.selectedType.id === 2
        ? _c("template", { slot: "content-center" }, [_c("Devices")], 1)
        : _vm._e(),
      !_vm.hasPermissionDashboard
        ? _c(
            "template",
            { slot: "content-center" },
            [
              _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Dashboard.No_Permission_Title"),
                  description: _vm.$t("Dashboard.No_Permission_Description"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }