<template lang="pug">
  .modal-overlay
    .modal-content
      .modal-header
        h2 {{ $t('Global.title_UserIsNotActive') }}

      .modal-body
        p {{ $t('Global.description_UserIsNotActive') }}
      .modal-footer
        button(@click="logout") {{ $t('Global.logout') }}
</template>

<script>
import { mapActions } from 'vuex'

import AuthorizationContext from "@/sso/keycloak-instance";

export default {
  name: 'PassiveUser',

  methods: {
    ...mapActions('Auth', ['Logout']),

    async logout () {
      window.history.replaceState({}, '', '/')
      await AuthorizationContext.logout()
      this.Logout()
    },
  }
}
</script>

<style lang="scss" scoped>

 .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* Yarı saydam siyah arka plan */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);  /* Arka planı bulanıklaştırır */
  z-index: 1000;

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
      }

      .close-btn {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .modal-body {
      margin: 15px 0;
    }

    .modal-footer {
      text-align: right;
    }
  }
}
</style>
