var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "forecast-container" }, [
    _c("div", { staticClass: "forecast-container-header" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("Dashboard.weather_ForecastSales")))]),
      _c("div", { staticClass: "forecast-container-header-right" }, [
        _c(
          "div",
          { staticClass: "switch" },
          [
            _c("span", { staticClass: "m-right-10" }, [
              _vm._v(
                _vm._s(_vm.$t("Dictionary.Quantity")) +
                  "/" +
                  _vm._s(_vm.$t("Dictionary.Amount"))
              ),
            ]),
            _c("SwitchButton", {
              class: _vm.showCurrency ? "active" : "passive",
              attrs: {
                active: _vm.showCurrency,
                id: "btn-dashboard-switch-status",
              },
              on: {
                change: function ($event) {
                  return _vm.changeCurrencyStatus(_vm.row)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("table", { staticClass: "m-top-10" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", [
              _c("div", { staticClass: "forecast-header-left" }, [
                _c("div", { staticClass: "dot-forecast" }),
                _c("div", { staticClass: "forecast-header-left-info" }, [
                  _vm._v(_vm._s(_vm.$t("Dashboard.forecastedSale"))),
                ]),
              ]),
              _c("div", { staticClass: "forecast-header-left" }, [
                _c("div", { staticClass: "dot-real" }),
                _c("div", { staticClass: "forecast-header-left-info" }, [
                  _vm._v(_vm._s(_vm.$t("Dashboard.actualSale"))),
                ]),
              ]),
              _c("div", { staticClass: "forecast-header-left" }, [
                _c("div", { staticClass: "dot-percent" }),
                _c("div", { staticClass: "forecast-header-left-info" }, [
                  _vm._v(_vm._s(_vm.$t("Dashboard.percentDeviation"))),
                ]),
              ]),
            ]),
            _vm._l(_vm.WeatherForecastData, function (day, index) {
              return _c("th", { key: index }, [
                _c("span", { staticClass: "weather-date" }, [
                  _vm._v(_vm._s(_vm.getFilteredDate(day.date))),
                ]),
                _c("img", {
                  staticClass: "weather-icon",
                  attrs: {
                    src: `http://openweathermap.org/img/wn/${day.icon}@2x.png`,
                    alt: day.info,
                  },
                }),
                _c("div", { staticClass: "weather" }, [
                  _c("span", { staticClass: "weather-max" }, [
                    _vm._v(
                      _vm._s(_vm.getFormattedTemperature(day.temperatureMax)) +
                        "°"
                    ),
                  ]),
                  _c("span", { staticClass: "weather-min" }, [
                    _vm._v(
                      _vm._s(_vm.getFormattedTemperature(day.temperatureMin)) +
                        "°"
                    ),
                  ]),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.SalesForecastData, function (stock) {
          return _c(
            "tr",
            [
              _c("td", [
                _c("span", { staticClass: "product" }, [
                  _vm._v(_vm._s(stock.menuItemName)),
                ]),
              ]),
              _vm._l(stock.resultList, function (forecastData) {
                return _c("td", [
                  !_vm.showCurrency
                    ? _c("div", { staticClass: "quantities" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(forecastData.forecastedQuantity || "-")
                          ),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(forecastData.actualQuantity || "-")),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(forecastData.percentDeviationQuantity || "%")
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "amounts" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              forecastData.forecastedAmount !== null
                                ? _vm.currencySymbol +
                                    forecastData.forecastedAmount
                                : "-"
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              forecastData.actualAmount !== null
                                ? _vm.currencySymbol + forecastData.actualAmount
                                : "-"
                            ) + " "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(forecastData.percentDeviationAmount || "%")
                          ),
                        ]),
                      ]),
                ])
              }),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }